import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './index.module.css';
import {unlock} from 'slices/password';
import {RootState} from 'state';
import Layout from 'components/Layout';
import TextInputForm from 'components/TextInputForm';
import Button from 'components/Button';
import Overlay from 'components/Overlay';
import Spinner from 'components/Spinner';
import {useTranslation} from 'react-i18next';

const Unlock: React.FC = () => {
  const dispatch = useDispatch();
  const {loading, error} = useSelector((state: RootState) => state.password);
  const [password, setPassword] = useState('');
  const {t} = useTranslation();

  const handleSubmit = () => {
    dispatch(unlock(password));
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <TextInputForm
            className={styles.input}
            name="password"
            label={t('features.Unlock.password')}
            type="password"
            onChange={(value) => setPassword(value)}
          />
          {error && <div className={styles.error}>{error}</div>}
          <Button
            className={styles.button}
            onClick={handleSubmit}
            text={t('features.Unlock.unlock')}
          />
        </div>
      </div>
      {loading && (
        <Overlay>
          <Spinner fill="#FFF" size={96} />
        </Overlay>
      )}
    </Layout>
  );
};

export default Unlock;
