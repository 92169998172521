import {useEffect} from 'react';
import {Action} from 'redux';
import {useToasts} from 'react-toast-notifications';
import {AppDispatch} from 'state';
import i18next from 'i18next';

interface Notifications {
  add?: boolean;
  update?: boolean;
  delete?: boolean;
  error?: string | null;
}

const useStatusNotifications = (
  dispatch: AppDispatch,
  dismissNotificationsAction: () => Action,
  notifications: Notifications,
) => {
  const {addToast} = useToasts();

  useEffect(() => {
    if (notifications.add) {
      addToast(i18next.t('toasts.added'));
      dispatch(dismissNotificationsAction());
    }
  }, [dispatch, addToast, dismissNotificationsAction, notifications.add]);

  useEffect(() => {
    if (notifications.update) {
      addToast(i18next.t('toasts.updated'));
      dispatch(dismissNotificationsAction());
    }
  }, [dispatch, addToast, dismissNotificationsAction, notifications.update]);

  useEffect(() => {
    if (notifications.delete) {
      addToast(i18next.t('toasts.deleted'));
      dispatch(dismissNotificationsAction());
    }
  }, [dispatch, addToast, dismissNotificationsAction, notifications.delete]);

  useEffect(() => {
    if (notifications.error) {
      addToast(notifications.error);
    }
  }, [addToast, notifications.error]);
};

export default useStatusNotifications;
