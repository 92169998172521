import React from 'react';
import styles from './index.module.css';
import Title from 'components/Title';
import {useTranslation} from 'react-i18next';

interface Props {
  title?: string;
  requestClose?: Function;
  headerCustom?: () => React.ReactNode;
}

const Modal: React.FC<Props> = ({
  title,
  requestClose,
  headerCustom,
  children,
}) => {
  const {t} = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {title && <Title className={styles.title}>{title}</Title>}
        <div className={styles.headerRight}>
          {headerCustom && headerCustom()}
          {requestClose && (
            <h4 className={styles.closeButton} onClick={requestClose as any}>
              {t('common.close')}
            </h4>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Modal;
