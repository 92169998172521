import React, {useEffect, useState} from 'react';
import {PDFDownloadLink} from '@react-pdf/renderer';
import ButtonForm from 'components/ButtonForm';
import stylesButtonForm from 'components/ButtonForm/index.module.css';
import Report, {ReportData} from './Report';
import loadData from './dataLoader';
import {useTranslation} from 'react-i18next';

interface Props {}

const MemoizedReport = React.memo(Report);

const GenerateReportButton: React.FC<Props> = () => {
  const {t} = useTranslation();

  const [data, setData] = useState<ReportData | null>(null);

  useEffect(() => {
    loadData().then(setData);
  }, []);

  if (!data)
    return (
      <ButtonForm
        text={t('components.GenerateReportButton.downloadReport')}
        onClick={() => {}}
        loading
        disabled
      />
    );

  return (
    <PDFDownloadLink
      className={stylesButtonForm.button}
      document={<MemoizedReport data={data} />}
      fileName="report.pdf">
      {t('components.GenerateReportButton.downloadReport')}
    </PDFDownloadLink>
  );
};

export default GenerateReportButton;
