import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import * as Parse from 'parse';

import {TableOrderClassName} from 'slices/types';
import {
  fetchTableOrders,
  fetchOrders,
  silentAddOrder,
  silentUpdateOrder,
  silentUpdateTableOrderStateAndOrderCount,
} from 'slices/tableOrders';
import {formatOrder} from 'helpers/orderFormatter';

let isSubscriptionTableOrderClosed = false;
let isSubscriptionOrderClosed = false;

const useLiveTableOrder = (id: string) => {
  const dispatch = useDispatch();
  const subscriptions: any = {};
  const subscribe = async () => {
    const queryTableOrder = new Parse.Query('TableOrder');
    queryTableOrder.equalTo('objectId', id).select('state', 'orderCount');

    console.info('----------------');
    subscriptions.subscriptionTableOrder = await queryTableOrder.subscribe();
    console.info('TableOrder screen > TableOrder socket > subscribed');

    subscriptions.subscriptionTableOrder.on('update', async (data: any) => {
      console.info('----------------');
      console.info('TableOrder screen > TableOrder socket > update: ', data);
      dispatch(
        silentUpdateTableOrderStateAndOrderCount({
          id,
          state: data.get('state'),
          orderCount: data.get('orderCount'),
        }),
      );
    });

    subscriptions.subscriptionTableOrder.on('open', () => {
      console.info('----------------');
      console.info('TableOrder screen > TableOrder socket > open');
      if (isSubscriptionTableOrderClosed) {
        dispatch(fetchTableOrders());
        isSubscriptionTableOrderClosed = false;
      }
    });

    subscriptions.subscriptionTableOrder.on('close', () => {
      console.info('----------------');
      console.info('TableOrder screen > TableOrder socket > close');
      isSubscriptionTableOrderClosed = true;
    });

    const tableOrderPointer = new TableOrderClassName().set('objectId', id);

    const queryOrder = new Parse.Query('Order');
    queryOrder.equalTo('tableOrder', tableOrderPointer);

    subscriptions.subscriptionOrder = await queryOrder.subscribe();
    console.info('TableOrder screen > Order socket > subscribed');

    subscriptions.subscriptionOrder.on('update', async (data: any) => {
      console.info('----------------');
      console.info('TableOrder screen > Order socket > update: ', data);
      await Promise.all([
        data.get('user').fetch(),
        // TODO : this below is inefficient, can we fetch all refunds at once ?
        data.get('refunds').map((refund: any) => refund.fetch()),
        data.get('discountPointer')
          ? data.get('discountPointer').fetch()
          : Promise.resolve(),
      ]);
      const order = formatOrder(data);
      dispatch(silentUpdateOrder({id, order}));
    });

    subscriptions.subscriptionOrder.on('create', async (data: any) => {
      console.info('----------------');
      console.info('TableOrder screen > Order socket > create: ', data);
      await Promise.all([
        data.get('user').fetch(),
        data.get('discountPointer')
          ? data.get('discountPointer').fetch()
          : Promise.resolve(),
      ]);
      const order = formatOrder(data);
      dispatch(silentAddOrder({id, order}));
    });

    subscriptions.subscriptionOrder.on('open', () => {
      console.info('----------------');
      console.info('TableOrder screen > Order socket > open');
      if (isSubscriptionOrderClosed) {
        dispatch(fetchOrders(id));
        isSubscriptionOrderClosed = false;
      }
    });

    subscriptions.subscriptionOrder.on('close', () => {
      console.info('----------------');
      console.info('TableOrder screen > Order socket > close');
      isSubscriptionOrderClosed = true;
    });
  };

  useEffect(() => {
    subscribe();

    return () => {
      console.info('----------------');
      if (subscriptions.subscriptionTableOrder)
        subscriptions.subscriptionTableOrder.unsubscribe().then(() => {
          isSubscriptionTableOrderClosed = false;
          console.info('TableOrder screen > Order socket > unsubscribed');
        });
      if (subscriptions.subscriptionOrder)
        subscriptions.subscriptionOrder.unsubscribe().then(() => {
          isSubscriptionOrderClosed = false;
          console.info('TableOrder screen > Order socket > unsubscribed');
        });
    };
  }, []);
};

export default useLiveTableOrder;
