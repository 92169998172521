import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'state';
import useStatusNotifications from 'hooks/useStatusNotifications';
import styles from './index.module.css';
import {
  fetchWaiters,
  addWaiter,
  deleteWaiter,
  reset as resetAction,
  dismissNotifications,
} from 'slices/waiters';
import WaiterCard from './WaiterCard';
import AddCard from './AddCard';
import AddModal, {Form as AddModalForm} from './AddModal';
import Layout from 'components/Layout';
import Title from 'components/Title';
import Overlay from 'components/Overlay';
import Popup from 'components/Popup';
import Spinner from 'components/Spinner';
import usePassword from 'hooks/usePassword';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

interface Props {}

const Waiters: React.FC<Props> = () => {
  const [deletePopupVisible, setDeletePopupVisible] = useState<any>(null);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {
    error,
    fetchLoading,
    addLoading,
    deleteLoading,
    waiters,
    addSuccessNotification,
    deleteSuccessNotification,
  } = useSelector((state: RootState) => state.waiters);

  usePassword();
  useStatusNotifications(dispatch, dismissNotifications, {
    add: addSuccessNotification,
    delete: deleteSuccessNotification,
    error: error ? i18next.t('toasts.error') : undefined,
  });

  useEffect(() => {
    dispatch(fetchWaiters());

    return () => {
      dispatch(resetAction());
    };
  }, [dispatch]);

  const deleteWaiterHandler = () => {
    dispatch(deleteWaiter(deletePopupVisible.id));
    setDeletePopupVisible(null);
  };

  const addWaiterHandler = (values: AddModalForm, profilePictureFile: any) => {
    dispatch(addWaiter(values, profilePictureFile));
  };

  return (
    <>
      <Layout>
        <div className={styles.container}>
          <Title>{t('features.Waiters.title')}</Title>
          <div className={styles.cardContainer}>
            {waiters.map((waiter) => (
              <WaiterCard
                key={waiter.id}
                name={waiter.name}
                profilePicture={waiter.profilePicture}
                rating={waiter.rating}
                onClick={() =>
                  setDeletePopupVisible({id: waiter.id, name: waiter.name})
                }
              />
            ))}
            <AddCard
              onClick={() => setAddModalVisible(true)}
              loading={fetchLoading || addLoading}
            />
          </div>
        </div>
      </Layout>
      {(deletePopupVisible ||
        addModalVisible ||
        addLoading ||
        deleteLoading) && (
        <Overlay>
          {deletePopupVisible && (
            <Popup
              title="Delete waiter"
              message={`Are you sure to delete ${deletePopupVisible.name} ?`}
              actions={[
                {
                  label: 'Cancel',
                  callback: () => setDeletePopupVisible(null),
                },
                {label: 'Confirm', callback: deleteWaiterHandler},
              ]}
            />
          )}
          {addModalVisible && (
            <AddModal
              requestClose={() => setAddModalVisible(false)}
              onSubmit={addWaiterHandler}
            />
          )}
          {(addLoading || deleteLoading) && <Spinner fill="#FFF" size={96} />}
        </Overlay>
      )}
    </>
  );
};

export default Waiters;
