import React, {FC} from 'react';

import styles from './index.module.css';
import concatStyles from 'helpers/concatStyles';
import {ImageIconSvg} from 'components/SvgIcon';
import DeleteButtonForm from 'components/DeleteButtonForm';
import {useTranslation} from 'react-i18next';

interface Props {
  style?: string;
  title: string;
  url: string | null;
  loading?: boolean;
  onRemove: () => void;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ImageUpload: FC<Props> = ({
  style,
  title,
  url,
  loading,
  onRemove,
  onUpload,
}) => {
  const {t} = useTranslation();

  const Empty: FC = () => (
    <label className={styles.inputLabel} htmlFor="coverUpload">
      <ImageIconSvg fill="#A6A6A6" />
      <h3>{title}</h3>
      <div className={styles.uploadButton}>{t('common.upload')}</div>
      <input
        name="cover"
        id="coverUpload"
        type="file"
        accept="image/x-png,image/gif,image/jpeg"
        className={styles.input}
        onChange={onUpload}
      />
    </label>
  );

  return (
    <div
      className={concatStyles([
        styles.container,
        style,
        loading ? styles.skeleton : '',
      ])}
      style={{backgroundImage: `url(${!loading && url})`}}>
      {!url && !loading && <Empty />}
      {url && !loading && (
        <div className={styles.delete}>
          <DeleteButtonForm onClick={onRemove} />
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
