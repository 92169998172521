import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import styles from './index.module.css';
import {TableItem} from 'slices/types';
import {
  fetchTables,
  addTable,
  updateTable,
  deleteTable,
  dismissNotifications,
  reset as resetAction,
} from 'slices/tables';
import {fetchWaiters} from 'slices/waiters';
import {
  addTableOrder,
  fetchTableOrders,
  dismissNotifications as dismissNotificationsTableOrders,
  reset as resetTableOrdersAction,
} from 'slices/tableOrders';
import AddModal from './AddModal';
import {Form as FormAddUpdate} from './AddUpdateForm';
import {Form as FormOpen} from './OpenForm';
import MainModal from './MainModal';
import {RootState} from 'state';
import useStatusNotifications from 'hooks/useStatusNotifications';
import TableCard from 'components/TableCard';
import Layout from 'components/Layout';
import Title from 'components/Title';
import ButtonForm from 'components/ButtonForm';
import Overlay from 'components/Overlay';
import Spinner from 'components/Spinner';
import Popup from 'components/Popup';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

interface Props {}

const Tables: React.FC<Props> = () => {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [mainModalVisible, setMainModalVisible] = useState<any>(null);
  const [deletePopupVisible, setDeletePopupVisible] = useState<any>(null);

  const history = useHistory();
  const {t} = useTranslation();

  const {
    fetchLoading,
    addLoading,
    updateLoading,
    deleteLoading,
    tables,
    error,
    addSuccessNotification,
    updateSuccessNotification,
    deleteSuccessNotification,
  } = useSelector((state: RootState) => state.tables);
  const {
    fetchLoading: fetchWaitersLoading,
    waiters,
    error: waitersError,
  } = useSelector((state: RootState) => state.waiters);
  const {
    fetchLoading: fetchTableOrdersLoading,
    addLoading: addTableOrdersLoading,
    deleteLoading: deleteTableOrdersLoading,
    tableOrders,
    addSuccessNotification: addSuccessNotificationsTableOrders,
    error: tableOrdersError,
  } = useSelector((state: RootState) => state.tableOrders);
  const dispatch = useDispatch();
  const {addToast} = useToasts();

  const inUseTables = tables.filter((table: TableItem) =>
    tableOrders.find((tableOrder) => tableOrder.table.id === table.id),
  );
  const availableTables = tables.filter(
    (table: TableItem) =>
      !tableOrders.find((tableOrder) => tableOrder.table.id === table.id),
  );

  useStatusNotifications(dispatch, dismissNotifications, {
    add: addSuccessNotification,
    update: updateSuccessNotification,
    delete: deleteSuccessNotification,
    error:
      error || tableOrdersError || waitersError
        ? i18next.t('toasts.error')
        : undefined,
  });

  useEffect(() => {
    dispatch(fetchTables());
    dispatch(fetchWaiters());
    dispatch(fetchTableOrders());

    return () => {
      dispatch(resetAction());
      dispatch(resetTableOrdersAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (addSuccessNotificationsTableOrders) {
      addToast(i18next.t('toasts.tableOrderCreated'), {appearance: 'success'});
      dispatch(dismissNotificationsTableOrders());
    }
  }, [dispatch, addToast, addSuccessNotificationsTableOrders]);

  const addTableHandler = (values: FormAddUpdate) => {
    dispatch(addTable(values));
  };

  const onUpdateHandler = (id: string, values: FormAddUpdate) => {
    dispatch(updateTable(id, values));
  };

  const onDeleteHandler = (table: TableItem) => {
    setDeletePopupVisible({id: table.id, name: table.name});
  };

  const onOpenHandler = (id: string, values: FormOpen) => {
    dispatch(addTableOrder({tableId: id, ...values}));
  };

  const deleteTableHandler = () => {
    dispatch(deleteTable(deletePopupVisible.id));
    setDeletePopupVisible(null);
  };

  const renderTableGroup = (tableGroup: Array<TableItem>) =>
    tableGroup.map((table) => {
      const tableOrder = tableOrders.find(
        (tableOrder) => tableOrder.table.id === table.id,
      );
      return (
        <TableCard
          key={table.id}
          item={table}
          tableOrder={tableOrder?.state !== 'closed' ? tableOrder : null}
          onClick={() =>
            !tableOrder || tableOrder.state === 'closed'
              ? setMainModalVisible(table)
              : history.push(`orders/${tableOrder.id}`)
          }
          loading={fetchWaitersLoading || fetchTableOrdersLoading}
          showScan
          className={styles.tableCard}
        />
      );
    });

  return (
    <>
      <Layout>
        <div className={styles.container}>
          {inUseTables.length > 0 && (
            <>
              <Title>{t('features.Tables.tablesInUse')}</Title>
              <div className={styles.cardContainer}>
                {renderTableGroup(inUseTables)}
              </div>
            </>
          )}
          <Title>{t('features.Tables.tablesAvailable')}</Title>
          <div className={styles.cardContainer}>
            {renderTableGroup(availableTables)}
            <ButtonForm
              className={styles.addButton}
              onClick={() => setAddModalVisible(true)}
              loading={
                fetchLoading || fetchWaitersLoading || fetchTableOrdersLoading
              }
              text={t('common.addTable')}
            />
          </div>
        </div>
      </Layout>

      {(addModalVisible ||
        mainModalVisible ||
        deletePopupVisible ||
        addLoading ||
        updateLoading ||
        deleteLoading ||
        deleteTableOrdersLoading ||
        addTableOrdersLoading) && (
        <Overlay>
          {addModalVisible && (
            <AddModal
              requestClose={() => setAddModalVisible(false)}
              onSubmit={addTableHandler}
            />
          )}
          {mainModalVisible && (
            <MainModal
              waiters={waiters}
              table={mainModalVisible}
              requestClose={() => setMainModalVisible(null)}
              onUpdate={onUpdateHandler}
              onDelete={onDeleteHandler}
              onOpen={onOpenHandler}
            />
          )}
          {deletePopupVisible && (
            <Popup
              title="Delete table"
              message={t('features.Tables.areYouSure', {
                name: deletePopupVisible.name,
              })}
              actions={[
                {
                  label: t('common.cancel'),
                  callback: () => setDeletePopupVisible(null),
                },
                {label: t('common.confirm'), callback: deleteTableHandler},
              ]}
            />
          )}
          {(addLoading ||
            updateLoading ||
            deleteLoading ||
            deleteTableOrdersLoading ||
            addTableOrdersLoading) && <Spinner size={96} />}
        </Overlay>
      )}
    </>
  );
};

export default Tables;
