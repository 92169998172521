import React from "react";

interface Props {
  fill?: string;
  size?: number;
}

export const TeamIcon: React.FC<Props> = ({fill = "#000", size = 48}) => {
  return (
    <svg width={size} height={size} viewBox="-3 -15 48 48">
      <defs>
        <path id="prefix__a" d="M0 0h42.24v42.233H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M7.425 15.015c-2.73 0-4.95-2.22-4.95-4.95s2.22-4.95 4.95-4.95 4.95 2.22 4.95 4.95-2.22 4.95-4.95 4.95zM34.815 15.015c-2.73 0-4.95-2.22-4.95-4.95s2.22-4.95 4.95-4.95 4.95 2.22 4.95 4.95-2.22 4.95-4.95 4.95z"
          fill={fill}
        />
        <mask id="prefix__b" fill={fill}>
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M21.12 12.375a6.195 6.195 0 01-6.188-6.188A6.195 6.195 0 0121.12 0a6.195 6.195 0 016.188 6.188 6.195 6.195 0 01-6.188 6.187zM22.358 12.5v8.538a1.237 1.237 0 11-2.476 0V12.5c-4.186.607-7.425 4.185-7.425 8.538v7.424c0 .685.554 1.238 1.238 1.238h2.772l.945 11.406c.053.64.59 1.134 1.233 1.134h4.95c.643 0 1.18-.493 1.233-1.134l.945-11.406h2.772c.684 0 1.237-.553 1.237-1.238v-7.424c0-4.353-3.238-7.93-7.424-8.538zM9.9 28.628v-7.425c0-1.855.5-3.58 1.304-5.119-1.11-.663-2.394-1.069-3.779-1.069C3.33 15.015 0 18.345 0 22.44v6.188c0 .684.553 1.237 1.238 1.237h1.354l1.127 11.26A1.238 1.238 0 004.95 42.24H9.9c.635 0 1.169-.482 1.231-1.114l.915-9.145A3.708 3.708 0 019.9 28.627zM42.24 22.44c0-4.095-3.33-7.425-7.425-7.425-1.385 0-2.668.406-3.78 1.07.806 1.539 1.305 3.263 1.305 5.117v7.425c0 1.486-.883 2.761-2.146 3.354l.915 9.145a1.238 1.238 0 001.231 1.114h4.95c.635 0 1.169-.482 1.231-1.114l1.127-11.261h1.354c.685 0 1.238-.553 1.238-1.237V22.44z"
          fill={fill}
          mask="url(#prefix__b)"
        />
      </g>
    </svg>
  );
};

export default TeamIcon;
