import React from 'react';
import {FieldError, Controller, Control} from 'react-hook-form';
import {DeepMap} from 'react-hook-form/dist/types/utils';

import styles from './index.module.css';
import TextInputForm from 'components/TextInputForm';
import DeleteButtonForm from 'components/DeleteButtonForm';
import ImageUpload from 'components/ImageUpload';
import CheckboxForm from 'components/CheckboxForm';
import SelectInputForm from 'components/SelectInputForm';
import TextAreaForm from 'components/TextAreaForm';
import {MenuCategory} from 'slices/types';
import {useTranslation} from 'react-i18next';

interface Props {
  loading?: boolean;
  canDelete?: boolean;
  disabled?: boolean;
  currentCategoryId: string;
  onUploadCover: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveCover: () => void;
  categories: {
    byId: {[id: string]: MenuCategory};
    allIds: string[];
  };
  currencySymbol: string;
  register: any;
  control: Control<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  coverPhoto: string | null;
  onDelete: () => void;
}

const Form: React.FC<Props> = ({
  loading,
  canDelete,
  disabled,
  currentCategoryId,
  categories,
  currencySymbol,
  register,
  errors,
  control,
  coverPhoto,
  onUploadCover,
  onRemoveCover,
  onDelete,
}) => {
  const {t} = useTranslation();

  const currentCategoryCount =
    categories.byId[currentCategoryId].menuItems.length;

  return (
    <>
      <div className={styles.title}>
        <TextInputForm
          disabled={disabled}
          error={errors['name']}
          ref={register({required: true})}
          loading={loading}
          name="name"
          label={t('common.name')}
        />
        {canDelete && (
          <div className={styles.delete}>
            <DeleteButtonForm
              disabled={loading || disabled}
              onClick={onDelete}
            />
          </div>
        )}
      </div>
      <div className={styles.imgRow}>
        <div className={styles.imgUploadContainer}>
          <ImageUpload
            style={styles.imgUpload}
            loading={loading}
            title={t('common.photo')}
            url={coverPhoto}
            onRemove={onRemoveCover}
            onUpload={onUploadCover}
          />
        </div>
        <div className={styles.imgRowRight}>
          <Controller
            name="available"
            control={control}
            render={({onChange, value}) => (
              <CheckboxForm
                loading={loading}
                disabled={loading || disabled}
                label={t('common.available')}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name="position"
            control={control}
            render={({onChange, value}) => (
              <SelectInputForm
                loading={loading}
                disabled={loading || disabled}
                label={t('common.position')}
                onChange={onChange}
                value={value}
                options={
                  currentCategoryCount !== 0
                    ? Array(currentCategoryCount)
                        .fill(0)
                        .map((_, i) => ({value: i + 1, label: `${i + 1}`}))
                    : [{value: 1, label: '1'}]
                }
              />
            )}
          />
          <Controller
            name="category"
            control={control}
            render={({onChange, value}) => (
              <SelectInputForm
                loading={loading}
                disabled={loading || disabled}
                label={t('common.category')}
                onChange={onChange}
                value={value}
                options={categories.allIds.map((id) => ({
                  value: id,
                  label: categories.byId[id].name,
                }))}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowLeft}>
          <TextInputForm
            error={errors['prepTime']}
            ref={register({required: true, pattern: /(\d+\.\d{1,2})|\d+/})}
            loading={loading}
            disabled={disabled}
            type="text"
            suffix={t('common.minutes')}
            name="prepTime"
            label={t('common.prepTime')}
          />
        </div>
        <div className={styles.rowRight}>
          <Controller
            name="defaultService"
            control={control}
            render={({onChange, value}) => (
              <SelectInputForm
                loading={loading}
                disabled={loading || disabled}
                label={t('common.service')}
                onChange={onChange}
                value={value}
                options={[1, 2].map((service) => ({
                  // TODO : save an array of services to restaurant if some restaurants want custom number of
                  //  services or names
                  value: service,
                  label: service === 1 ? 'First service' : 'Second service',
                }))}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.row50}>
        <TextInputForm
          error={errors['price']}
          ref={register({required: true, pattern: /(\d+\.\d{1,2})|\d+/})}
          loading={loading}
          disabled={disabled}
          type="text"
          suffix={currencySymbol}
          name="price"
          label={t('common.price')}
        />
        <TextInputForm
          error={errors['deal']}
          ref={register({pattern: /(\d+\.\d{1,2})|\d+/})}
          loading={loading}
          disabled={disabled}
          type="text"
          suffix={currencySymbol}
          name="deal"
          label={t('common.discount')}
        />
      </div>
      <TextAreaForm
        error={errors['description']}
        ref={register({required: true})}
        loading={loading}
        disabled={disabled}
        name="description"
        label={t('common.description')}
      />
      <input type="hidden" name="id" ref={register({required: true})} />
    </>
  );
};

export default Form;
