import * as Parse from 'parse';

/*
 * Waiters
 */

export const WaiterClassName = Parse.Object.extend('Waiter');

export type WaiterItem = {
  id: string;
  createdAt: string;
  name: string;
  available: boolean;
  profilePicture: string | null;
  rating: number | undefined;
  reviewsCount: number;
};

/*
 * Tables
 */
export const TableClassName = Parse.Object.extend('Table');

export type TableItem = {
  id: string;
  createdAt: string;
  name: string;
  capacity: number;
  available: boolean;
};

/*
 * TableOrders
 */

// NOTE ON TABLEORDER STATES
// --------------------
// TableOrders collect all the orders in a table
// There are 4 states on tableOrders: pending, progress, completed, closed
// Pending: the table is open but orders have not been sent to the restaurant. Orders are sent to the restaurant when the number of sent orders matches the number of guests, or when a guest has manually requested the order to be sent to the restaurant (for example because one person at the table didn't show up)
// Progress: the table has been sent to the restaurant app
// Completed: all the orders have been served to the customers
// Closed: the bill has been paid and the table can be open for a new group

// NOTE ON ITEM STATES
// --------------------
// Items inside an order have 4 states: pending, progress, confirmed, completed
// Pending: items have not been sent to the restaurant
// Progress: items have been sent to the restaurant app and are waiting to be acknowledged
// Confirmed: the staff has entered the items into the restaurant POS system and they are being prepared
// Completed: the items have been served to the customers

// NOTE ON ORDER STATES
// --------------------
// Orders don't really need to have a state, because we already have a state on items and TableOrder, but it was done like this in the beginning, and it's still used as a shortcut to know if all users at a table have sent their order, and automatically mark the tableOrder state as "progress".
// Possible states are "pending" and "ordered"

// This is actually a combination of all possible states (those of TableOrder and those of OrderItems) had to do this to keep the refactoring manageable
export type TableOrderState =
  | 'pending'
  | 'progress'
  | 'confirmed'
  | 'completed'
  | 'closed';

export type TableOrderItem = {
  id: string;
  createdAt: string;
  usersId: string[];
  waiter: WaiterItem;
  orders: Order[];
  minGuests: number;
  table: TableItem;
  state: TableOrderState;
  orderCount: number;
};

export const TableOrderClassName = Parse.Object.extend('TableOrder');

/*
 * Orders
 */
export type PaymentMode = 'cash' | 'card' | 'applePay';

export type OrderItem = {
  id: string;
  name: string;
  price: number;
  deal: number | null;
  defaultService: number;
  service: number | null;
  quantity: number;
  comment?: string;
  state: TableOrderState;
  orderNumber: number;
  orderedAt: string;
};

export type Refund = {
  id: string;
  amount: number;
  reason: string;
  order: Order;
};

export type Order = {
  id: string;
  createdAt: string;
  table: number;
  username: string;
  profilePicture: string | null;
  status: TableOrderState;
  tip: number | null;
  paymentMode: PaymentMode;
  paid: boolean;
  discount: number | null;
  orderItems: OrderItem[];
  refunds: Refund[];
  visitsCount: VisitsCount[];
};

export type VisitsCount = {
  restaurantId: string;
  visits: number;
};

export type TableCardItem = {
  tableOrder: TableOrderItem;
  orderNumber: number;
  orderedAt: string;
  state: TableOrderState;
};

export const OrderClassName = Parse.Object.extend('Order');

/*
 * Menu
 */

export type MenuCategory = {
  id: string;
  name: string;
  menuItems: string[];
};

export type MenuItem = {
  id: string;
  category: string;
  name: string;
  available: boolean;
  description: string;
  price: number;
  deal: number | null | undefined;
  coverPhoto: string | null;
  defaultService: number | null;
  prepTime: number | null;
};

export type Content = {
  menuCategories: {
    byId: {[id: string]: MenuCategory};
    allIds: string[];
  };
  menuItems: {[id: string]: MenuItem};
  currencySymbol: string;
};

/*
 * Review
 */

export const ReviewClassName = Parse.Object.extend('Review');
