import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en/common.json';
import fr from './fr/common.json';

const userLang = (navigator.language || 'en').substring(0, 2);

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  debug: process.env.NODE_ENV === 'development',
  lng: userLang,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
