import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';

import {AppThunk} from './';

type State = {error: string | null; loading: boolean; isLogged: boolean};

const initialState: State = {error: null, loading: false, isLogged: false};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
    },
    loginSuccess(state) {
      state.loading = false;
      state.error = null;
      state.isLogged = true;
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    logoutSuccess() {
      return {...initialState};
    },
    resetForm(state) {
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  resetForm,
  loginSuccess,
  loginFailed,
  loginStart,
  logoutSuccess,
} = authSlice.actions;

export default authSlice.reducer;

export const login = (email: string, password: string): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(loginStart());
    await Parse.User.logIn(email, password);
  } catch (error) {
    const errorMessage =
      error.code === 101 ? 'Invalid email or password.' : 'An error occured.';
    dispatch(loginFailed(errorMessage));
    return;
  }
  dispatch(loginSuccess());
};

export const logout = (): AppThunk => async (dispatch) => {
  try {
    await Parse.User.logOut();
    dispatch(logoutSuccess());
  } catch (error) {}
};

export const resetPassword = (email: string): AppThunk => async () => {
  try {
    Parse.User.requestPasswordReset(email);
  } catch (error) {}
};
