import React from "react";

import styles from "./index.module.css";
import {TrashIconSvg} from "components/SvgIcon";
import concatStyles from "helpers/concatStyles";

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const DeleteButtonForm: React.FC<Props> = ({onClick, disabled = false}) => {
  const onClickHandler = () => {
    if (!disabled) onClick();
  };
  return (
    <div
      onClick={onClickHandler}
      className={concatStyles([
        styles.button,
        disabled ? styles.disabled : "",
      ])}>
      <TrashIconSvg fill="#A6A6A6" />
    </div>
  );
};

export default DeleteButtonForm;
