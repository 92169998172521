import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';
import {AppThunk} from 'state';
import {
  TableOrderClassName,
  TableOrderItem,
  WaiterClassName,
  TableClassName,
  Order,
  OrderClassName,
  TableOrderState,
  PaymentMode,
  MenuItem,
} from './types';
import {formatTableOrder} from 'helpers/tableOrdersFormatter';
import {formatOrder} from 'helpers/orderFormatter';

interface State {
  error: string | null;
  fetchLoading: boolean;
  deleteLoading: boolean;
  fetchOrdersLoading: boolean;
  updateOrderLoading: boolean;
  addLoading: boolean;
  addSuccessNotification: boolean;
  tableOrders: TableOrderItem[];
  taxRate: number;
}

const initialState: State = {
  error: null,
  fetchLoading: false,
  deleteLoading: false,
  fetchOrdersLoading: false,
  updateOrderLoading: false,
  addLoading: false,
  addSuccessNotification: false,
  tableOrders: [],
  taxRate: 0,
};

const tableOrdersSlice = createSlice({
  name: 'tableOrders',
  initialState,
  reducers: {
    fetchTableOrdersStart(state) {
      state.fetchLoading = true;
      state.error = null;
    },
    fetchTableOrdersSuccess(
      state,
      action: PayloadAction<{taxRate: number; tableOrders: TableOrderItem[]}>,
    ) {
      state.fetchLoading = false;
      state.tableOrders = action.payload.tableOrders;
      state.taxRate = action.payload.taxRate;
    },
    fetchTableOrdersFailed(state, action: PayloadAction<string>) {
      state.fetchLoading = false;
      state.error = action.payload;
    },
    addTableOrderStart(state) {
      state.addLoading = true;
      state.addSuccessNotification = false;
      state.error = null;
    },
    addTableOrderSuccess(state, action: PayloadAction<TableOrderItem>) {
      state.addLoading = false;
      state.addSuccessNotification = true;
      state.tableOrders.push(action.payload);
    },
    addTableOrderFailed(state, action: PayloadAction<string>) {
      state.addLoading = false;
      state.error = action.payload;
    },
    deleteTableOrderStart(state) {
      state.deleteLoading = true;
      state.error = null;
    },
    deleteTableOrderSuccess(state, action: PayloadAction<string>) {
      state.deleteLoading = false;
      const index = state.tableOrders.findIndex(
        (tableOrder) => tableOrder.id === action.payload,
      );

      if (index > -1) {
        state.tableOrders.splice(index, 1);
      }
    },
    deleteTableOrderFailed(state, action: PayloadAction<string>) {
      state.deleteLoading = false;
      state.error = action.payload;
    },
    silentAddTableOrder(state, action: PayloadAction<TableOrderItem>) {
      state.tableOrders.push(action.payload);
    },
    silentUpdateTableOrder(state, action: PayloadAction<TableOrderItem>) {
      const index = state.tableOrders.findIndex(
        (tableOrder) => tableOrder.id === action.payload.id,
      );

      if (index > -1) {
        state.tableOrders[index] = action.payload;
      }
    },
    silentUpdateTableOrderStateAndOrderCount(
      state,
      action: PayloadAction<{
        id: string;
        state: TableOrderState;
        orderCount: number;
      }>,
    ) {
      const index = state.tableOrders.findIndex(
        (tableOrder) => tableOrder.id === action.payload.id,
      );
      if (index > -1) {
        state.tableOrders[index].state = action.payload.state;
        state.tableOrders[index].orderCount = action.payload.orderCount;
      }
    },
    fetchOrdersStart(state) {
      state.fetchOrdersLoading = true;
      state.error = null;
    },
    fetchOrdersSuccess(
      state,
      action: PayloadAction<{id: string; orders: Order[]}>,
    ) {
      state.fetchOrdersLoading = false;
      const index = state.tableOrders.findIndex(
        (tableOrder) => tableOrder.id === action.payload.id,
      );

      if (index > -1) {
        state.tableOrders[index].orders = action.payload.orders;
      }
    },
    fetchOrdersFailed(state, action: PayloadAction<string>) {
      state.fetchOrdersLoading = false;
      state.error = action.payload;
    },
    updateOrderStart(state) {
      state.updateOrderLoading = true;
      state.error = null;
    },
    updateOrderSuccess(
      state,
      action: PayloadAction<{id: string; order: Order}>,
    ) {
      state.updateOrderLoading = false;
      const tableOrder = state.tableOrders.find(
        (tableOrder) => tableOrder.id === action.payload.id,
      );

      if (tableOrder) {
        const index = tableOrder.orders.findIndex(
          (order) => order.id === action.payload.order.id,
        );

        if (index > -1) {
          tableOrder.orders[index] = action.payload.order;
        }
      }
    },
    updateOrderFailed(state, action: PayloadAction<string>) {
      state.updateOrderLoading = false;
      state.error = action.payload;
    },
    silentAddOrder(state, action: PayloadAction<{id: string; order: Order}>) {
      const index = state.tableOrders.findIndex(
        (tableOrder) => tableOrder.id === action.payload.id,
      );

      if (index > -1) {
        state.tableOrders[index].orders.push(action.payload.order);
      }
    },
    silentUpdateOrder(
      state,
      action: PayloadAction<{id: string; order: Order}>,
    ) {
      const tableOrder = state.tableOrders.find(
        (tableOrder) => tableOrder.id === action.payload.id,
      );
      if (tableOrder) {
        const index = tableOrder.orders.findIndex(
          (order) => order.id === action.payload.order.id,
        );

        if (index > -1) {
          tableOrder.orders[index] = action.payload.order;
        }
      }
    },
    reset(state) {
      state.addSuccessNotification = false;
      state.fetchLoading = false;
      state.updateOrderLoading = false;
      state.addLoading = false;
    },
    dismissNotifications(state) {
      state.addSuccessNotification = false;
    },
    purge() {
      return initialState;
    },
  },
});

export const {
  fetchTableOrdersStart,
  fetchTableOrdersSuccess,
  fetchTableOrdersFailed,
  addTableOrderStart,
  addTableOrderSuccess,
  addTableOrderFailed,
  deleteTableOrderStart,
  deleteTableOrderSuccess,
  deleteTableOrderFailed,
  silentAddTableOrder,
  silentUpdateTableOrder,
  silentUpdateTableOrderStateAndOrderCount,
  fetchOrdersStart,
  fetchOrdersSuccess,
  fetchOrdersFailed,
  updateOrderStart,
  updateOrderSuccess,
  updateOrderFailed,
  silentAddOrder,
  silentUpdateOrder,
  reset,
  dismissNotifications,
  purge,
} = tableOrdersSlice.actions;

export default tableOrdersSlice.reducer;

/**
 * Thunks
 */

export const fetchTableOrders = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchTableOrdersStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();
    const taxRate = restaurant.get('tax') || 0;

    const completedTableOrdersQuery = new Parse.Query('TableOrder');
    completedTableOrdersQuery.containedIn('state', ['completed']);
    const progressTableOrdersQuery = new Parse.Query('TableOrder');
    progressTableOrdersQuery.containedIn('state', ['pending', 'progress']);

    const tableOrdersQuery = Parse.Query.or(
      completedTableOrdersQuery,
      progressTableOrdersQuery,
    );
    tableOrdersQuery
      .equalTo('restaurant', restaurant)
      .equalTo('available', true)
      .include([
        'waiter',
        'table',
        'orders',
        'orders.user',
        'orders.discountPointer',
        'orders.refunds',
      ])
      .exists('table')
      .ascending('createdAt');

    const tableOrders = await tableOrdersQuery.find();
    // TODO: Change this, without sometime tableOrder.orders is empty but not in sashido
    await Promise.all(
      tableOrders.map((tableOrder) =>
        tableOrder.fetchWithInclude([
          'orders',
          'orders.user',
          'orders.discountPointer',
        ]),
      ),
    );

    dispatch(
      fetchTableOrdersSuccess({
        taxRate,
        tableOrders: tableOrders.map(formatTableOrder),
      }),
    );
  } catch (error) {
    dispatch(fetchTableOrdersFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchTableOrders error', error);
  }
};

interface AddTableOrderPayload {
  tableId: string;
  waiterId: string;
  minGuests: number;
}

export const addTableOrder = (
  formData: AddTableOrderPayload,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(addTableOrderStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    const waiterPointer = new WaiterClassName().set(
      'objectId',
      formData.waiterId,
    );
    const table = new TableClassName().set('objectId', formData.tableId);
    const tableNumber = getState().tables.tables.find(
      (table) => table.id === formData.tableId,
    )?.name;

    const tableOrder = await new TableOrderClassName().save({
      restaurant,
      usersId: [],
      waiter: waiterPointer,
      orders: [],
      orderCount: 0,
      available: true,
      minGuests: formData.minGuests,
      state: 'pending',
      tableNumber,
      table,
    });
    dispatch(addTableOrderSuccess(formatTableOrder(tableOrder)));
  } catch (error) {
    dispatch(addTableOrderFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('addTableOrder error', error);
  }
};

export const updateOrderItemsAndTableOrderState = (
  id: string,
  state: TableOrderState,
  orderNumber: number,
): AppThunk => async () => {
  const query = new Parse.Query(TableOrderClassName);
  query.equalTo('objectId', id);
  query.include('orders');
  const tableOrder = await query.first();

  // We must save Orders sequentially to avoid renders during a save, which can break the UI
  const saveOrders = async (orders: any) => {
    for (const order of orders) {
      const orderContent = order.get('order');
      // When closing a table, we get rid of pending orders and mark all others as completed
      if (state === 'closed') {
        const filteredContent = orderContent.filter(
          (item: any) => item.state !== 'pending',
        );
        filteredContent.forEach((item: any) => {
          item.state = 'completed';
        });
        order.set('order', filteredContent);
        await order.save();
      } else {
        // Only save orders that have changed
        let saveNeeded = false;
        orderContent.forEach((item: any) => {
          if (item.orderNumber === orderNumber) {
            item.state = state;
            saveNeeded = true;
          }
        });
        if (saveNeeded) {
          order.set('order', orderContent);
          await order.save();
        }
      }
    }
  };

  try {
    if (tableOrder) {
      await saveOrders(tableOrder.get('orders'));
      // console.info('tableOrder.get(\'orders\')', tableOrder.get('orders'))
      if (
        tableOrder.get('orders') &&
        tableOrder
          .get('orders')
          .find((order: any) =>
            order
              .get('order')
              .find(
                (item: any) =>
                  item.state === 'progress' || item.state === 'confirmed',
              ),
          )
      ) {
        tableOrder.set('state', 'progress');
      } else {
        // There's a border case where the target items state is 'confirmed' but the if condition above isn't filled.
        // The hack below handles it but we need to figure out why it happens. TableOrder state cannot be 'confirmed'
        if (state === 'confirmed') {
          console.info(
            'WE SHOULDNT BE HERE FIX THIS => (TableOrder state === confirmed)',
          );
        }
        tableOrder.set('state', state === 'confirmed' ? 'progress' : state);
      }
      await tableOrder.save();
    }
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('saveOrders error', error);
  }
};

export const deleteTableOrder = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(deleteTableOrderStart());

    const query = new Parse.Query(TableOrderClassName);
    const tableOrder = await query
      .include(['orders', 'orders.refunds'])
      .get(id);
    if (tableOrder) {
      await Promise.all(
        tableOrder.get('orders').map((order: any) =>
          order
            .get('refunds')
            .flat()
            .map((refund: any) => refund.destroy()),
        ),
      );
      await Promise.all(
        tableOrder.get('orders').map((order: any) => order.destroy()),
      );
      await tableOrder.destroy();
    }

    dispatch(deleteTableOrderSuccess(id));
  } catch (error) {
    dispatch(deleteTableOrderFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('deleteTableOrder error', error);
  }
};

export const fetchOrders = (tableOrderId: string): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(fetchOrdersStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    const tableOrderPointer = new TableOrderClassName().set(
      'objectId',
      tableOrderId,
    );

    const query = new Parse.Query(OrderClassName);
    query
      .equalTo('restaurant', restaurant)
      .equalTo('tableOrder', tableOrderPointer)
      .include(['user', 'discountPointer', 'refunds'])
      .ascending('createdAt');

    const orders = await query.find();

    dispatch(
      fetchOrdersSuccess({id: tableOrderId, orders: orders.map(formatOrder)}),
    );
  } catch (error) {
    dispatch(fetchOrdersFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchOrders error', error);
  }
};

interface UpdateOrderPayload {
  paid?: boolean;
  paymentMode?: PaymentMode;
}

export const updateOrder = (
  orderId: string,
  formData: UpdateOrderPayload,
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateOrderStart());

    const query = new Parse.Query('Order');
    query.equalTo('objectId', orderId).include(['user', 'discountPointer']);

    const order = await query.first();

    if (order) {
      if (formData.paid !== undefined) {
        order.set('paid', formData.paid);
      }
      if (formData.paymentMode !== undefined) {
        order.set('paymentMode', formData.paymentMode);
      }
      await order.save();
      dispatch(
        updateOrderSuccess({
          id: order.get('tableOrder').id,
          order: formatOrder(order),
        }),
      );
    } else {
      dispatch(updateOrderFailed('Unknown order id'));
    }
  } catch (error) {
    dispatch(updateOrderFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('updateOrder error', error);
  }
};

export const orderItems = (
  orderId: string,
  menuItem: MenuItem,
  quantity: number,
  tableOrder: TableOrderItem,
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateOrderStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    const query = new Parse.Query('Order');
    query.equalTo('objectId', orderId).include(['user', 'discountPointer']);

    const order = await query.first();

    const tableOrderQuery = new Parse.Query('TableOrder');
    query.equalTo('objectId', tableOrder.id);

    const tableOrderFromParse = await tableOrderQuery.first();

    if (order && tableOrderFromParse) {
      tableOrderFromParse.increment('orderCount');
      await tableOrderFromParse.save();
      const orderNumber = tableOrderFromParse.get('orderCount');
      const orderItems = order.get('order');
      orderItems.push({
        item: restaurant
          .get('menu')
          .find((category: any) => category.id === menuItem.category)
          .menu.find((item: any) => item.id === menuItem.id),
        number: quantity,
        comment: '',
        state: 'progress',
        orderNumber: orderNumber,
      });
      order.set('order', orderItems);
      await order.save();

      dispatch(
        updateOrderSuccess({
          id: order.get('tableOrder').id,
          order: formatOrder(order),
        }),
      );
    } else {
      dispatch(updateOrderFailed('Unknown order id'));
    }
  } catch (error) {
    dispatch(updateOrderFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('orderItems error', error);
  }
};

export const addRefund = (
  amount: number,
  taxRate: number,
  tip: number,
  reason: string,
  order: Order,
): AppThunk => async (dispatch) => {
  const query = new Parse.Query('Order');
  query.equalTo('objectId', order.id).include(['user', 'discountPointer']);

  const orderFromParse = await query.first();

  if (orderFromParse) {
    const paidByCard =
      orderFromParse.get('paid') &&
      orderFromParse.get('paymentMode') === 'card';

    const totalAmount = amount + amount * taxRate + amount * tip;
    try {
      if (paidByCard) {
        const transactionId = orderFromParse.get('transactionId');
        if (!transactionId) new Error('No transaction id to refund...');
        await Parse.Cloud.run('refund', {
          transactionId,
          totalAmount,
        });
      }
      const Refund = Parse.Object.extend('Refund');
      const refund = new Refund();

      refund.set('amount', amount);
      refund.set('taxRate', taxRate);
      refund.set('tip', tip);
      refund.set('totalAmount', totalAmount);
      refund.set('reason', reason);
      refund.set('order', orderFromParse);

      dispatch(updateOrderStart());
      await refund.save();
      const refunds = orderFromParse.get('refunds') || [];
      refunds.push(refund);
      orderFromParse.set('refunds', refunds);

      await orderFromParse.save();

      dispatch(
        updateOrderSuccess({
          id: orderFromParse.get('tableOrder').id,
          order: formatOrder(orderFromParse),
        }),
      );
    } catch (error) {
      dispatch(updateOrderFailed(error.toString()));
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.error('addRefund error', error);
    }
  } else {
    dispatch(updateOrderFailed('Unknown order.id'));
  }
};
