import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import styles from './index.module.css';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import {emailRegex} from 'helpers/validateEmail';
import {MailIconSvg} from 'components/SvgIcon';
import {resetPassword} from 'state/auth';
import {useTranslation} from 'react-i18next';

interface Props {}

type FormInputs = {
  email: string;
};

const ForgotPassword: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [sent, setSent] = useState(false);
  const {register, handleSubmit, errors} = useForm<FormInputs>();

  const onSubmitHandler = ({email}: FormInputs) => {
    setSent(true);
    dispatch(resetPassword(email));
  };

  const required = t('common.required');
  const invalid = t('common.invalidEmail');

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('features.ForgotPassword.title')}</h1>
      {sent ? (
        <>
          <h2 className={styles.message}>
            {t('features.ForgotPassword.text')}
          </h2>
          <MailIconSvg fill="#fff" />
        </>
      ) : (
        <>
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className={styles.form}>
            <TextInput
              name="email"
              placeholder={t('common.email')}
              type="email"
              error={errors.email?.message}
              ref={register({
                required: required,
                pattern: {value: emailRegex, message: invalid},
              })}
            />
            <Button text={t('features.ForgotPassword.button')} />
          </form>
          <Link to="/login" className={styles.link}>
            {t('features.ForgotPassword.back')}
          </Link>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
