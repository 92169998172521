import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import styles from './index.module.css';
import {LogoSvg} from 'components/SvgIcon';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import {emailRegex} from 'helpers/validateEmail';
import {RootState} from 'state';
import {login, resetForm} from 'state/auth';
import {useTranslation} from 'react-i18next';

import garcon from '../../assets/images/garcon-g.png';

interface Props {}

type FormInputs = {
  email: string;
  password: string;
};

const Login: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {register, handleSubmit, errors} = useForm<FormInputs>();

  const {error: loginError, loading} = useSelector(
    (state: RootState) => state.auth,
  );

  const required = t('common.required');
  const invalid = t('common.invalidEmail');

  const onSubmitHandler = ({email, password}: FormInputs) => {
    dispatch(login(email, password));
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <img src={garcon} />
      <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
        <TextInput
          name="email"
          placeholder={t('common.email')}
          type="email"
          error={errors.email?.message}
          ref={register({
            required: required,
            pattern: {value: emailRegex, message: invalid},
          })}
        />
        <TextInput
          name="password"
          placeholder={t('common.password')}
          type="password"
          error={errors.password?.message}
          ref={register({required: required})}
        />
        <Button loading={loading} error={loginError} text="Login" />
        {loginError && <span className={styles.errorLabel}>{loginError}</span>}
      </form>
      <Link to="/forgot" className={styles.link}>
        {t('common.lostPassword')}
      </Link>
    </div>
  );
};

export default Login;
