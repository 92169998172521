import React from 'react';

import styles from './index.module.css';
import concatStyles from 'helpers/concatStyles';
import {CaretDownIconSvg} from 'components/SvgIcon';

interface Props {
  className?: string;
  selectClassName?: string;
  label: string;
  defaultValue?: string | number;
  disabled?: boolean;
  loading?: boolean;
  options: {value: any; label: string}[];
  value: any;
  onChange: any;
}

const SelectInputForm: React.FC<Props> = ({
  className,
  selectClassName,
  value,
  label,
  defaultValue,
  options,
  loading = false,
  disabled = false,
  onChange,
}) => (
  <div
    className={concatStyles([
      styles.container,
      loading ? styles.skeleton : '',
      className,
    ])}>
    <label className={styles.label} htmlFor={label}>
      {label}
    </label>
    {!loading && (
      <select
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        id={label}
        className={concatStyles([styles.input, selectClassName])}>
        {options.map((option) => (
          <option key={`option-${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )}
    <CaretDownIconSvg size={16} fill="#A6A6A6" />
  </div>
);

export default SelectInputForm;
