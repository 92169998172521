import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../state';

interface Props {
  amount: number;
}

const FormattedPrice: React.FC<Props> = ({amount}) => {
  const {currency, country} = useSelector((state: RootState) => state.profile);
  console.info('currency', currency);
  console.info('country', country);
  const price = new Intl.NumberFormat(country || 'fr-FR', {
    style: 'currency',
    currency: currency || 'EUR',
  }).format(amount);

  return <>{price}</>;
};

export default FormattedPrice;
