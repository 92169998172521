import React from "react";

import styles from "./index.module.css";
import concatStyles from "helpers/concatStyles";

interface Props {
  name: string;
  type?: string;
  label: string;
  error?: boolean;
  defaultValue?: string | number;
  disabled?: boolean;
  loading?: boolean;
  suffix?: string;
  min?: number;
  max?: number;
  onChange?: (value: string) => void;
  className?: string;
}

const TextInputForm = React.forwardRef<any, Props>(
  (
    {
      type = "text",
      loading = false,
      name,
      label,
      error = false,
      defaultValue,
      disabled,
      suffix,
      min,
      max,
      onChange,
      className,
    },
    ref,
  ) => (
    <div
      className={concatStyles([
        styles.container,
        error ? styles.error : "",
        loading ? styles.skeleton : "",
        className,
      ])}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      {!loading && (
        <>
          <input
            id={name}
            ref={ref}
            autoComplete="none"
            type={type}
            name={name}
            defaultValue={defaultValue}
            disabled={disabled}
            className={styles.input}
            min={min}
            max={max}
            onChange={(e) => onChange && onChange(e.target.value)}
          />
          <span className={styles.suffix}>{suffix}</span>
        </>
      )}
    </div>
  ),
);

export default TextInputForm;
