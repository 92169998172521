import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import i18next from 'i18next';
import {I18nextProvider} from 'react-i18next';
import {ToastProvider} from 'react-toast-notifications';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'styles/react_dates_overrides.css';
import './locales/i18n';

import styles from './index.module.css';

import configureStore from 'state';
import Routes from 'routes';
import Toast from 'components/Toast';

const Parse = require('parse');

Parse.initialize(
  process.env.REACT_APP_PARSE_APP_ID!,
  process.env.REACT_APP_PARSE_JS_KEY!,
);

Parse.serverURL =
  'https://pg-app-jtxlimrcv63uszqdkzh2a9d1r7pd0u.scalabl.cloud/1/';

export const App: React.FC = () => {
  const {store, persistor} = configureStore;

  return (
    <ReduxProvider store={store}>
      <I18nextProvider i18n={i18next}>
        <PersistGate persistor={persistor}>
          <ToastProvider
            autoDismiss
            placement="bottom-left"
            components={{Toast}}>
            <Routes />
            <div className={styles.powered}>Powered by garçon</div>
          </ToastProvider>
        </PersistGate>
      </I18nextProvider>
    </ReduxProvider>
  );
};

export default App;
