import React from 'react';
import styles from './index.module.css';
import concatStyles from 'helpers/concatStyles';

interface Props {
  onChange: (number: number) => void;
  selected: number;
  size: number;
}

const NumberList: React.FC<Props> = ({size, selected, onChange}) => {
  const handleClick = (number: number) => {
    onChange(number);
  };

  return (
    <div className={styles.container}>
      {Array.from(Array(size).keys()).map((number) => (
        <b
          key={number}
          className={concatStyles([
            styles.item,
            selected === number + 1 ? styles.itemSelected : '',
          ])}
          onClick={() => handleClick(number + 1)}>
          {number + 1}
        </b>
      ))}
    </div>
  );
};

export default NumberList;
