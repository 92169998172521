import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import styles from './index.module.css';
import Modal from 'components/Modal';
import ImageUpload from 'components/ImageUpload';
import TextInputForm from 'components/TextInputForm';
import Button from 'components/Button';

interface Props {
  requestClose: Function;
  onSubmit: (values: Form, profilePictureFile: any) => void;
}

export interface Form {
  name: string;
}

const AddModal: React.FC<Props> = ({requestClose, onSubmit}) => {
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const {register, handleSubmit, errors} = useForm<Form>();

  const onSubmitHandler = (values: Form) => {
    onSubmit(values, profilePicture?.file);
    requestClose();
  };

  const onUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];

    setProfilePicture({url: URL.createObjectURL(file), file});
  };

  return (
    <Modal title="Add waiter" requestClose={requestClose}>
      <form
        className={styles.container}
        onSubmit={handleSubmit(onSubmitHandler)}>
        <ImageUpload
          style={styles.image}
          title="Photo"
          url={profilePicture?.url}
          onRemove={() => setProfilePicture(null)}
          onUpload={onUploadHandler}
        />
        <TextInputForm
          ref={register({
            required: 'Name required',
            maxLength: {value: 30, message: 'Max length reached (30)'},
          })}
          name="name"
          error={!!errors.name}
          label="Name"
        />
        {errors.name && (
          <span className={styles.error}>{errors.name.message}</span>
        )}
        <Button className={styles.button} text="Add waiter" />
      </form>
    </Modal>
  );
};

export default AddModal;
