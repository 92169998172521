import React from 'react';
import styles from './index.module.css';
import concatStyles from 'helpers/concatStyles';
import {PlusIcon} from 'components/SvgIcon';

interface Props {
  onClick: Function;
  loading: boolean;
}

const AddCard: React.FC<Props> = ({onClick, loading}) => {
  return (
    <div
      className={concatStyles([
        styles.container,
        loading ? styles.skeleton : '',
      ])}
      onClick={loading ? null : (onClick as any)}>
      {!loading && <PlusIcon size={80} fill="#D4D4D4" />}
    </div>
  );
};

export default AddCard;
