import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';

import {AppThunk} from 'state';
import {normalizeMenu, updateMenuFormatter} from 'helpers/menuFormatter';
import {MenuCategory, MenuItem, Content} from './types';

interface State extends Content {
  error: string | null;
  fetchLoading: boolean;
  updateLoading: boolean;
  successNotification: boolean;
  deleteNotification: boolean;
  menuCategories: {
    byId: {[id: string]: MenuCategory};
    allIds: string[];
  };
  menuItems: {[id: string]: MenuItem};
}

const initialState: State = {
  error: null,
  fetchLoading: false,
  updateLoading: false,
  successNotification: false,
  deleteNotification: false,
  menuCategories: {byId: {}, allIds: []},
  menuItems: {},
  currencySymbol: '',
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    fetchMenuStart(state) {
      state.fetchLoading = true;
      state.error = null;
      state.updateLoading = false;
    },
    fetchMenuSuccess(state, action: PayloadAction<Content>) {
      return {...state, ...action.payload, fetchLoading: false};
    },
    fetchMenuFailed(state, action: PayloadAction<string>) {
      state.fetchLoading = false;
      state.error = action.payload;
    },
    updateMenuStart(state) {
      state.updateLoading = true;
      state.error = null;
      state.successNotification = false;
    },
    updateMenuSuccess(state, action: PayloadAction<Content>) {
      state.updateLoading = false;
      state.menuCategories = action.payload.menuCategories;
      state.menuItems = action.payload.menuItems;
      state.successNotification = true;
    },
    updateMenuFailed(state, action: PayloadAction<string>) {
      state.updateLoading = false;
      state.error = action.payload;
    },
    deleteItemStart(state) {
      state.updateLoading = true;
      state.deleteNotification = false;
    },
    deleteItemSuccess(state, action: PayloadAction<string>) {
      const itemId = action.payload;

      state.updateLoading = false;
      state.deleteNotification = true;

      // delete from category
      const categoryId = state.menuItems[itemId].category;
      const menuItemsIds = state.menuCategories.byId[categoryId].menuItems;
      // delete at id index
      const index = menuItemsIds.findIndex((id) => id === itemId);
      menuItemsIds.splice(index, 1);

      // delete menu item
      delete state.menuItems[itemId];
    },
    deleteItemFailed(state, action: PayloadAction<string>) {
      state.updateLoading = false;
      state.error = action.payload;
    },
    reset(state) {
      state.updateLoading = false;
      state.fetchLoading = false;
      state.error = null;
      state.deleteNotification = false;
      state.successNotification = false;
    },
    dismissNotification(state) {
      state.successNotification = false;
      state.deleteNotification = false;
    },
    purge() {
      return initialState;
    },
  },
});

export const {
  fetchMenuStart,
  fetchMenuSuccess,
  fetchMenuFailed,
  updateMenuStart,
  updateMenuSuccess,
  updateMenuFailed,
  deleteItemStart,
  deleteItemSuccess,
  deleteItemFailed,
  reset,
  dismissNotification,
  purge,
} = menuSlice.actions;

export default menuSlice.reducer;

export const fetchMenu = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchMenuStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();
    const data = restaurant.attributes.menu;
    const currencySymbol = restaurant.attributes.currencySymbol;

    const normalizedData = normalizeMenu(data);
    normalizedData.currencySymbol = currencySymbol;

    dispatch(fetchMenuSuccess(normalizedData));
  } catch (error) {
    dispatch(fetchMenuFailed(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchMenu error', error);
  }
};

interface UpdateMenuPayload extends MenuItem {
  position: number;
}

export const updateMenu = (
  formData: UpdateMenuPayload,
  image: any,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(updateMenuStart());

    const {position, ...menuItem} = formData;
    const content = JSON.parse(JSON.stringify(getState().menu));

    // cover photo default from store url, null if delete or a Parse file if new image
    let coverPhoto =
      content.menuItems[menuItem.id] &&
      content.menuItems[menuItem.id].coverPhoto;

    if (image) {
      if (image !== 'delete') {
        const file = new Parse.File('coverPhoto', image);
        coverPhoto = await file.save();
      } else {
        coverPhoto = null;
      }
    }

    // update the content
    const updatedContent = updateMenuFormatter(
      menuItem,
      content,
      position - 1,
      image && image !== 'delete' ? coverPhoto._url : coverPhoto,
    );

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();
    const menu = restaurant.attributes.menu;

    // dict of cover photo to repopulate
    const coverPhotos = menu.reduce((acc: any, category: any) => {
      category.menu.forEach((row: any) => {
        if (menuItem.id === row.id) {
          acc[row.id] = image ? coverPhoto : row.coverPhoto;
        } else {
          acc[row.id] = row.coverPhoto;
        }
      });

      return acc;
    }, {});

    // format menu for parse sdk
    const formattedMenu = updatedContent.menuCategories.allIds.map(
      (categoryId) => {
        const category: any = {
          ...updatedContent.menuCategories.byId[categoryId],
        };

        category.menu = category.menuItems.map((menuId: string) => {
          const item = {
            ...updatedContent.menuItems[menuId],
            category: undefined,
          };
          item.coverPhoto = coverPhotos[menuId] || coverPhoto; // default cover photo or new one

          return item;
        });

        // backend use menu as name so we delete menuItems
        delete category.menuItems;

        return category;
      },
    );

    restaurant.set('menu', formattedMenu);
    await restaurant.save();

    dispatch(updateMenuSuccess(updatedContent));
  } catch (error) {
    dispatch(updateMenuFailed(error.message));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('updateMenu error', error);
  }
};

export const deleteItem = (itemId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(deleteItemStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();
    const menu = restaurant.attributes.menu;

    const filteredMenu = menu.map((row: any) => {
      row.menu = row.menu.filter((menuItem: any) => menuItem.id !== itemId);
      return row;
    });

    restaurant.set('menu', filteredMenu);
    await restaurant.save();

    dispatch(deleteItemSuccess(itemId));
  } catch (error) {
    dispatch(deleteItemFailed(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('deleteItem error', error);
  }
};

interface UpdateMenuPayload extends MenuItem {
  position: number;
}
