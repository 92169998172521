import React from 'react';
import styles from './index.module.css';
import {TableItem, TableOrderItem} from 'slices/types';
import concatStyles from 'helpers/concatStyles';
import useCountdown from 'hooks/useCountdown';
import {Call} from '../../slices/waiterCall';
import {BellIconSvg} from 'components/SvgIcon';
import {PaymentIconSvg} from 'components/SvgIcon';
import {useSelector} from 'react-redux';
import {RootState} from '../../state';
import {useTranslation} from 'react-i18next';

interface Props {
  style?: Object;
  item: TableItem;
  tableOrder?: TableOrderItem | null;
  onClick: Function;
  loading?: boolean;
  disableClick?: boolean;
  showScan?: boolean;
  orderNumber?: number;
  orderedAt?: string;
  className?: string;
}

const TableCard: React.FC<Props> = ({
  style,
  item,
  tableOrder,
  onClick,
  loading,
  disableClick,
  showScan,
  orderNumber,
  orderedAt,
  className,
}) => {
  const {t} = useTranslation();
  const {queue} = useSelector((state: RootState) => state.waiterCall);
  const waiterCall = queue.filter(
    (call) => call.tableNumber === tableOrder?.table.name,
  );
  const countdown = useCountdown(
    orderedAt ? orderedAt : tableOrder ? tableOrder.createdAt : item.createdAt,
    !tableOrder ||
      tableOrder.state === 'completed' ||
      tableOrder.state === 'closed',
  );
  const numPaid = tableOrder?.orders.filter((order) => order.paid).length;
  const currentCustomers =
    tableOrder &&
    (tableOrder.usersId.length > 0
      ? tableOrder.usersId.length
      : tableOrder?.minGuests);

  const bellNumber = waiterCall
    ? waiterCall.filter((call: Call) => call.type === 'waiterCall').length
    : 0;

  const paymentNumber = waiterCall
    ? waiterCall.filter((call: Call) => call.type === 'waiterPayment').length
    : 0;

  return (
    <div
      className={concatStyles([
        styles.container,
        loading ? styles.skeleton : '',
        bellNumber + paymentNumber > 0 ? styles.bordered : '',
        className,
      ])}
      style={style}
      onClick={loading || disableClick ? undefined : (onClick as any)}>
      {!loading && (
        <>
          <div className={styles.subContainer}>
            <span
              className={styles.tableTitle}
              style={
                numPaid && tableOrder && numPaid >= currentCustomers!
                  ? {color: 'green'}
                  : undefined
              }>
              {t('common.table')}
            </span>
            <span
              className={styles.tableName}
              style={
                numPaid && tableOrder && numPaid >= currentCustomers!
                  ? {color: 'green'}
                  : undefined
              }>
              {item.name}
            </span>
          </div>
          {bellNumber + paymentNumber > 0 && (
            <div className={styles.subContainer}>
              {bellNumber > 0 && (
                <div className={styles.iconContainer}>
                  <BellIconSvg />
                  <div className={styles.number}>{bellNumber}</div>
                </div>
              )}
              {paymentNumber > 0 && (
                <div className={styles.iconContainer}>
                  <PaymentIconSvg />
                  <div className={styles.number}>{paymentNumber}</div>
                </div>
              )}
            </div>
          )}
          <div
            className={concatStyles([styles.subContainer, styles.alignRight])}>
            <div>
              {tableOrder
                ? orderNumber
                  ? t('features.TableCard.orderNumber', {orderNumber})
                  : t('features.TableCard.customers', {currentCustomers})
                : t('common.available')}
            </div>
            <div className={styles.subtitle}>
              {tableOrder ? (
                <div className={styles.countdown}>{countdown}</div>
              ) : (
                t('common.upTo', {capacity: item.capacity})
              )}
            </div>
            {tableOrder && (
              <div className={styles.caption}>
                {showScan ? tableOrder.usersId.length : numPaid}/
                {currentCustomers}{' '}
                {showScan ? t('common.scanned') : t('common.paid')}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TableCard;
