import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {lock} from "slices/password";

const usePassword = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(lock());
    };
  }, [dispatch]);
};

export default usePassword;
