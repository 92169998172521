import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';
import useSound from 'use-sound';

import {
  fetchTableOrders,
  silentAddTableOrder,
  silentAddOrder,
  silentUpdateOrder,
  silentUpdateTableOrder,
} from 'slices/tableOrders';
import {formatTableOrder} from 'helpers/tableOrdersFormatter';
import {formatOrder} from 'helpers/orderFormatter';
import newOrderSound from 'assets/audio/bell.mp3';
// import {OrderItem, TableOrderItem} from '../../slices/types';
// import {RootState} from '../../state';
import i18next from 'i18next';

let isSubscriptionTableOrderClosed = false;
let isSubscriptionOrderClosed = false;

const useLiveTableOrders = () => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const [playSound] = useSound(newOrderSound, {volume: 0.3});
  const [trigger, setTrigger] = useState<any>(null);
  const subscriptions: any = {};

  // const {tableOrders} = useSelector((state: RootState) => state.tableOrders);

  const subscribe = async () => {
    const currentUser = Parse.User.current()!;
    const restaurant = currentUser.get('restaurant');

    const queryTableOrders = new Parse.Query('TableOrder');
    queryTableOrders.equalTo('restaurant', restaurant);

    console.info('----------------');
    subscriptions.subscriptionTableOrders = await queryTableOrders.subscribe();
    console.info('TableOrders screen > TableOrder socket > subscribed');

    const fetchTableOrderContent = async (data: any, type: string) => {
      console.info('----------------');
      console.info(`TableOrders screen > TableOrder socket > ${type}: `, data);
      try {
        data.set('waiter', await data.get('waiter').fetch());
        data.set('table', await data.get('table').fetch());
        data.set(
          'orders',
          await Promise.all(
            data
              .get('orders')
              .map((order: any) =>
                order.fetchWithInclude(['user', 'discountPointer']),
              ),
          ),
        );
        const tableOrder = formatTableOrder(data);
        setTrigger({tableOrder, type: type});
      } catch (e) {
        console.error('Error in useLiveTableOrders', e);
        Sentry.captureException(e);
      }
    };

    subscriptions.subscriptionTableOrders.on('update', (data: any) =>
      fetchTableOrderContent(data, 'update'),
    );

    subscriptions.subscriptionTableOrders.on('create', (data: any) =>
      fetchTableOrderContent(data, 'add'),
    );

    subscriptions.subscriptionTableOrders.on('open', () => {
      console.info('----------------');
      console.info('TableOrders screen > TableOrder socket > open');
      if (isSubscriptionTableOrderClosed) {
        dispatch(fetchTableOrders());
        isSubscriptionTableOrderClosed = false;
      }
    });

    subscriptions.subscriptionTableOrders.on('close', () => {
      console.info('----------------');
      console.info('TableOrders screen > TableOrder socket > close');
      isSubscriptionTableOrderClosed = true;
    });

    const queryOrders = new Parse.Query('Order');
    queryOrders.equalTo('restaurant', restaurant);

    subscriptions.subscriptionOrders = await queryOrders.subscribe();
    console.info('TableOrders screen > Order socket > subscribed');

    const doOnOrderMessage = async (data: any, type: string) => {
      console.info('----------------');
      console.info(`TableOrders screen > Order socket > ${type}: `, data);
      // const tableOrderFromParse = await data
      //   .get('tableOrder')
      //   .fetchWithInclude(['orders']);
      // Todo : reactivate this code when issue with livequeries is resolved
      // if (type === 'update') {
      //   const tableOrder = tableOrders.find(
      //     (tableOrder: TableOrderItem) =>
      //       tableOrder.id === tableOrderFromParse.id,
      //   );
      //   const orderContentHasChanged =
      //     tableOrderFromParse
      //       .get('orders')
      //       .find((order: any) => order.id === data.id)
      //       .get('order').length !==
      //     (tableOrder?.orders.find((order) => order.id === data.id)?.orderItems
      //       .length || 0);
      //
      //   // const hasOrderSwitchPendingToOtherState = tableOrderFromParse
      //   //   .get('orders')
      //   //   .some((orderFromParse: any) => {
      //   //     console.log('tableOrderFromStore', tableOrder);
      //   //     const orderFromStore = tableOrder?.orders.find(
      //   //       (orderFromParse: any) => orderFromParse.id === order.id,
      //   //     );
      //   //
      //   //     console.log('orderFromStore', orderFromStore);
      //   //     console.log('orderFromParse', orderFromParse);
      //   //     if (
      //   //       orderFromParse.get('state') !== 'pending' &&
      //   //       (!orderFromStore || orderFromStore.status === 'pending')
      //   //     ) {
      //   //       return true;
      //   //     }
      //   //     return false;
      //   //   });
      //
      //   const newContentIsNotPending = !data
      //     .get('order')
      //     .find((item: OrderItem) => item.state === 'pending');
      //   const tableOrderIsNotPending =
      //     tableOrderFromParse.get('state') !== 'pending';
      //   if (
      //     orderContentHasChanged &&
      //     newContentIsNotPending &&
      //     tableOrderIsNotPending
      //     // hasOrderSwitchPendingToOtherState
      //   ) {
      //     playSound();
      //     addToast(
      //       i18next.t('toasts.newOrder', {
      //         tableNumber: tableOrderFromParse.get('tableNumber'),
      //       }),
      //       {appearance: 'success', autoDismiss: true},
      //     );
      //   }
      // }
      try {
        data.set('user', await data.get('user').fetch());
        data.set(
          'discountPointer',
          await (data.get('discountPointer')
            ? data.get('discountPointer').fetch()
            : Promise.resolve()),
        );
        const order = formatOrder(data);
        dispatch(
          (type === 'create' ? silentAddOrder : silentUpdateOrder)({
            id: data.get('tableOrder').id,
            order,
          }),
        );
      } catch (error) {
        Sentry.captureException(error);
        // eslint-disable-next-line no-console
        console.error('error getting user and discountPointer', error);
      }
    };

    subscriptions.subscriptionOrders.on('create', (data: any) =>
      doOnOrderMessage(data, 'create'),
    );
    subscriptions.subscriptionOrders.on('update', (data: any) =>
      doOnOrderMessage(data, 'update'),
    );

    subscriptions.subscriptionOrders.on('open', () => {
      console.info('----------------');
      console.info('TableOrders screen > Order socket > open');
      if (isSubscriptionOrderClosed) {
        dispatch(fetchTableOrders());
        isSubscriptionOrderClosed = false;
      }
    });

    subscriptions.subscriptionOrders.on('close', () => {
      console.info('----------------');
      console.info('TableOrders screen > Order socket > close');
      isSubscriptionOrderClosed = true;
    });
  };

  // update order
  useEffect(() => {
    if (trigger) {
      if (trigger.type === 'add') {
        dispatch(silentAddTableOrder(trigger.tableOrder));
        addToast(i18next.t('toasts.newTableOrder'), {
          appearance: 'success',
          autoDismiss: true,
        });
      } else if (trigger.type === 'update') {
        dispatch(silentUpdateTableOrder(trigger.tableOrder));
      }
      setTrigger(null);
    }
  }, [trigger, dispatch, playSound, addToast]);

  useEffect(() => {
    subscribe();

    return () => {
      console.info('----------------');
      if (subscriptions.subscriptionTableOrders)
        subscriptions.subscriptionTableOrders.unsubscribe().then(() => {
          console.info('TableOrders screen > TableOrder socket > unsubscribed');
          isSubscriptionTableOrderClosed = false;
        });
      if (subscriptions.subscriptionOrders)
        subscriptions.subscriptionOrders.unsubscribe().then(() => {
          console.info('TableOrders screen > Order socket > unsubscribed');
          isSubscriptionOrderClosed = false;
        });
    };
  }, []);
};

export default useLiveTableOrders;
