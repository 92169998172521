import React, {useState, useEffect} from 'react';
import styles from './index.module.css';
import AddUpdateForm, {Form as FormAddUpdate} from '../AddUpdateForm';
import OpenForm, {Form as FormOpen} from '../OpenForm';
import {WaiterItem, TableItem} from 'slices/types';
import Modal from 'components/Modal';
import {useTranslation} from 'react-i18next';

interface Props {
  table: TableItem;
  waiters: WaiterItem[];
  requestClose: Function;
  onUpdate: (id: string, values: FormAddUpdate) => void;
  onDelete: (table: TableItem) => void;
  onOpen: (id: string, values: FormOpen) => void;
}

const MainModal: React.FC<Props> = ({
  table,
  waiters,
  requestClose,
  onUpdate,
  onDelete,
  onOpen,
}) => {
  const [updateMode, setUpdateMode] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (waiters.length === 0) {
      alert(t('features.Tables.MainModal.cantOpen'));
      requestClose();
    }
  });

  const onUpdateHandler = (values: FormAddUpdate) => {
    requestClose();

    if (values.name !== table.name || values.capacity !== table.capacity) {
      onUpdate(table.id, values);
    }
  };

  const onDeleteHandler = () => {
    requestClose();
    onDelete(table);
  };

  const onOpenHandler = (values: FormOpen) => {
    requestClose();
    onOpen(table.id, values);
  };

  if (waiters.length === 0) return null;

  return (
    <Modal
      title={t('features.Tables.MainModal.name', {name: table.name})}
      requestClose={requestClose}
      headerCustom={() => (
        <>
          <h4
            className={styles.headerButton}
            onClick={() => setUpdateMode(!updateMode)}>
            {updateMode ? t('common.open') : t('common.edit')}
          </h4>
          <h4 className={styles.headerButton} onClick={onDeleteHandler}>
            {t('common.delete')}
          </h4>
        </>
      )}>
      {updateMode ? (
        <AddUpdateForm
          onSubmit={onUpdateHandler}
          defaultValues={{name: table.name, capacity: table.capacity}}
          submitButtonText={t('common.update')}
        />
      ) : (
        <OpenForm
          capacity={table.capacity}
          waiters={waiters}
          onSubmit={onOpenHandler}
        />
      )}
    </Modal>
  );
};

export default MainModal;
