import React from "react";

import styles from "./index.module.css";
import concatStyles from "helpers/concatStyles";

interface Props {
  text: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onClick: () => void;
}

const ButtonForm: React.FC<Props> = ({
  text,
  disabled,
  className = "",
  loading,
  onClick,
}) => {
  const onClickHandler = () => {
    if (!disabled) onClick();
  };
  return (
    <div
      onClick={loading ? undefined : onClickHandler}
      className={concatStyles([
        styles.button,
        className,
        loading ? styles.skeleton : "",
        disabled ? styles.disabled : "",
      ])}>
      {text}
    </div>
  );
};

export default ButtonForm;
