import React from 'react';
import styles from './index.module.css';
import {WaiterItem} from 'slices/types';
import WaiterListItem from '../WaitersListItem';

interface Props {
  waiters: WaiterItem[];
  selected: string | undefined;
  onChange: (waiterId: string) => void;
}

const WaitersList: React.FC<Props> = ({waiters, selected, onChange}) => {
  const handleClick = (index: number) => {
    onChange(waiters[index].id);
  };

  return (
    <div className={styles.container}>
      {waiters.map((waiter, i) => (
        <WaiterListItem
          key={waiter.id}
          name={waiter.name}
          profilePicture={waiter.profilePicture}
          selected={selected === waiter.id}
          onClick={() => handleClick(i)}
        />
      ))}
    </div>
  );
};

export default WaitersList;
