import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Login from 'features/Login';
import ForgotPassword from 'features/ForgotPassword';

export const PublicRoutes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Redirect from="*" to="/login" />
      </Switch>
    </Router>
  );
};

export default PublicRoutes;
