import {Order} from '../slices/types';

const calculatePriceSubtotals = (order: Order, taxRate: number) => {
  const {orderItems, refunds, tip, discount} = order;

  const orderedItems = orderItems.filter((item) => item.state !== 'pending');

  const totalRefund = refunds
    ? refunds.reduce((acc, refund) => acc + refund.amount, 0)
    : 0;

  const itemsTotal = orderedItems.map(
    ({quantity, price, deal}) => (price - (deal || 0)) * quantity,
  );

  const subTotal = parseFloat(
    (
      itemsTotal.reduce((acc, itemTotal) => acc + itemTotal, 0) -
      (discount || 0) -
      totalRefund
    ).toFixed(2),
  );

  const tips = (tip || 0) * subTotal;

  const taxes = taxRate * subTotal;

  const total = subTotal + taxes + tips;

  return {totalRefund, subTotal, tips, taxes, total};
};

export default calculatePriceSubtotals;
