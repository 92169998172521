import {WaiterItem} from 'slices/types';

export const formatWaiter = (waiter: any): WaiterItem => {
  const profilePicture = waiter ? waiter.get('profilePicture') : null;

  return {
    id: waiter.id,
    createdAt: waiter.get('createdAt').toString(),
    name: waiter.get('name'),
    available: waiter.get('available') || false,
    profilePicture: profilePicture && profilePicture._url,
    rating: waiter.get('rating'),
    reviewsCount: waiter.get('reviewsCount'),
  };
};
