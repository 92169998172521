import React, {FC} from "react";

interface Props {
  fill?: string;
  size?: number;
}

export const PaymentIcon: FC<Props> = ({size = 35}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#0E9347"
        d="M488.727,325.818H23.273C10.473,325.818,0,315.345,0,302.545v-256
	c0-12.8,10.473-23.273,23.273-23.273h465.455c12.8,0,23.273,10.473,23.273,23.273v256C512,315.345,501.527,325.818,488.727,325.818z
	"
      />
      <path
        fill="#0D8944"
        d="M430.545,232.727c-26.764,0-51.2,12.8-65.164,33.745C353.745,260.655,340.945,256,325.818,256
	c-40.727,0-74.473,30.255-80.291,69.818h243.2c11.636,0,20.945-8.145,23.273-19.782
	C507.345,265.309,472.436,232.727,430.545,232.727z"
      />
      <path
        fill="#3BB54A"
        d="M442.182,302.545H69.818c0-25.6-20.945-46.545-46.545-46.545V93.091
	c25.6,0,46.545-20.945,46.545-46.545h372.364c0,25.6,20.945,46.545,46.545,46.545V256C463.127,256,442.182,276.945,442.182,302.545z
	"
      />
      <g>
        <path
          fill="#0E9347"
          d="M430.545,232.727c-26.764,0-51.2,12.8-65.164,33.745C353.745,260.655,340.945,256,325.818,256
		c-32.582,0-60.509,18.618-73.309,46.545h189.673c0-25.6,19.782-45.382,45.382-46.545
		C472.436,242.036,452.655,232.727,430.545,232.727z"
        />
        <ellipse
          fill="#0E9347"
          cx="256"
          cy="174.545"
          rx="93.091"
          ry="104.727"
        />
        <circle fill="#0E9347" cx="116.364" cy="174.545" r="23.273" />
        <circle fill="#0E9347" cx="395.636" cy="174.545" r="23.273" />
      </g>
      <path
        fill="#89C763"
        d="M267.636,162.909h-23.273c-6.982,0-11.636-4.655-11.636-11.636c0-6.982,4.655-11.636,11.636-11.636
	h34.909c6.982,0,11.636-4.655,11.636-11.636c0-6.982-4.655-11.636-11.636-11.636h-11.636c0-6.982-4.655-11.636-11.636-11.636
	c-6.982,0-11.636,4.655-11.636,11.636c-19.782,0-34.909,15.127-34.909,34.909s15.127,34.909,34.909,34.909h23.273
	c6.982,0,11.636,4.655,11.636,11.636c0,6.982-4.655,11.636-11.636,11.636h-34.909c-6.982,0-11.636,4.655-11.636,11.636
	c0,6.982,4.655,11.636,11.636,11.636h11.636c0,6.982,4.655,11.636,11.636,11.636c6.982,0,11.636-4.655,11.636-11.636
	c19.782,0,34.909-15.127,34.909-34.909S287.418,162.909,267.636,162.909z"
      />
    </svg>
  );
};

export default PaymentIcon;
