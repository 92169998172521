import React, {FC} from "react";

import styles from "./index.module.css";
import concatStyles from "helpers/concatStyles";

interface Props {
  className?: string;
}

export const Title: FC<Props> = ({children, className}) => {
  return (
    <h2 className={concatStyles([styles.title, className])}>{children}</h2>
  );
};

export default Title;
