import React from 'react';
import {useForm} from 'react-hook-form';
import styles from './index.module.css';
import Modal from 'components/Modal';
import TextInputForm from 'components/TextInputForm';
import Button from 'components/Button';
import {useTranslation} from 'react-i18next';

interface Props {
  requestClose: () => void;
  onSubmit: (password: string) => void;
}

interface Form {
  password: string;
  passwordConfirmation: string;
}

const AdminCodeModal: React.FC<Props> = ({requestClose, onSubmit}) => {
  const {register, handleSubmit, errors, getValues} = useForm<Form>();
  const {t} = useTranslation();

  const onSubmitHandler = (values: Form) => {
    onSubmit(values.password);
  };

  const required = t('common.required');
  const confirmationRequired = t('common.confirmationRequired');
  const confirmationError = t('common.confirmationError');

  return (
    <Modal
      title={t('features.Profile.AdminCodeModal.title')}
      requestClose={requestClose}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <TextInputForm
          name="password"
          ref={register({required: required})}
          label={t('common.password')}
          type="password"
          error={!!errors.password}
        />
        {errors.password && (
          <span className={styles.error}>{errors.password.message}</span>
        )}
        <TextInputForm
          name="passwordConfirmation"
          ref={register({
            required: confirmationRequired,
            validate: (value) =>
              value === getValues('password') || confirmationError,
          })}
          label={t('common.passwordConfirmation')}
          type="password"
          error={!!errors.passwordConfirmation}
        />
        {errors.passwordConfirmation && (
          <span className={styles.error}>
            {errors.passwordConfirmation.message}
          </span>
        )}
        <Button className={styles.button} text={t('common.save')} />
      </form>
    </Modal>
  );
};

export default AdminCodeModal;
