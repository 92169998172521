import React from 'react';

import styles from './index.module.css';
import Skeleton from 'components/Skeleton';
import Title from 'components/Title';
import MenuRow from '../MenuRow';
import ButtonForm from 'components/ButtonForm';
import {MenuCategory, MenuItem} from 'slices/types';
import {useTranslation} from 'react-i18next';

interface Props {
  loading?: boolean;
  categories: {
    byId: {[id: string]: MenuCategory};
    allIds: string[];
  };
  items: {[id: string]: MenuItem};
  onRowClick: (itemId: string) => void;
  onAddClick?: (categoryId: string) => void;
  selected?: string;
  smallHeight?: boolean;
}

const MenuList: React.FC<Props> = ({
  loading,
  categories,
  items,
  onAddClick,
  onRowClick,
  selected,
  smallHeight,
}) => {
  const {t} = useTranslation();
  return (
    <div className={smallHeight ? styles.menuContainerSmall : undefined}>
      {loading ? (
        <div className={styles.skeletonContainer}>
          {[...Array(6)].map((_, i) => (
            <div key={'skeleton-' + i} className={styles.skeletonRow}>
              <Skeleton className={styles.loading} height={96} />
            </div>
          ))}
        </div>
      ) : (
        <>
          {categories.allIds.map((categoryId) => (
            <div key={categoryId}>
              <Title>{categories.byId[categoryId].name}</Title>
              {categories.byId[categoryId].menuItems.map((itemId) => {
                const {name, description, price, coverPhoto} = items[itemId];
                return (
                  <MenuRow
                    key={itemId}
                    title={name}
                    description={description}
                    price={price}
                    image={coverPhoto}
                    onClick={() => onRowClick(itemId)}
                    selected={selected === itemId}
                  />
                );
              })}
              {onAddClick && (
                <ButtonForm
                  text={t('common.addNewItem')}
                  onClick={() => onAddClick(categoryId)}
                />
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default MenuList;
