import i18next from 'i18next';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {logout} from '../../state/auth';
import Overlay from '../Overlay';
import Popup from '../Popup';

import styles from './index.module.css';
import Navbar from 'components/Navbar';

interface Props {}

const Layout: React.FC<Props> = ({children}) => {
  const dispatch = useDispatch();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  return (
    <>
      <Navbar requestLogout={() => setShowLogoutModal(true)} />
      <div className={styles.content}>{children}</div>
      {showLogoutModal && (
        <Overlay>
          <Popup
            title={i18next.t('common.logout')}
            actions={[
              {
                label: i18next.t('common.yes'),
                callback: () => dispatch(logout()),
              },
              {
                label: i18next.t('common.no'),
                callback: () => setShowLogoutModal(false),
              },
            ]}
            message={i18next.t('common.confirmLogout')}
          />
        </Overlay>
      )}
    </>
  );
};

export default Layout;
