import * as Parse from 'parse';
import {set, isBefore, subDays} from 'date-fns';
import {parseFromTimeZone} from 'date-fns-timezone';
import {ReviewClassName, TableOrderClassName} from 'slices/types';
import capitalize from 'helpers/capitalize';
import calculatePriceSubtotals from 'helpers/calculatePriceSubtotals';
import {formatOrder} from 'helpers/orderFormatter';
import {ReportData} from './Report';

type Product = {
  id: string;
  name: string;
};

const getProducts = (restaurant: any): Product[] => {
  const menu = restaurant.get('menu') || [];
  const products: Product[] = [];

  menu.forEach((category: any) =>
    category.menu.forEach((product: any) =>
      products.push({id: product.id, name: product.name}),
    ),
  );

  return products;
};

const loadData = async (): Promise<ReportData> => {
  const reportData: any = {
    averageByOrder: 0,
    totalGain: 0,
    totalCash: 0,
    totalCard: 0,
    orders: [],
  };

  const currentUser = Parse.User.current()!;
  const restaurant = await currentUser.get('restaurant').fetch();
  const taxRate = restaurant.get('tax') || 0;
  const timeZone = restaurant.get('timeZone') || 'Europe/Paris';

  let fromDate;
  const now = parseFromTimeZone(new Date().toString(), {timeZone});
  const now5Clock = parseFromTimeZone(
    set(Date.now(), {
      hours: 5,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }).toString(),
    {timeZone},
  );

  if (isBefore(now, now5Clock)) {
    fromDate = parseFromTimeZone(
      set(subDays(Date.now(), 1), {
        hours: 5,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }).toString(),
      {timeZone},
    );
  } else {
    fromDate = now5Clock;
  }

  const query = new Parse.Query(TableOrderClassName);
  query
    .equalTo('restaurant', restaurant)
    .greaterThan('createdAt', fromDate)
    .equalTo('state', 'closed')
    .include('orders')
    .include('orders.discount')
    .include('orders.discountPointer')
    .include('orders.refunds');

  const tableOrders = await query.find();
  const orders: any[] = tableOrders.reduce(
    (prev, current) => prev.concat(current.get('orders')),
    [],
  );

  const products = getProducts(restaurant).reduce((acc: any, product) => {
    acc[product.id] = {name: product.name, amount: 0};
    return acc;
  }, {});

  reportData.restaurantName = restaurant.get('name');
  reportData.restaurantId = restaurant.id.substring(0, 8).toUpperCase();

  await Promise.all(
    orders.map(async (order, i) => {
      const {total} = calculatePriceSubtotals(formatOrder(order), taxRate);

      reportData.totalGain += total;
      if (order.get('paymentMode') === 'card') {
        reportData.totalCard += total;
      } else if (order.get('paymentMode') === 'cash') {
        reportData.totalCash += total;
      }

      const formattedOrder = {
        id: order.id.substring(0, 6).toUpperCase(),
        index: i,
        date: order.get('createdAt').getTime(),
        transactionId: order
          .get('transactionId')
          ?.substring(0, 6)
          .toUpperCase(),
        description: '',
        comment: undefined,
        paymentMode: capitalize(order.get('paymentMode')),
        refunds: '',
        total,
      };

      order.get('order').forEach((item: any) => {
        products[item.item.id].amount += item.number;
        formattedOrder.description += `${item.item.name} x${item.number}\n`;
      });
      order.get('refunds').forEach((item: any) => {
        formattedOrder.refunds += `${item.get('amount')}$ ${item.get(
          'reason',
        )}\n`;
      });

      if (order.get('reviewed')) {
        const reviewQuery = new Parse.Query(ReviewClassName);
        reviewQuery.equalTo('order', order);
        const review = await reviewQuery.find();

        if (review.length) {
          formattedOrder.comment = review[0].get('comment');
        }
      }
      reportData.orders.push(formattedOrder);
    }),
  );
  reportData.averageByOrder =
    Math.round(reportData.totalGain / orders.length) || 0;
  reportData.productSales = Object.values(products);

  console.log('reportData', reportData);
  return reportData;
};

export default loadData;
