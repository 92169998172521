import React from "react";

import styles from "./index.module.css";

type PopupAction = {
  label: string;
  callback: Function;
};

interface Props {
  title: string;
  message: string;
  actions: PopupAction[];
}

const Popup: React.FC<Props> = ({title, message, actions}) => {
  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.message}>{message}</p>
      </div>
      <div className={styles.actionContainer}>
        {actions.map(({label, callback}) => (
          <div className={styles.button} onClick={() => callback()} key={label}>
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Popup;
