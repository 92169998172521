import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.css';
import ItemStateSelector from './ItemStateSelector';
import OrderCard from './OrderCard';
import useLiveTableOrder from './useLiveTableOrder';
import AddItemModal from './AddItemModal';
import {RootState} from 'state';
import useCountdown from 'hooks/useCountdown';
import {
  fetchOrders,
  deleteTableOrder,
  updateOrderItemsAndTableOrderState,
  updateOrder,
  orderItems,
  addRefund,
  reset,
} from 'slices/tableOrders';
import {MenuItem, Order, OrderItem, TableOrderItem} from 'slices/types';
import {fetchMenu, reset as resetMenuAction} from 'slices/menu';
import Layout from 'components/Layout';
import Spinner from 'components/Spinner';
import Overlay from 'components/Overlay';
import Popup from 'components/Popup';
import RefundModal from './RefundModal';
import concatStyles from '../../helpers/concatStyles';
import * as Parse from 'parse';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

interface Props {}

interface ConfirmPopup {
  type: 'paid status' | 'payment';
  value: any;
  orderId: string;
  onConfirm: (value: any) => void;
}

const TableOrder: React.FC<Props> = () => {
  const {tableOrderId, orderNumber} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const orderNumberAsInteger = orderNumber ? parseInt(orderNumber) : 0;

  const [confirmPopup, setConfirmPopup] = useState<ConfirmPopup | null>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addItemModal, setAddItemModal] = useState<string | null>(null);
  const [refundModal, setRefundModal] = useState<string | null>(null);

  const {addToast} = useToasts();

  const tableOrder = useSelector((state: RootState) =>
    state.tableOrders.tableOrders.find(
      (tableOrder) => tableOrder.id === tableOrderId,
    ),
  );
  const {fetchOrdersLoading, updateOrderLoading, taxRate, error} = useSelector(
    (state: RootState) => state.tableOrders,
  );
  const {
    fetchLoading: fetchMenuLoading,
    menuCategories,
    menuItems,
  } = useSelector((state: RootState) => state.menu);

  const itemsFromCurrentOrder = tableOrder?.orders
    .map((order: Order) =>
      order.orderItems.find(
        (item: OrderItem) => item.orderNumber === orderNumberAsInteger,
      ),
    )
    .flat();

  const orderTime =
    orderNumberAsInteger === 0
      ? tableOrder
        ? tableOrder.createdAt
        : '08/22/2000'
      : itemsFromCurrentOrder
      ? itemsFromCurrentOrder[0]
        ? itemsFromCurrentOrder[0].orderedAt
        : '08/22/2000'
      : '08/22/2000';

  const countdown = useCountdown(
    orderTime,
    tableOrder ? tableOrder.state === 'completed' : true,
  );

  useLiveTableOrder(tableOrderId);

  useEffect(() => {
    dispatch(fetchOrders(tableOrderId));
    dispatch(fetchMenu());

    return () => {
      dispatch(reset());
      dispatch(resetMenuAction());
    };
  }, [dispatch, tableOrderId]);

  useEffect(() => {
    if (error) {
      addToast(i18next.t('toasts.error'), {appearance: 'error'});
    }
  }, [error]);

  const handleChangePaidCash = (data: ConfirmPopup) => {
    dispatch(
      updateOrder(data.orderId, {paid: data.value, paymentMode: 'cash'}),
    );
    setConfirmPopup(null);
  };

  const handleAddItem = (
    menuItem: MenuItem,
    quantity: number,
    tableOrder: TableOrderItem,
  ) => {
    dispatch(orderItems(addItemModal!, menuItem, quantity, tableOrder));
    setAddItemModal(null);
  };

  const handleAddRefund = (
    amount: number,
    taxRate: number,
    tip: number,
    reason: string,
    order: Order,
  ) => {
    dispatch(addRefund(amount, taxRate, tip, reason, order));
    setRefundModal(null);
  };

  const handleDelete = () => {
    history.push('/orders');
    dispatch(deleteTableOrder(tableOrderId));
  };

  if (!tableOrder) {
    return (
      <Layout>
        <div className={styles.container}>
          <h1>{t('features.TableOrder.orderNotFound')}</h1>
        </div>
      </Layout>
    );
  }

  const currentCustomers =
    tableOrder.usersId.length > 0
      ? tableOrder.usersId.length
      : tableOrder.minGuests;

  const currentCustomersPaid = tableOrder.orders.filter((order) => order.paid)
    .length;

  // We're deriving the state of the suborder from one of the items that have the suborder's itemNumber (there's probably a much more elegant way to do this)
  const orderForState = tableOrder.orders.find((order) => {
    return order.orderItems.find(
      (item) => item.orderNumber === orderNumberAsInteger,
    );
  });
  const itemForState = orderForState
    ? orderForState.orderItems.find(
        (item) => item.orderNumber === orderNumberAsInteger,
      )
    : null;
  const state =
    tableOrder.state === 'closed'
      ? 'closed'
      : itemForState
      ? itemForState.state
      : 'progress';

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.backButton} onClick={history.goBack}>
            <FontAwesomeIcon icon={faAngleLeft} size="2x" />
          </div>
          <h1 className={styles.title}>{t('common.back')}</h1>
          <div className={styles.headerContainerRight}>
            {orderNumberAsInteger === 0 &&
              tableOrder.orders.filter((order) => order.paid).length === 0 && (
                <span
                  className={styles.deleteButton}
                  onClick={() => setConfirmDelete(true)}>
                  {t('common.delete')}
                </span>
              )}
            {orderNumberAsInteger !== 0 && (
              <span
                className={styles.deleteButton}
                onClick={() => history.push(`/orders/${tableOrderId}/0`)}>
                {t('features.TableOrder.allOrders')}
              </span>
            )}
            <ItemStateSelector
              value={state}
              onChange={(value) =>
                dispatch(
                  updateOrderItemsAndTableOrderState(
                    tableOrderId,
                    value,
                    orderNumberAsInteger,
                  ),
                )
              }
              hideClose={currentCustomersPaid < currentCustomers}
              hideMain={orderNumberAsInteger === 0}
            />
          </div>
        </div>
        <div>
          <div className={styles.subHeaderContainer}>
            <div className={styles.flexColumn}>
              <div>
                <span className={styles.tableTitle}>
                  {t('features.TableOrder.table', {
                    name: tableOrder.table.name,
                  })}
                  &nbsp;
                </span>
                <span className={styles.suborderTitle}>
                  &rarr;&nbsp;
                  {orderNumberAsInteger === 0
                    ? t('features.TableOrder.allOrders')
                    : t('features.TableOrder.order', {orderNumberAsInteger})}
                </span>
                <span className={styles.counter}>{countdown}</span>
              </div>
              <div className={styles.flexRowCenter}>
                <div
                  className={styles.picture}
                  style={{
                    backgroundImage: `url(${tableOrder.waiter.profilePicture})`,
                  }}
                />
                <span className={styles.user}>{tableOrder.waiter.name}</span>
              </div>
            </div>
            <div className={styles.customersContainer}>
              <span>
                {t('features.TableOrder.customers', {currentCustomers})}
              </span>
              {!fetchOrdersLoading && (
                <span
                  className={concatStyles([
                    styles.paid,
                    currentCustomersPaid === currentCustomers
                      ? styles.allPaid
                      : undefined,
                  ])}>
                  {t('features.TableOrder.paid', {
                    currentCustomersPaid,
                    currentCustomers,
                  })}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={styles.ordersContainer}>
          {!fetchOrdersLoading && !fetchMenuLoading ? (
            tableOrder.orders.map((order, index) => (
              <OrderCard
                key={order.id}
                data={order}
                taxRate={taxRate}
                index={index + 1}
                onChangePaid={() => {
                  setConfirmPopup({
                    type: t('features.TableOrder.paidStatus'),
                    orderId: order.id,
                    value: true,
                    onConfirm: handleChangePaidCash,
                  });
                }}
                tableOrderId={tableOrderId}
                onClickAddItem={() => setAddItemModal(order.id)}
                onClickAddRefund={() => setRefundModal(order.id)}
                orderNumber={orderNumberAsInteger}
                restaurantId={Parse.User.current()!.get('restaurant').id}
              />
            ))
          ) : (
            <div className={styles.spinnerContainer}>
              <Spinner fill={'#000000'} />
            </div>
          )}
        </div>
      </div>
      {(updateOrderLoading ||
        confirmPopup ||
        confirmDelete ||
        addItemModal ||
        refundModal) && (
        <Overlay>
          {confirmPopup && (
            <Popup
              title={t('features.TableOrder.update', {type: confirmPopup.type})}
              message={t('common.sure')}
              actions={[
                {
                  label: t('common.cancel'),
                  callback: () => setConfirmPopup(null),
                },
                {
                  label: t('common.confirm'),
                  callback: () => confirmPopup.onConfirm(confirmPopup),
                },
              ]}
            />
          )}
          {confirmDelete && (
            <Popup
              title={t('features.TableOrder.deleteTitle')}
              message={t('features.TableOrder.deleteText')}
              actions={[
                {
                  label: t('common.cancel'),
                  callback: () => setConfirmDelete(false),
                },
                {
                  label: t('common.confirm'),
                  callback: handleDelete,
                },
              ]}
            />
          )}
          {addItemModal && (
            <AddItemModal
              requestClose={() => setAddItemModal(null)}
              menuItems={menuItems}
              menuCategories={menuCategories}
              onSubmit={handleAddItem}
              tableOrder={tableOrder}
            />
          )}
          {refundModal && (
            <RefundModal
              requestClose={() => setRefundModal(null)}
              onSubmit={handleAddRefund}
              order={
                tableOrder.orders.find(
                  (order: Order) => order.id === refundModal,
                )!
              }
              taxRate={taxRate}
            />
          )}
          {updateOrderLoading && <Spinner fill="#FFF" size={96} />}
        </Overlay>
      )}
    </Layout>
  );
};

export default TableOrder;
