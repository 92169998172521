import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';
import {AppThunk} from 'state';

interface State {
  hasPassword: boolean;
  unlocked: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  hasPassword: true,
  unlocked: false,
  loading: false,
  error: null,
};

const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    lock(state) {
      state.unlocked = false;
    },
    unlockStart(state) {
      state.loading = true;
      state.unlocked = false;
      state.error = null;
    },
    unlockSuccess(state) {
      state.loading = false;
      state.unlocked = true;
    },
    unlockFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setHasPassword(state, action: PayloadAction<boolean>) {
      state.hasPassword = action.payload;
    },
  },
});

export const {
  lock,
  unlockStart,
  unlockSuccess,
  unlockFailed,
  setHasPassword,
} = passwordSlice.actions;

export default passwordSlice.reducer;

/**
 * Thunks
 */

export const unlock = (password: string): AppThunk => async (dispatch) => {
  try {
    dispatch(unlockStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    if (restaurant.get('password') === password) {
      dispatch(unlockSuccess());
    } else {
      dispatch(unlockFailed('Bad password'));
    }
  } catch (error) {
    dispatch(unlockFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('unlock error', error);
  }
};

export const checkHasPassword = (): AppThunk => async (dispatch) => {
  try {
    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    dispatch(setHasPassword(!!restaurant.get('password')));
  } catch (e) {
    // Remove stale session token
    window.localStorage.clear();
    dispatch(setHasPassword(false));
    Sentry.captureException(e);
    // eslint-disable-next-line no-console
    console.error('check password error', e);
  }
};
