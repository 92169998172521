import * as Parse from 'parse';
import {useEffect} from 'react';
import {formatVersion} from './versionFormatter';
import {fetchVersionSuccess} from '../slices/version';
import {useDispatch} from 'react-redux';

const useLiveVersion = () => {
  const dispatch = useDispatch();

  let subscriptionVersion: any;
  const subscribe = async () => {
    const query = new Parse.Query('Version');
    query.equalTo('platform', 'admin');
    subscriptionVersion = await query.subscribe();
    subscriptionVersion.on('update', (data: any) => {
      const normalizedData = formatVersion(data);
      dispatch(fetchVersionSuccess(normalizedData));
    });
  };
  useEffect(() => {
    subscribe();

    return () => {
      subscriptionVersion?.unsubscribe();
    };
  }, []);
};

export default useLiveVersion;
