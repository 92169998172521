import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {ArrayField, FieldError} from 'react-hook-form';

import styles from './index.module.css';
import Title from 'components/Title';
import ButtonForm from 'components/ButtonForm';
import DeleteButtonForm from 'components/DeleteButtonForm';
import DragTextInputForm from 'components/DragTextInputForm';
import {DeepMap} from 'react-hook-form/dist/types/utils';
import CategoryLoading from '../CategoryLoading';
import concatStyles from '../../../helpers/concatStyles';
import {useTranslation} from 'react-i18next';

interface FormSectionProps {
  id: string;
  name: string;
  isLoading?: boolean;
  fields: Partial<ArrayField<Record<string, any>, 'id'>>[];
  register: any;
  errors: DeepMap<Record<string, any>, FieldError>;
  onDrag: (id: string) => ({source, destination}: DropResult) => void;
  onRemove: (id: string) => (index: number) => void;
  onAppend: (id: string) => void;
}

const FormSection: React.FC<FormSectionProps> = ({
  id,
  name,
  fields,
  register,
  errors,
  isLoading,
  onDrag,
  onRemove,
  onAppend,
}) => {
  const {t} = useTranslation();
  return (
    <>
      <Title>{name}</Title>
      {!isLoading ? (
        <>
          <DragDropContext onDragEnd={onDrag(id)}>
            <Droppable droppableId={`${id}-items`}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((item, i) => {
                    // TODO: this is a rather inelegant way of managing a read only value, should be refactored at
                    //  some point
                    const isServiceItem = item.name === 'Service';
                    return (
                      <Draggable
                        key={`${id}[${i}]`}
                        draggableId={`${id}-${i}`}
                        index={i}>
                        {(provided) => (
                          <div
                            key={item.id}
                            className={concatStyles([
                              styles.listRow,
                              isServiceItem
                                ? styles.listRowNoMargin
                                : undefined,
                            ])}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <DragTextInputForm
                              index={i + 1}
                              error={errors[id] && errors[id][i]}
                              name={`${id}[${i}].name`}
                              defaultValue={`${item.name}`}
                              ref={register({required: true})}
                              placeholder="Category"
                              disabled={isServiceItem}
                            />
                            {!isServiceItem && (
                              <DeleteButtonForm
                                onClick={() => onRemove(id)(i)}
                              />
                            )}
                            <input
                              type="hidden"
                              name={`${id}[${i}].id`}
                              ref={register({required: true})}
                              value={`${item.id}`}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ButtonForm
            text={t('features.Profile.FormSection.button')}
            onClick={() => onAppend(id)}
          />
        </>
      ) : (
        <CategoryLoading rowCount={3} id="skeleton-menu-" />
      )}
    </>
  );
};

export default FormSection;
