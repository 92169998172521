import React from "react";

import styles from "./index.module.css";
import concatStyles from "helpers/concatStyles";
import {CheckmarkIconSvg} from "components/SvgIcon";

interface Props {
  label: string;
  disabled?: boolean;
  loading?: boolean;
  onChange: any;
  value: any;
}

const CheckboxForm: React.FC<Props> = ({
  label,
  loading = false,
  disabled = false,
  value,
  onChange,
}) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && !loading) onChange(!value);
  };
  return (
    <div
      className={concatStyles([
        styles.container,
        loading ? styles.skeleton : "",
      ])}>
      <label className={styles.label}>
        {label}
        {!loading && (
          <>
            <input
              onChange={onChangeHandler}
              type="checkbox"
              disabled={disabled}
              className={styles.input}
            />
            <div
              className={concatStyles([
                styles.checkmark,
                value ? styles.checked : "",
                disabled ? styles.disabled : "",
              ])}>
              {value && <CheckmarkIconSvg fill="#fff" size={32} />}
            </div>
          </>
        )}
      </label>
    </div>
  );
};
export default CheckboxForm;
