import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import {v4 as uuidv4} from 'uuid';
import {format} from 'date-fns';
import logo from 'assets/images/logo.png';
import {PaymentMode} from 'slices/types';

type Order = {
  id: string;
  index: number;
  date: number;
  transactionId?: string;
  description: string;
  comments?: string;
  paymentMode: PaymentMode;
  total: number;
  refunds: [{amount: number; reason: string}];
};

export type ReportData = {
  restaurantName: string;
  restaurantId: string;
  averageByOrder: number;
  totalGain: number;
  totalCash: number;
  totalCard: number;
  productSales: [{name: string; amount: number}];
  orders: Order[];
};

interface Props {
  data: ReportData;
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    fontSize: 12,
  },
  header: {
    flexDirection: 'row-reverse',
    height: '90pt',
    backgroundColor: '#F23B4D',
    alignItems: 'center',
  },
  logo: {
    width: '70pt',
    height: '70pt',
    marginRight: '20pt',
  },
  container: {
    margin: '30pt',
    marginBottom: 0,
  },
  h1: {
    fontSize: 22,
  },
  greyColor: {
    color: '#8e8e8e',
  },
  marginText: {
    marginVertical: '2pt',
  },
  leftRightTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  marginBottomSeparator: {
    marginBottom: '20pt',
  },
  separator: {
    height: '1pt',
    backgroundColor: '#000000',
  },
  productSalesLine: {
    backgroundColor: '#F6F6F6',
    borderRadius: '20pt',
    height: '27pt',
    alignItems: 'center',
    marginHorizontal: '15pt',
    paddingHorizontal: '15pt',
  },
  tableHeaderCell: {
    border: 0,
    backgroundColor: '#F6F6F6',
    height: '27pt',
  },
  tableCell: {
    border: 0,
    marginVertical: '4pt',
  },
});

const Report: React.FC<Props> = ({data}) => {
  const leftRightText = (
    text1: string | number,
    text2: string | number,
    style?: any,
  ) => (
    <View style={[style, styles.leftRightTextContainer]} key={uuidv4()}>
      <Text>{text1}</Text>
      <Text>{text2}</Text>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.root}>
        <View style={styles.header}>
          <Image src={logo} style={styles.logo} cache={false} />
        </View>
        <View style={styles.container}>
          <Text style={styles.h1}>{data.restaurantName}</Text>
          <Text style={[styles.greyColor, styles.marginText]}>
            #{data.restaurantId}
          </Text>
          <Text style={[styles.marginText, styles.marginBottomSeparator]}>
            {format(Date.now(), 'dd/MM/uuuu')}
          </Text>
          {leftRightText(
            'Numbers of orders',
            data.orders.length,
            styles.marginText,
          )}
          {leftRightText(
            'Average by order',
            data.averageByOrder.toFixed(2) + '$',
            styles.marginText,
          )}
          {leftRightText(
            'Revenues',
            data.totalGain.toFixed(2) + '$',
            styles.marginText,
          )}
          {leftRightText('Cash', data.totalCash.toFixed(2) + '$', [
            styles.marginText,
            styles.greyColor,
          ])}
          {leftRightText('Credit', data.totalCard.toFixed(2) + '$', [
            styles.marginText,
            styles.greyColor,
            styles.marginBottomSeparator,
          ])}
          <View style={[styles.separator, styles.marginBottomSeparator]} />
          <Text style={[styles.h1, styles.marginText]}>Product sales</Text>
        </View>
        {data.productSales.map((product, i) =>
          leftRightText(product.name, product.amount, [
            styles.productSalesLine,
            i % 2 === 1 ? {backgroundColor: '#FFFFFF'} : null,
          ]),
        )}
        <Text
          style={[
            styles.h1,
            {marginHorizontal: '30pt', marginTop: '30pt', marginBottom: '2pt'},
          ]}>
          Orders
        </Text>
        <Table data={data.orders}>
          <TableHeader
            textAlign="center"
            fontSize={10}
            includeBottomBorder={false}
            includeLeftBorder={false}
            includeRightBorder={false}
            includeTopBorder={false}>
            <TableCell
              style={[
                styles.tableHeaderCell,
                {
                  borderBottomLeftRadius: '27pt',
                  borderTopLeftRadius: '27pt',
                  marginLeft: '15pt',
                  paddingLeft: '15pt',
                },
              ]}>
              Date
            </TableCell>
            <TableCell style={styles.tableHeaderCell}>Hour</TableCell>
            <TableCell style={styles.tableHeaderCell}>
              Transaction number
            </TableCell>
            <TableCell style={styles.tableHeaderCell}>Order number</TableCell>
            <TableCell style={styles.tableHeaderCell}>Description</TableCell>
            <TableCell style={styles.tableHeaderCell}>Comments</TableCell>
            <TableCell style={styles.tableHeaderCell}>Payment</TableCell>
            <TableCell style={styles.tableHeaderCell}>Refunds</TableCell>
            <TableCell
              style={[
                styles.tableHeaderCell,
                {
                  textAlign: 'right',
                  borderBottomRightRadius: '27pt',
                  borderTopRightRadius: '27pt',
                  marginRight: '15pt',
                  paddingRight: '15pt',
                },
              ]}>
              Total
            </TableCell>
          </TableHeader>
          <TableBody
            fontSize={9}
            textAlign="center"
            includeTopBorder={false}
            includeRightBorder={false}
            includeLeftBorder={false}
            includeBottomBorder={false}>
            <DataTableCell
              style={[styles.tableCell, {marginLeft: '30pt'}]}
              getContent={(r: Order) => (
                <Text style={r.index % 2 === 1 ? styles.greyColor : undefined}>
                  {format(r.date, 'dd/MM/uuuu')}
                </Text>
              )}
            />
            <DataTableCell
              style={styles.tableCell}
              getContent={(r: Order) => (
                <Text style={r.index % 2 === 1 ? styles.greyColor : undefined}>
                  {format(r.date, 'h:mm')}
                </Text>
              )}
            />
            <DataTableCell
              style={styles.tableCell}
              getContent={(r: Order) => (
                <Text style={r.index % 2 === 1 ? styles.greyColor : undefined}>
                  {!!r.transactionId ? '#' + r.transactionId : '-'}
                </Text>
              )}
            />
            <DataTableCell
              style={styles.tableCell}
              getContent={(r: Order) => (
                <Text style={r.index % 2 === 1 ? styles.greyColor : undefined}>
                  #{r.id}
                </Text>
              )}
            />
            <DataTableCell
              style={styles.tableCell}
              getContent={(r: Order) => (
                <Text style={r.index % 2 === 1 ? styles.greyColor : undefined}>
                  {r.description}
                </Text>
              )}
            />
            <DataTableCell
              style={styles.tableCell}
              getContent={(r: Order) => (
                <Text style={r.index % 2 === 1 ? styles.greyColor : undefined}>
                  {r.comments || '-'}
                </Text>
              )}
            />
            <DataTableCell
              style={styles.tableCell}
              getContent={(r: Order) => (
                <Text style={r.index % 2 === 1 ? styles.greyColor : undefined}>
                  {r.paymentMode}
                </Text>
              )}
            />
            <DataTableCell
              style={styles.tableCell}
              getContent={(r: Order) => (
                <Text style={r.index % 2 === 1 ? styles.greyColor : undefined}>
                  {r.refunds}
                </Text>
              )}
            />
            <DataTableCell
              style={[
                styles.tableCell,
                {textAlign: 'right', marginRight: '30pt'},
              ]}
              getContent={(r: Order) => (
                <Text style={r.index % 2 === 1 ? styles.greyColor : undefined}>
                  {r.total.toFixed(2) + '$'}
                </Text>
              )}
            />
          </TableBody>
        </Table>
      </Page>
    </Document>
  );
};

export default Report;
