import React from 'react';

import styles from './index.module.css';
import {useTranslation} from 'react-i18next';

interface Props {}

const Refresh: React.FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.circle}>
        <h1>{t('features.Refresh.title')}</h1>
        <h2>{t('features.Refresh.text')}</h2>
      </div>
    </div>
  );
};

export default Refresh;
