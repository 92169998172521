import React, {useEffect, useState, useMemo} from 'react';
import {DropResult} from 'react-beautiful-dnd';
import {useForm, useFieldArray, Controller} from 'react-hook-form';
import {useToasts} from 'react-toast-notifications';
import {v4 as uuidv4} from 'uuid';
import {useDispatch, useSelector} from 'react-redux';
import {listTimeZones} from 'timezone-support';

import styles from './index.module.css';
import AdminCodeModal from './AdminCodeModal';
import Layout from 'components/Layout';
import Title from 'components/Title';
import ImageUpload from 'components/ImageUpload';
import TextInputForm from 'components/TextInputForm';
import CheckboxForm from 'components/CheckboxForm';
import ButtonForm from 'components/ButtonForm';
import Overlay from 'components/Overlay';
import concatStyles from 'helpers/concatStyles';
import ActionBarForm from 'components/ActionBarForm';
import SelectInputForm from '../../components/SelectInputForm';
import GenerateReportButton from 'components/GenerateReportButton';
import FormSection from './FormSection';
import {RootState} from 'state';
import usePassword from 'hooks/usePassword';
import {
  fetchProfile,
  updateProfile,
  updatePassword,
  reset as resetAction,
} from './profileSlice';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {addToast} = useToasts();

  const [actionVisible, setActionVisible] = useState(false);
  const [image, setImage] = useState<{url: string | null; raw: any}>({
    url: null,
    raw: null,
  });
  const [adminCodeModal, setAdminCodeModal] = useState(false);

  const {control, errors, formState, handleSubmit, register, reset} = useForm();
  const {isDirty} = formState;

  const timeZones = useMemo(
    () =>
      listTimeZones().map((timeZone) => ({value: timeZone, label: timeZone})),
    [],
  );

  const {
    fetchLoading,
    updateLoading,
    successNotification,
    name,
    currency,
    currencySymbol,
    coverPhoto,
    country,
    available,
    menu,
    error,
    reviews,
    timeZone,
  } = useSelector((state: RootState) => state.profile);

  usePassword();

  const {
    fields: fieldsMenu,
    append: appendMenu,
    move: moveMenu,
    remove: removeMenu,
  } = useFieldArray({
    control,
    name: 'menu',
  });

  const {
    fields: fieldsReviews,
    append: appendReviews,
    move: moveReviews,
    remove: removeReviews,
  } = useFieldArray({
    control,
    name: 'reviews',
  });

  // Fetch data when component is loaded
  useEffect(() => {
    dispatch(fetchProfile());
    return () => {
      // reset form when unmount
      dispatch(resetAction());
    };
  }, [dispatch]);

  // Update form content when data is fetched
  useEffect(() => {
    reset({
      available,
      timeZone,
      menu: menu?.map((item) => ({name: item.name, id: item.id || uuidv4()})),
      reviews: reviews?.map((item) => ({name: item})),
    });
  }, [menu, reviews, available, reset]);

  // Show action bar when form is dirty
  useEffect(() => {
    if (isDirty) {
      setActionVisible(true);
    }
  }, [isDirty, addToast]);

  // show success and hide action bar
  useEffect(() => {
    if (successNotification) {
      setActionVisible(false);
      addToast(i18next.t('toasts.saved'));
    }
  }, [successNotification, addToast]);

  // display error
  useEffect(() => {
    if (error) {
      addToast(error);
    }
  }, [error, addToast]);

  // sync image
  useEffect(() => {
    setImage({url: coverPhoto, raw: null});
  }, [coverPhoto]);

  const onResetHandler = () => {
    setActionVisible(false);
    setImage({url: coverPhoto, raw: null});

    reset({
      available,
      menu: menu?.map((item: any) => ({name: item.name, id: item.id})),
      reviews: reviews?.map((item: any) => ({name: item})),
    });
  };

  const dragHandler = (id: string) => ({source, destination}: DropResult) => {
    if (destination) {
      id === 'menu'
        ? moveMenu(source.index, destination.index)
        : moveReviews(source.index, destination.index);
    }
  };

  const onAppendHandler = (id: string) => {
    id === 'menu'
      ? appendMenu({name: '', id: uuidv4()})
      : appendReviews({name: ''});
  };

  const onRemoveHandler = (id: string) => (index: number) => {
    id === 'menu' ? removeMenu(index) : removeReviews(index);
  };

  const onRemoveCoverHandler = () => {
    setImage({url: null, raw: null});
    setActionVisible(true);
  };

  const onUploadCoverHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    setImage({url: URL.createObjectURL(file), raw: file});
    setActionVisible(true);
  };

  const onSubmitHandler = ({available, menu, timeZone, reviews}: any) => {
    const formattedReviews = reviews
      ? reviews.map((review: any) => review.name)
      : [];

    // delete or update the image
    const imageUpload = image.url ? image.raw : 'delete';

    dispatch(
      updateProfile(available, menu, formattedReviews, imageUpload, timeZone),
    );
  };

  const changePasswordHandler = (password: string) => {
    dispatch(updatePassword(password));
    setAdminCodeModal(false);
  };

  return (
    <Layout>
      <form
        className={concatStyles([
          styles.form,
          !actionVisible ? styles.clean : '',
        ])}
        onSubmit={handleSubmit(onSubmitHandler)}>
        <section className={concatStyles([styles.column, styles.leftColumn])}>
          <Title>{name || 'Profile'}</Title>
          <ImageUpload
            loading={fetchLoading}
            url={image.url}
            title={t('features.Profile.profilePicture')}
            onRemove={onRemoveCoverHandler}
            onUpload={onUploadCoverHandler}
          />
          <TextInputForm
            loading={fetchLoading}
            name="country"
            label={t('features.Profile.country')}
            ref={register({required: true})}
            defaultValue={!fetchLoading ? `${country}` : ''}
            disabled
          />
          <TextInputForm
            loading={fetchLoading}
            name="currency"
            label={t('features.Profile.currency')}
            ref={register({required: true})}
            defaultValue={
              !fetchLoading
                ? `${currency?.toUpperCase()} ${currencySymbol}`
                : ''
            }
            disabled
          />
          <Controller
            name="timeZone"
            control={control}
            defaultValue={timeZone}
            render={({onChange, value}) => (
              <SelectInputForm
                label={t('features.Profile.timeZone')}
                options={timeZones}
                value={value}
                onChange={onChange}
                selectClassName={styles.selectFontSize}
              />
            )}
          />
          <Controller
            name="available"
            control={control}
            defaultValue={available}
            render={({onChange, value}) => (
              <CheckboxForm
                loading={fetchLoading}
                label={t('common.available')}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <ButtonForm
            text={t('features.Profile.setAdminPassword')}
            onClick={() => setAdminCodeModal(true)}
          />
          <GenerateReportButton />
        </section>
        <section className={concatStyles([styles.column, styles.rightColumn])}>
          <div className={styles.innerRightColumn}>
            <FormSection
              isLoading={fetchLoading}
              register={register}
              id="menu"
              name={t('common.menu')}
              fields={fieldsMenu}
              errors={errors}
              onDrag={dragHandler}
              onRemove={onRemoveHandler}
              onAppend={onAppendHandler}
            />
            <FormSection
              isLoading={fetchLoading}
              register={register}
              id="reviews"
              name={t('common.reviews')}
              fields={fieldsReviews}
              errors={errors}
              onDrag={dragHandler}
              onRemove={onRemoveHandler}
              onAppend={onAppendHandler}
            />
          </div>
        </section>
        <ActionBarForm
          loading={updateLoading}
          onReset={onResetHandler}
          className={concatStyles([
            styles.actionBar,
            !actionVisible ? styles.hiddenActionBar : '',
          ])}
        />
      </form>
      {adminCodeModal && (
        <Overlay>
          <AdminCodeModal
            requestClose={() => setAdminCodeModal(false)}
            onSubmit={changePasswordHandler}
          />
        </Overlay>
      )}
    </Layout>
  );
};

export default Profile;
