import React from 'react';
import {format} from 'date-fns';

import styles from './index.module.css';
import {useTranslation} from 'react-i18next';

interface Props {
  id: string;
  createdAt: string;
  profilePicture: string | null;
  username: string;
  rating: number;
  scores: {name: string; value: number}[];
}

const ReviewCard: React.FC<Props> = ({
  id,
  createdAt,
  profilePicture,
  username,
  rating,
  scores,
}) => {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.profile}>
          {profilePicture && (
            <div
              className={styles.profilePicture}
              style={{
                backgroundImage: `url(${profilePicture})`,
              }}
            />
          )}
          <div className={styles.profileMeta}>
            <h3 className={styles.profileName}>{username}</h3>
            <span className={styles.headerDate}>
              {format(new Date(createdAt), 'd MMM hh:mm')}
            </span>
            <span className={styles.headerOrderId}>
              {t('orderNumber', {id: id.substring(0, 4)})}
            </span>
          </div>
        </div>
        <span className={styles.score}>{rating.toFixed(1)}</span>
      </div>
      <div>
        {scores.map(({name, value}, i) => (
          <div key={`${id}-${i}`} className={styles.ratingRow}>
            <span className={styles.ratingTitle}>{name}</span>
            <span className={styles.ratingValue}>{value.toFixed(0)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewCard;
