import {useEffect, useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import * as Parse from 'parse';
import useSound from 'use-sound';
import {useDispatch, useSelector} from 'react-redux';
import waiterCallSound from 'assets/audio/bell.mp3';
import {
  fetchQueue,
  resetQueue,
  resolveCall,
  resolveCallSuccess,
  appendToQueue,
} from 'slices/waiterCall';
import {RootState} from 'state';
import i18next from 'i18next';

let isSubscriptionClosed = false;

const useLiveWaiterCall = () => {
  const dispatch = useDispatch();
  const subscriptions: any = {};
  const {addToast, removeAllToasts, removeToast} = useToasts();
  const [playSound] = useSound(waiterCallSound, {volume: 0.3});
  const [trigger, setTrigger] = useState<{
    tableNumber: string;
    id: string;
    type: 'waiterCall' | 'waiterPayment';
  } | null>(null);
  const [removeTrigger, setRemoveTrigger] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const {queue, loaded}: any = useSelector(
    (state: RootState) => state.waiterCall,
  );

  const subscribe = async () => {
    const currentUser = Parse.User.current()!;
    const restaurant = currentUser.get('restaurant');

    const query = new Parse.Query('WaiterCall');
    query.equalTo('restaurant', restaurant).equalTo('seen', false);

    subscriptions.subscriptionWaiterCalls = await query.subscribe();
    console.info('WaiterCalls socket > subscribed');

    subscriptions.subscriptionWaiterCalls.on('create', (data: any) => {
      console.info('----------------');
      console.info('WaiterCalls socket > create');
      setTrigger({
        tableNumber: data.get('tableNumber'),
        id: data.id,
        type: data.get('type'),
      });
    });

    subscriptions.subscriptionWaiterCalls.on('enter', (data: any) => {
      console.info('----------------');
      console.info('WaiterCalls socket > enter');
      setTrigger({
        tableNumber: data.get('tableNumber'),
        id: data.id,
        type: data.get('type'),
      });
    });

    subscriptions.subscriptionWaiterCalls.on('delete', (data: any) => {
      console.info('----------------');
      console.info('WaiterCalls socket > delete');
      setRemoveTrigger(data.id);
    });

    subscriptions.subscriptionWaiterCalls.on('open', () => {
      console.info('----------------');
      console.info('WaiterCalls socket > open');
      if (isSubscriptionClosed) {
        dispatch(fetchQueue());
        isSubscriptionClosed = false;
      }
    });

    subscriptions.subscriptionWaiterCalls.on('close', () => {
      console.info('----------------');
      console.info('WaiterCalls socket > close');
      dispatch(resetQueue());
      setIsLoaded(false);
      isSubscriptionClosed = true;
    });
  };

  useEffect(() => {
    if (trigger) {
      playSound();

      const msg =
        trigger.type === 'waiterCall'
          ? i18next.t('toasts.waiterNeeded', {tableNumber: trigger.tableNumber})
          : i18next.t('toasts.paymentNeeded', {
              tableNumber: trigger.tableNumber,
            });
      dispatch(appendToQueue(trigger));
      addToast(msg, {
        autoDismiss: false,
        onDismiss: () => dispatch(resolveCall(trigger.id)),
        appearance: trigger.type === 'waiterCall' ? 'warning' : 'error',
        // @ts-ignore
        id: trigger.id,
      });
      setTrigger(null);
    }
  }, [trigger, addToast, playSound, dispatch]);

  useEffect(() => {
    if (loaded && !isLoaded) {
      setIsLoaded(true);

      queue.forEach((item: {type: string; tableNumber: string; id: string}) => {
        const msg =
          item.type === 'waiterCall'
            ? i18next.t('toasts.waiterNeeded', {tableNumber: item.tableNumber})
            : i18next.t('toasts.paymentNeeded', {
                tableNumber: item.tableNumber,
              });
        addToast(msg, {
          autoDismiss: false,
          onDismiss: () => dispatch(resolveCall(item.id)),
          appearance: item.type === 'waiterCall' ? 'warning' : 'error',
          // @ts-ignore
          id: item.id,
        });
      });
    }
  }, [queue, loaded, isLoaded, addToast, dispatch]);

  useEffect(() => {
    if (removeTrigger) {
      removeToast(removeTrigger);
      // dispatch(resolveCall(removeTrigger));
      dispatch(resolveCallSuccess(removeTrigger));
      // setRemoveTrigger(null);
    }
  }, [removeTrigger, removeToast]);

  useEffect(() => {
    dispatch(fetchQueue());
    subscribe();

    return () => {
      if (subscriptions.subscriptionWaiterCalls)
        subscriptions.subscriptionWaiterCalls.unsubscribe().then(() => {
          console.info('----------------');
          console.info('WaiterCalls socket > unsubscribe');
        });
      dispatch(resetQueue());
      isSubscriptionClosed = false;
      removeAllToasts();
    };
  }, [dispatch, removeAllToasts]);
};

export default useLiveWaiterCall;
