import React from 'react';
import AddUpdateForm, {Form} from '../AddUpdateForm';
import Modal from 'components/Modal';
import {useTranslation} from 'react-i18next';

interface Props {
  requestClose: Function;
  onSubmit: (values: Form) => void;
}

const AddModal: React.FC<Props> = ({requestClose, onSubmit}) => {
  const onSubmitHandler = (values: Form) => {
    requestClose();
    onSubmit(values);
  };
  const {t} = useTranslation();

  return (
    <Modal title={t('common.addTable')} requestClose={requestClose}>
      <AddUpdateForm
        onSubmit={onSubmitHandler}
        submitButtonText={t('common.addTable')}
      />
    </Modal>
  );
};

export default AddModal;
