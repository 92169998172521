import React from "react";

import styles from "./index.module.css";
import Spinner from "components/Spinner";
import concatStyles from "helpers/concatStyles";

interface Props {
  text: string;
  loading?: boolean;
  error?: any;
  className?: string;
  onClick?: Function;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({
  text,
  loading = false,
  error = false,
  className = "",
  onClick,
  disabled,
}) => {
  return loading ? (
    <div className={concatStyles([styles.input, styles.loading])}>
      <Spinner fill="#F23B4D" />
    </div>
  ) : (
    <input
      type="submit"
      value={text}
      className={concatStyles([
        styles.input,
        className,
        error ? styles.error : "",
      ])}
      onClick={onClick as any}
      disabled={disabled}
    />
  );
};

export default Button;
