import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';

import {AppThunk} from 'state';

type FetchContent = {
  id: string | null;
  name: string | null;
  country: string | null;
  currency: string | null;
  currencySymbol: string | null;
  available: boolean;
  coverPhoto: string | null;
  menu:
    | {
        id: string;
        name: string;
      }[]
    | null;
  reviews: string[] | null;
  password: string | null;
  timeZone: string;
};

interface State extends FetchContent {
  error: string | null;
  fetchLoading: boolean;
  updateLoading: boolean;
  successNotification: boolean;
}

const initialState: State = {
  error: null,
  fetchLoading: false,
  updateLoading: false,
  successNotification: false,
  id: null,
  name: null,
  country: null,
  currency: null,
  currencySymbol: null,
  available: false,
  coverPhoto: null,
  menu: null,
  reviews: null,
  password: null,
  timeZone: 'Europe/Paris',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    fetchProfileStart(state) {
      state.fetchLoading = true;
      state.error = null;
    },
    fetchProfileSuccess(state, action: PayloadAction<FetchContent>) {
      return {...state, ...action.payload, fetchLoading: false};
    },
    fetchProfileFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.fetchLoading = false;
    },
    updateProfileStart(state) {
      state.updateLoading = true;
      state.error = null;
      state.successNotification = false;
    },
    updateProfileSuccess(
      state,
      action: PayloadAction<{
        available: boolean;
        menu: {id: string; name: string}[];
        reviews: string[];
        coverPhoto: string | null;
        timeZone: string;
      }>,
    ) {
      const {available, menu, reviews, coverPhoto, timeZone} = action.payload;

      state.updateLoading = false;
      state.successNotification = true;
      state.available = available;
      state.menu = menu;
      state.reviews = reviews;
      state.coverPhoto = coverPhoto;
      state.timeZone = timeZone;
    },
    updateProfileFailed(state, action: PayloadAction<string>) {
      state.updateLoading = false;
      state.error = action.payload;
    },
    updatePasswordStart(state) {
      state.updateLoading = true;
      state.error = null;
    },
    updatePasswordSuccess(state, action: PayloadAction<string>) {
      state.password = action.payload;
      state.updateLoading = false;
    },
    updatePasswordFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.updateLoading = false;
    },
    reset(state) {
      state.updateLoading = false;
      state.error = null;
      state.fetchLoading = false;
      state.successNotification = false;
    },
  },
});

export const {
  fetchProfileStart,
  fetchProfileSuccess,
  fetchProfileFailed,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFailed,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordFailed,
  reset,
} = profileSlice.actions;

export default profileSlice.reducer;

/**
 * Thunks
 */

export const fetchProfile = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchProfileStart());

    const currentUser = Parse.User.current()!;
    const {
      id,
      attributes: {
        name,
        country,
        currency,
        currencySymbol,
        available,
        coverPhoto,
        menu,
        reviewsPoints,
        password,
        timeZone,
      },
    } = await currentUser.get('restaurant').fetch();

    console.info('resto', await currentUser.get('restaurant').fetch());
    dispatch(
      fetchProfileSuccess({
        id,
        name,
        country,
        currency,
        currencySymbol,
        available,
        coverPhoto: coverPhoto && coverPhoto._url,
        menu: menu.map((item: any) => ({id: item.id, name: item.name})),
        reviews: reviewsPoints,
        password,
        timeZone: timeZone || 'Europe/Paris',
      }),
    );
  } catch (error) {
    dispatch(fetchProfileFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchProfile error', error);
  }
};

export const updateProfile = (
  available: boolean,
  menu: any,
  reviews: string[],
  image: any,
  timeZone: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateProfileStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    restaurant.set('available', available);
    restaurant.set('reviewsPoints', reviews);
    restaurant.set('timeZone', timeZone);

    const formattedMenu = menu.map((item: any) => {
      const originalItem = restaurant.attributes.menu.find(
        (row: any) => row.id === item.id,
      ) || {id: item.id, name: item.name, menu: []};
      originalItem.name = item.name;
      return originalItem;
    });

    if (image) {
      if (image !== 'delete') {
        const file = new Parse.File('coverPhoto', image);
        restaurant.set('coverPhoto', file);
      } else {
        restaurant.set('coverPhoto', null);
      }
    }

    restaurant.set('menu', formattedMenu);
    const response = await restaurant.save();

    const coverPhoto = response.get('coverPhoto')
      ? response.get('coverPhoto')._url
      : null;

    dispatch(
      updateProfileSuccess({available, menu, reviews, coverPhoto, timeZone}),
    );
  } catch (error) {
    dispatch(updateProfileFailed(error.message));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('updateProfile error', error);
  }
};

export const updatePassword = (password: string): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(updatePasswordStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    restaurant.set('password', password);
    await restaurant.save();
    dispatch(updatePasswordSuccess(password));
  } catch (error) {
    dispatch(updatePasswordFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('updatePassword error', error);
  }
};
