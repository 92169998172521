import React, {useState} from 'react';
import {useWindowSize} from '@react-hook/window-size';
import Modal from '../Modal';

import styles from './index.module.css';
import logo from '../../assets/images/garcon-g.png';
import {
  LogoutIconSvg,
  OrdersIconSvg,
  MenuIconSvg,
  ProfileIconSvg,
  LogoSvg,
  StatsIconSvg,
  TeamIcon,
  TablesIcon,
} from 'components/SvgIcon';
import {NavLink} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {logout} from 'state/auth';
import i18next from 'i18next';

export const navbarItems = [
  {
    to: '/orders',
    title: i18next.t('common.orders'),
    iconSmall: <OrdersIconSvg fill="#fff" size={30} />,
    icon: <OrdersIconSvg fill="#fff" />,
  },
  {
    to: '/tables',
    title: i18next.t('common.tables'),
    iconSmall: <TablesIcon fill="#fff" size={30} />,
    icon: <TablesIcon fill="#fff" />,
  },
  {
    to: '/menu',
    title: i18next.t('common.menu'),
    iconSmall: <MenuIconSvg fill="#fff" size={30} />,
    icon: <MenuIconSvg fill="#fff" />,
  },
  {
    to: '/stats',
    title: i18next.t('common.stats'),
    iconSmall: <StatsIconSvg fill="#fff" size={30} />,
    icon: <StatsIconSvg fill="#fff" />,
  },
  {
    to: '/waiters',
    title: i18next.t('common.staff'),
    iconSmall: <TeamIcon fill="#fff" size={30} />,
    icon: <TeamIcon fill="#fff" />,
  },
  {
    to: '/profile',
    title: i18next.t('common.profile'),
    iconSmall: <ProfileIconSvg fill="#fff" size={30} />,
    icon: <ProfileIconSvg fill="#fff" />,
  },
];

interface Props {
  requestLogout: () => void;
}

const Navbar: React.FC<Props> = ({requestLogout}) => {
  const [, height] = useWindowSize();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.logo}>
          <img src={logo} className={styles.img} />
          <div className={styles.separator} />
        </div>
        <nav className={styles.nav}>
          <ul>
            {navbarItems.map(({to, title, iconSmall, icon}) => (
              <li key={title}>
                <NavLink
                  activeClassName={styles.active}
                  className={styles.link}
                  to={to}>
                  {height < 620 && iconSmall}
                  {height > 620 && icon}
                  <span className={styles.title}>{title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div onClick={requestLogout} className={styles.link}>
        <LogoutIconSvg fill="#fff" />
      </div>
      {showLogoutModal && (
        <Modal
          title={i18next.t('common.confirmLogout')}
          requestClose={() => setShowLogoutModal(false)}
        />
      )}
    </div>
  );
};

export default Navbar;
