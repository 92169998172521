import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {subDays} from 'date-fns';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';

import {AppThunk} from 'state';
import {normalizeOrders} from 'helpers/orderFormatter';
import {Order} from './types';

type Content = {
  byId: {[id: string]: Order};
  allIds: string[];
};

interface State {
  error: string | null;
  fetchLoading: boolean;
  updateLoading: boolean;
  taxRate: number;
  entities: Content;
}

const initialState: State = {
  error: null,
  fetchLoading: false,
  updateLoading: false,
  taxRate: 0,
  entities: {
    byId: {},
    allIds: [],
  },
};

const authSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    fetchOrdersStart(state, action: PayloadAction<boolean>) {
      state.fetchLoading = action.payload;
      state.error = null;
    },
    fetchOrdersSuccess(
      state,
      action: PayloadAction<{entities: Content; taxRate: number}>,
    ) {
      state.fetchLoading = false;
      state.entities = action.payload.entities;
      state.taxRate = action.payload.taxRate;
    },
    fetchOrdersFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.fetchLoading = false;
    },
    updateOrder(state, action: PayloadAction<Order>) {
      const order = action.payload;
      state.entities.byId[order.id] = order;
    },
    addOrder(state, action: PayloadAction<Order>) {
      const order = action.payload;
      state.entities.byId[order.id] = order;
      state.entities.allIds.push(order.id);
    },
    purge() {
      return initialState;
    },
  },
});

export const {
  fetchOrdersStart,
  fetchOrdersSuccess,
  fetchOrdersFailed,
  updateOrder,
  addOrder,
  purge,
} = authSlice.actions;

export default authSlice.reducer;

/**
 * Thunks
 */

export const fetchOrders = (showLoading: boolean = true): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(fetchOrdersStart(showLoading));

    const fromDate = subDays(Date.now(), 1);

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();
    const taxRate = restaurant.get('tax') || 0;

    const completedQuery = new Parse.Query('Order');
    completedQuery
      .equalTo('restaurant', restaurant)
      .greaterThan('createdAt', fromDate)
      .equalTo('state', 'completed')
      .include('user')
      .include('discount')
      .limit(1000);

    const pendingProgressQuery = new Parse.Query('Order');
    pendingProgressQuery
      .equalTo('restaurant', restaurant)
      .containedIn('state', ['pending', 'progress'])
      .include('user')
      .include('discount')
      .limit(1000);

    const query = Parse.Query.or(pendingProgressQuery, completedQuery);

    const orders = await query.find();

    const normalizedOrders = normalizeOrders(orders);

    dispatch(fetchOrdersSuccess({entities: normalizedOrders, taxRate}));
  } catch (error) {
    dispatch(fetchOrdersFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchOrders error', error);
  }
};
