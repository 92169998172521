export const formatOrder = (order: any) => {
  const profilePicture = order.get('user').get('profilePicture') || null;

  const orderItems = order.get('order').map((orderItem: any) => {
    return {
      id: orderItem.item.id,
      name: orderItem.item.name,
      price: orderItem.item.price,
      deal: orderItem.item.deal,
      defaultService: orderItem.item.defaultService,
      service: orderItem.service,
      quantity: orderItem.number,
      comment: orderItem.comment,
      state: orderItem.state,
      orderNumber: orderItem.orderNumber,
      orderedAt: orderItem.orderedAt,
    };
  });

  const refunds = order.get('refunds')
    ? order.get('refunds').map((refund: any) => {
        return {
          id: refund.id,
          reason: refund.get('reason'),
          amount: refund.get('amount'),
        };
      })
    : [];

  return {
    id: order.id,
    createdAt: order.get('createdAt').toString(),
    table: order.get('table') || 0,
    username: order.get('user').get('name'),
    visitsCount: order.get('user').get('visitsCount'),
    profilePicture: profilePicture && profilePicture._url,
    status: order.get('state'),
    tip: order.get('tip'),
    paymentMode: order.get('paymentMode'),
    paid: order.get('paid'),
    discount:
      (order.get('discountPointer') &&
        order.get('discountPointer').get('discount')) ||
      null,
    orderItems,
    refunds,
  };
};

export const normalizeOrders = (orders: any[]) => {
  return orders.reduce(
    (acc: any, order: any) => {
      const formattedOrder = formatOrder(order);

      acc.byId[formattedOrder.id] = formattedOrder;
      acc.allIds = [...acc.allIds, formattedOrder.id];

      return acc;
    },
    {
      byId: {},
      allIds: [],
    },
  );
};
