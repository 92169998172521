import React from 'react';
import styles from './index.module.css';
import {useTranslation} from 'react-i18next';

interface Props {
  name: string | null;
  profilePicture: string | null;
  rating: number | undefined;
  onClick?: Function;
}

const WaiterCard: React.FC<Props> = ({
  name,
  profilePicture,
  rating,
  onClick,
}) => {
  const {t} = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.picture}
          style={{backgroundImage: `url(${profilePicture})`}}
          onClick={onClick as any}
        />
        <h4 className={styles.name}>{name}</h4>
        <span className={styles.rating}>
          {rating ? `${rating}/5` : t('common.noReview')}
        </span>
      </div>
    </>
  );
};

export default WaiterCard;
