import React, {useMemo} from 'react';
import styles from './index.module.css';
import {CardIconSvg, CashIconSvg, TicketIconSvg} from 'components/SvgIcon';
import Button from 'components/Button';
import concatStyles from 'helpers/concatStyles';
import {Order, OrderItem, PaymentMode, VisitsCount} from 'slices/types';
import {useHistory} from 'react-router-dom';
import calculatePriceSubtotals from '../../../helpers/calculatePriceSubtotals';
import {useTranslation} from 'react-i18next';
import FormattedPrice from 'components/FormattedPrice';

interface Props {
  data: Order;
  taxRate: number;
  index: number;
  onChangePaid: (value: boolean) => void;
  onClickAddItem: () => void;
  onClickAddRefund: () => void;
  orderNumber: number;
  tableOrderId: string;
  restaurantId: string;
}

const OrderCard: React.FC<Props> = ({
  data,
  taxRate,
  index,
  onChangePaid,
  onClickAddItem,
  onClickAddRefund,
  orderNumber,
  tableOrderId,
  restaurantId,
}) => {
  const {
    id,
    orderItems,
    paymentMode,
    discount,
    profilePicture,
    username,
    tip,
    paid,
    refunds,
    visitsCount,
  } = data;

  const history = useHistory();
  const {t} = useTranslation();

  const visits = visitsCount.find(
    (obj: VisitsCount) => obj.restaurantId === restaurantId,
  )!.visits;

  const renderPayment = (type: PaymentMode) => {
    switch (type) {
      case 'cash':
        return (
          <>
            {t('common.cash')}
            <CashIconSvg size={36} fill="#000000" />
          </>
        );
      case 'card':
        return (
          <>
            {t('common.card')}
            <CardIconSvg size={36} fill="#000000" />
          </>
        );
      case 'applePay':
        return (
          <>
            {t('common.applePay')}
            <CardIconSvg size={36} fill="#000000" />
          </>
        );
    }
  };

  const orderedItems = orderItems.filter((item) => item.state !== 'pending');

  const {subTotal, tips, taxes, total} = useMemo(
    () => calculatePriceSubtotals(data, taxRate),
    [data, taxRate],
  );

  const organizedItems: any = {};

  orderedItems.forEach((item: OrderItem) => {
    if (!organizedItems[item.orderNumber]) {
      organizedItems[item.orderNumber] = [];
    }
    organizedItems[item.orderNumber].push(item);
  });

  const computeClass = (state: string) => {
    switch (state) {
      case 'progress':
        return styles.rowProgressHighlighted;
      case 'confirmed':
        return styles.rowConfirmedHighlighted;
      case 'completed':
        return styles.rowCompletedHighlighted;
      default:
        return '';
    }
  };

  const renderItems = (orderItems: Array<OrderItem>, key: number) =>
    orderItems.map(
      ({
        quantity,
        id,
        name,
        comment,
        state,
        price,
        deal,
        defaultService,
        service,
      }) => (
        <div
          key={`${id}-${Math.random()}`}
          className={
            orderNumber === 0
              ? concatStyles([computeClass(state), styles.row, styles.pointer])
              : orderNumber === key
              ? concatStyles([computeClass(state), styles.row])
              : concatStyles([styles.row, styles.pointer])
          }
          onClick={() => {
            history.push(`/orders/${tableOrderId}/${key}`);
          }}
          style={{marginBottom: '16px'}}>
          <div className={styles.itemRowTitle}>
            <span className={styles.itemRowCount}>{quantity}</span>
            <div>
              <p className={styles.description} style={{margin: '3px 8px 0 0'}}>
                {name}
              </p>
              {service && defaultService !== service && (
                <p className={styles.comment}>
                  {t('features.TableOrder.OrderCard.secondService')}
                </p>
              )}
              {comment && <p className={styles.comment}>{comment}</p>}
            </div>
          </div>
          <span className={styles.value} style={{alignSelf: 'flex-start'}}>
            <FormattedPrice amount={(price - (deal || 0)) * quantity} />
          </span>
        </div>
      ),
    );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.profile}>
          <div
            className={styles.profilePicture}
            style={{
              backgroundImage: `url(${profilePicture})`,
            }}>
            <div className={styles.pictureBadge}>{index}</div>
          </div>
          <div className={styles.profileMeta}>
            <h3 className={styles.profileName}>{`${username} (${visits})`}</h3>
            <span className={styles.headerOrderId}>#{id.toUpperCase()}</span>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div>
            <h3
              className={styles.paymentRowTitle}
              style={{color: paid ? 'green' : '#F23B4D'}}>
              {paid
                ? t('features.TableOrder.OrderCard.paid')
                : t('features.TableOrder.OrderCard.notPaid')}
            </h3>
          </div>
          <div>
            {paid && (
              <span className={styles.paymentRowValue}>
                {renderPayment(paymentMode)}
              </span>
            )}
            {!paid && (
              <Button
                className={styles.buttonPaidCash}
                text={t('features.TableOrder.OrderCard.markPaidCash')}
                onClick={onChangePaid}
              />
            )}
          </div>
        </div>
        <h3 className={styles.orderSubtitle}>Order</h3>
        <div>
          {Object.keys(organizedItems).map((key) =>
            renderItems(organizedItems[key], parseInt(key)),
          )}
        </div>
        {refunds.length > 0 && (
          <>
            <h3 className={styles.orderSubtitle}>Refunds</h3>
            {refunds.map((refund) => (
              <div
                className={styles.row}
                style={{marginBottom: '16px'}}
                key={refund.id}>
                <div className={styles.itemRowTitle}>
                  <div>
                    <p
                      className={styles.description}
                      style={{margin: '3px 8px 0 0'}}>
                      {refund.reason}
                    </p>
                  </div>
                </div>
                <span
                  className={styles.value}
                  style={{alignSelf: 'flex-start'}}>
                  -<FormattedPrice amount={refund.amount} />
                </span>
              </div>
            ))}
          </>
        )}
        {!paid && (
          <Button
            className={styles.buttonAddItem}
            text={t('features.TableOrder.OrderCard.addAnItem')}
            onClick={onClickAddItem}
          />
        )}
        <Button
          className={styles.buttonAddItem}
          text={t('features.TableOrder.OrderCard.addARefund')}
          onClick={onClickAddRefund}
        />
        <div>
          {discount && (
            <div className={styles.row}>
              <div className={styles.promoRowTitle}>
                <TicketIconSvg size={26} fill="#A6A6A6" />
                <p className={styles.description}>{t('common.promoCode')}</p>
              </div>
              <span className={styles.value}>
                -<FormattedPrice amount={discount} />
              </span>
            </div>
          )}
        </div>
        <div className={styles.accounting}>
          <div className={styles.row}>
            <p className={styles.description}>{t('common.subtotal')}</p>
            <span className={styles.value}>
              <FormattedPrice amount={subTotal} />
            </span>
          </div>
          <div className={styles.row}>
            <p className={styles.description}>{t('common.tax')}</p>
            <span className={styles.value}>
              <FormattedPrice amount={taxes} />
            </span>
          </div>
          <div className={styles.row}>
            <p className={styles.description}>
              {t('features.TableOrder.OrderCard.tip', {
                tip: Math.round((tip || 0) * 100),
              })}
            </p>
            <span className={styles.value}>
              <FormattedPrice amount={tips} />
            </span>
          </div>
          <div className={styles.row}>
            <p className={styles.description}>{t('common.total')}</p>
            <span className={styles.value}>
              <FormattedPrice amount={total} />
            </span>
          </div>
        </div>
      </div>
      <div className={styles.separator} />
    </div>
  );
};

export default OrderCard;
