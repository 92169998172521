import React from "react";

import styles from "./index.module.css";
import concatStyles from "helpers/concatStyles";

interface Props {
  name: string;
  label: string;
  error?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  loading?: boolean;
  suffix?: string;
}

const TextAreaForm = React.forwardRef<any, Props>(
  (
    {
      loading = false,
      name,
      label,
      error = false,
      defaultValue,
      disabled,
      suffix,
    },
    ref,
  ) => (
    <div
      className={concatStyles([
        styles.container,
        error ? styles.error : "",
        loading ? styles.skeleton : "",
      ])}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      {!loading ? (
        <textarea
          rows={4}
          id={name}
          ref={ref}
          autoComplete="none"
          name={name}
          defaultValue={defaultValue}
          disabled={disabled || loading}
          className={styles.input}
        />
      ) : (
        <textarea rows={4} className={styles.input} disabled />
      )}
      <span className={styles.suffix}>{suffix}</span>
    </div>
  ),
);

export default TextAreaForm;
