import React, {useMemo, useState} from 'react';
import styles from './index.module.css';
import Modal from 'components/Modal';
import Button from 'components/Button';
import TextInputForm from 'components/TextInputForm';
import {Order} from 'slices/types';
import calculatePriceSubtotals from '../../../helpers/calculatePriceSubtotals';
import {useTranslation} from 'react-i18next';
import FormattedPrice from '../../../components/FormattedPrice';

interface Props {
  requestClose: Function;
  onSubmit: (
    amount: number,
    taxRate: number,
    tip: number,
    reason: string,
    order: Order,
  ) => void;
  order: Order;
  taxRate: number;
}

const RefundModal: React.FC<Props> = ({
  requestClose,
  onSubmit,
  order,
  taxRate,
}) => {
  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState('');
  const {t} = useTranslation();

  const {subTotal} = useMemo(() => calculatePriceSubtotals(order, taxRate), [
    order,
    taxRate,
  ]);

  const tip = order.tip || 0;

  const refundWithTipAndTax = amount + amount * taxRate + amount * tip;

  // TODO: show error message
  const handleSubmit = () => {
    if (amount > 0 && reason.length > 0 && amount <= subTotal) {
      onSubmit(amount, taxRate, tip, reason, order);
    }
  };

  return (
    <Modal
      title={t('features.TableOrder.RefundModal.title')}
      requestClose={requestClose}>
      <>
        <TextInputForm
          name="amount"
          label={t('common.amount')}
          defaultValue={0}
          type="number"
          min={0}
          onChange={(value) => setAmount(parseInt(value) || 0)}
        />
        <TextInputForm
          name="reason"
          label={t('common.reason')}
          defaultValue=""
          type="string"
          onChange={(value) => setReason(value)}
        />
        <div className={styles.total}>
          {t('features.TableOrder.RefundModal.totalRefund')}
          <FormattedPrice amount={refundWithTipAndTax} />
        </div>
        <Button
          className={styles.button}
          text="Confirm"
          disabled={!amount || !reason}
          onClick={handleSubmit}
        />
      </>
    </Modal>
  );
};

export default RefundModal;
