import React from 'react';

import styles from './index.module.css';
import concatStyles from 'helpers/concatStyles';

interface Props {
  name: string;
  placeholder: string;
  type?: string;
  error?: string;
  className?: string;
}

const TextInput = React.forwardRef<any, Props>(
  ({placeholder, type = 'text', name, error = false, className}, ref) => (
    <>
      <input
        ref={ref}
        autoComplete="none"
        type={type}
        aria-label={name}
        name={name}
        placeholder={placeholder}
        className={concatStyles([
          styles.input,
          className,
          error ? styles.error : '',
        ])}
      />
      {error && <span className={styles.errorLabel}>{error}</span>}
    </>
  ),
);

export default TextInput;
