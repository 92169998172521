import React, {useState} from 'react';
import styles from './index.module.css';
import {WaiterItem} from 'slices/types';
import WaitersList from '../WaitersList';
import NumberList from '../NumberList';
import Button from 'components/Button';
import {useTranslation} from 'react-i18next';

interface Props {
  waiters: WaiterItem[];
  capacity: number;
  onSubmit: (values: Form) => void;
}

export interface Form {
  waiterId: string;
  minGuests: number;
}

interface FormRaw {
  waiterId?: string;
  minGuests: number;
}

const OpenForm: React.FC<Props> = ({capacity, waiters, onSubmit}) => {
  const {t} = useTranslation();

  const [values, setValues] = useState<FormRaw>({minGuests: 1});

  const handleSubmit = () => {
    if (values.waiterId) {
      onSubmit(values as Form);
    }
  };

  return (
    <div className={styles.container}>
      <b className={styles.subtitle}>{t('common.upTo', {capacity})}</b>
      <b>{t('common.waiter')}</b>
      <WaitersList
        waiters={waiters}
        selected={values.waiterId}
        onChange={(waiterId) => {
          setValues({...values, waiterId: waiterId});
        }}
      />
      <b>{t('common.guests')}</b>
      <NumberList
        onChange={(number) => {
          setValues({...values, minGuests: number});
        }}
        selected={values.minGuests}
        size={capacity}
      />
      <Button
        className={styles.button}
        text={t('features.Tables.OpenForm.openTable')}
        onClick={handleSubmit}
        disabled={values.waiterId === undefined}
      />
    </div>
  );
};

export default OpenForm;
