import React, {FC} from "react";

interface Props {
  fill?: string;
  size?: number;
}

export const DragIcon: FC<Props> = ({fill = "#000", size = 48}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.01 7.037l.041.01a1.444 1.444 0 011.291 1.258l2.67 3.012a.677.677 0 01.126.677.634.634 0 01-.592.38h-2.194v10.103h10.103v-2.194a.635.635 0 01.38-.592.677.677 0 01.677.127l3.013 2.67c.633.078 1.14.567 1.245 1.192a.634.634 0 010 .482 1.446 1.446 0 01-1.245 1.192l-3.013 2.67a.55.55 0 01-.422.168h-.254a.635.635 0 01-.38-.592l-.001-2.235H25.352v10.102h2.194a.634.634 0 01.592.38.677.677 0 01-.126.677l-2.67 3.013a1.445 1.445 0 01-1.188 1.244.661.661 0 01-.245.057.676.676 0 01-.245-.056l.106.013a1.445 1.445 0 01-1.294-1.258l-2.67-3.013a.677.677 0 01-.126-.677.635.635 0 01.592-.38h2.193V25.365H12.362v2.193a.634.634 0 01-.38.592l-.253.042a.55.55 0 01-.423-.169l-3.013-2.67a1.445 1.445 0 01-1.245-1.19.635.635 0 01.001-.488 1.444 1.444 0 011.244-1.187l3.013-2.67a.677.677 0 01.676-.127.635.635 0 01.38.592v2.194h10.103V12.374h-2.193a.635.635 0 01-.592-.38.677.677 0 01.127-.677l2.669-3.012a1.444 1.444 0 011.29-1.258.635.635 0 01.243-.01z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default DragIcon;
