import React, {FC} from "react";

interface Props {
  fill?: string;
  size?: number;
}

export const CardIcon: FC<Props> = ({fill = "#000", size = 48}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.927 12H10.053A3.056 3.056 0 007 15.053v17.255a3.056 3.056 0 003.053 3.053h27.874a3.056 3.056 0 003.053-3.053V15.053A3.056 3.056 0 0037.927 12zM31.27 26.714a1.062 1.062 0 01-.833-1.72c.298-.377.455-.831.455-1.314 0-.482-.157-.936-.455-1.314a1.061 1.061 0 111.668-1.315c.587.746.91 1.68.91 2.63s-.323 1.883-.91 2.629a1.06 1.06 0 01-.835.404zm3.427 2.283a1.06 1.06 0 01-1.5.082 1.062 1.062 0 01-.08-1.5 5.83 5.83 0 001.491-3.899 5.83 5.83 0 00-1.491-3.898 1.062 1.062 0 111.58-1.418 7.95 7.95 0 012.035 5.316 7.95 7.95 0 01-2.035 5.317z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CardIcon;
