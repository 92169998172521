import {MenuItem, Content} from 'slices/types';

export const normalizeMenu = (data: any) => {
  return data.reduce(
    (acc: any, category: any) => {
      // normalize menu items by items
      const newMenuItems = category.menu.reduce((acc: any, menuItem: any) => {
        acc[menuItem.id] = {
          category: category.id,
          id: menuItem.id,
          name: menuItem.name,
          available: menuItem.available,
          description: menuItem.description,
          price: menuItem.price,
          deal: menuItem.deal,
          prepTime: menuItem.prepTime,
          defaultService: menuItem.defaultService,
          coverPhoto: menuItem.coverPhoto && menuItem.coverPhoto._url,
        };

        return {...acc};
      }, {});
      acc.menuItems = {...acc.menuItems, ...newMenuItems};

      // normalize menu categories by items
      acc.menuCategories.byId[category.id] = {
        id: category.id,
        name: category.name,
        menuItems: category.menu.map((item: any) => item.id),
      };

      // normalize menu categories by ids
      acc.menuCategories.allIds.push(category.id);

      return {...acc};
    },
    {menuCategories: {byId: {}, allIds: []}, menuItems: {}},
  );
};

export const updateMenuFormatter = (
  menuItem: MenuItem,
  content: Content,
  position: number,
  coverPhoto: string | null,
) => {
  const {menuItems, menuCategories, currencySymbol} = content;

  const isNewItem = !Object.keys(menuItems).includes(menuItem.id);

  if (!isNewItem) {
    // remove id from category
    const originalCategoryId = menuItems[menuItem.id].category;

    // mutate menuCategories
    const index = menuCategories.byId[originalCategoryId].menuItems.findIndex(
      (id: string) => id === menuItem.id,
    );
    menuCategories.byId[originalCategoryId].menuItems.splice(index, 1);
  }

  // insert new id to category
  menuCategories.byId[menuItem.category].menuItems.splice(
    position,
    0,
    menuItem.id,
  );

  // update item
  menuItems[menuItem.id] = {
    ...menuItem,
    coverPhoto,
    price: menuItem.price,
    deal: isNaN(menuItem.deal!) ? undefined : menuItem.deal,
  };

  return {menuItems, menuCategories, currencySymbol};
};
