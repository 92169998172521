import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import styles from './index.module.css';
import {checkHasPassword} from 'slices/password';
import {RootState} from 'state';
import Unlock from 'features/Unlock';
import Menu from 'features/Menu';
import TableOrders from 'features/TableOrders';
import TableOrder from 'features/TableOrder';
import Profile from 'features/Profile';
import Stats from 'features/Stats';
import Waiters from 'features/Waiters';
import Tables from 'features/Tables';
import useLiveWaiterCall from 'hooks/useLiveWaiterCall';
import {fetchVersion, setIsOnline} from '../slices/version';
import {purge as purgeMenu} from '../slices/menu';
import {purge as purgeTableOrders} from '../slices/tableOrders';
import {purge as purgeOrders} from '../slices/orders';
import {purge as purgeTables} from '../slices/tables';
import {purge as purgeWaiters} from '../slices/waiters';
import {purge as purgeWaiterCalls} from '../slices/waiterCall';
import Refresh from '../features/Refresh';
import useLiveVersion from '../helpers/useLiveVersion';
import {useTranslation} from 'react-i18next';
import {fetchProfile} from '../features/Profile/profileSlice';

export const PrivateRoutes: React.FC = () => {
  const dispatch = useDispatch();

  useLiveWaiterCall();
  useLiveVersion();

  const {t} = useTranslation();

  const {hasPassword, unlocked} = useSelector(
    (state: RootState) => state.password,
  );

  const {version, isOnline} = useSelector((state: RootState) => state.version);

  const showUnlock = hasPassword && !unlocked;

  useEffect(() => {
    dispatch(checkHasPassword());
    dispatch(fetchVersion());

    // We need to fetch the profile here to retrieve currency info (this could be extracted to a separate config store)
    dispatch(fetchProfile());

    // Force reload when back online
    const reloadWhenBackOnline = () => {
      dispatch(setIsOnline(true));
      document.location.reload();
    };

    window.addEventListener('online', reloadWhenBackOnline);

    return () => {
      window.removeEventListener('online', reloadWhenBackOnline);
    };
  }, [dispatch]);

  // Set marker when offline
  useEffect(() => {
    const markOffline = () => {
      dispatch(setIsOnline(false));
    };
    window.addEventListener('offline', markOffline);
    return () => {
      window.removeEventListener('offline', markOffline);
    };
  }, [dispatch]);

  const correctVersion = process.env.REACT_APP_VERSION === version.version;

  useEffect(() => {
    // Force empty reducers if version has been updated
    if (!correctVersion) {
      dispatch(purgeMenu());
      dispatch(purgeOrders());
      dispatch(purgeTableOrders());
      dispatch(purgeTables());
      dispatch(purgeWaiterCalls());
      dispatch(purgeWaiters());
    }
  }, [dispatch, correctVersion]);

  if (!isOnline) {
    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('common.Offline.title')}</div>
        <div>{t('common.Offline.content')}</div>
      </div>
    );
  }

  return (
    <Router>
      {correctVersion && (
        <Switch>
          <Route exact path="/orders" component={TableOrders} />
          <Route
            exact
            path="/orders/:tableOrderId/:orderNumber"
            component={TableOrder}
          />
          <Route exact path="/orders/:tableOrderId" component={TableOrder} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/menu" component={Menu} />a
          <Route
            exact
            path="/profile"
            component={showUnlock ? Unlock : Profile}
          />
          <Route exact path="/stats" component={showUnlock ? Unlock : Stats} />
          <Route
            exact
            path="/waiters"
            component={showUnlock ? Unlock : Waiters}
          />
          <Redirect exact from="/" to="/orders" />
          <Redirect exact from="/login" to="/orders" />
          <Redirect from="*" to="/orders" />
        </Switch>
      )}
      {!correctVersion && (
        <Switch>
          <Route path="*" component={Refresh} />
        </Switch>
      )}
    </Router>
  );
};

export default PrivateRoutes;
