import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';
import {AppThunk} from 'state';
import {formatTable} from 'helpers/tableFormatter';
import {TableClassName, TableItem} from './types';

interface State {
  error: string | null;
  fetchLoading: boolean;
  addLoading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  tables: TableItem[];
  addSuccessNotification: boolean;
  updateSuccessNotification: boolean;
  deleteSuccessNotification: boolean;
}

const initialState: State = {
  error: null,
  fetchLoading: false,
  addLoading: false,
  updateLoading: false,
  deleteLoading: false,
  tables: [],
  addSuccessNotification: false,
  updateSuccessNotification: false,
  deleteSuccessNotification: false,
};

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    fetchTablesStart(state) {
      state.fetchLoading = true;
      state.error = null;
    },
    fetchTablesSuccess(state, action: PayloadAction<TableItem[]>) {
      state.fetchLoading = false;
      state.tables = action.payload;
    },
    fetchTablesFailed(state, action: PayloadAction<string>) {
      state.fetchLoading = false;
      state.error = action.payload;
    },
    addTableStart(state) {
      state.addLoading = true;
      state.addSuccessNotification = false;
      state.error = null;
    },
    addTableSuccess(state, action: PayloadAction<TableItem>) {
      state.addLoading = false;
      state.addSuccessNotification = true;
      state.tables.push(action.payload);
    },
    addTableFailed(state, action: PayloadAction<string>) {
      state.addLoading = false;
      state.error = action.payload;
    },
    updateTableStart(state) {
      state.updateLoading = true;
      state.updateSuccessNotification = false;
      state.error = null;
    },
    updateTableSuccess(state, action: PayloadAction<TableItem>) {
      state.updateLoading = false;
      state.updateSuccessNotification = true;

      const tableIndex = state.tables.findIndex(
        (table) => table.id === action.payload.id,
      );
      if (tableIndex > -1) {
        state.tables[tableIndex] = action.payload;
      }
    },
    updateTableFailed(state, action: PayloadAction<string>) {
      state.updateLoading = false;
      state.error = action.payload;
    },
    deleteTableStart(state) {
      state.deleteLoading = true;
      state.deleteSuccessNotification = false;
      state.error = null;
    },
    deleteTableSuccess(state, action: PayloadAction<string>) {
      state.deleteLoading = false;
      state.deleteSuccessNotification = true;

      const tableIndex = state.tables.findIndex(
        (table) => table.id === action.payload,
      );
      if (tableIndex > -1) {
        state.tables.splice(tableIndex, 1);
      }
    },
    deleteTableFailed(state, action: PayloadAction<string>) {
      state.deleteLoading = false;
      state.error = action.payload;
    },
    reset(state) {
      state.fetchLoading = false;
      state.updateLoading = false;
      state.addLoading = false;
      state.error = null;
      state.addSuccessNotification = false;
      state.updateSuccessNotification = false;
      state.deleteSuccessNotification = false;
    },
    dismissNotifications(state) {
      state.addSuccessNotification = false;
      state.updateSuccessNotification = false;
      state.deleteSuccessNotification = false;
    },
    purge() {
      return initialState;
    },
  },
});

export const {
  fetchTablesStart,
  fetchTablesSuccess,
  fetchTablesFailed,
  addTableStart,
  addTableSuccess,
  addTableFailed,
  updateTableStart,
  updateTableSuccess,
  updateTableFailed,
  deleteTableStart,
  deleteTableSuccess,
  deleteTableFailed,
  reset,
  dismissNotifications,
  purge,
} = tablesSlice.actions;

export default tablesSlice.reducer;

/**
 * Thunks
 */

export const fetchTables = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchTablesStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    const tablesQuery = new Parse.Query('Table');
    tablesQuery.equalTo('restaurant', restaurant);

    const tables = await tablesQuery.find();

    dispatch(fetchTablesSuccess(tables.map(formatTable)));
  } catch (error) {
    dispatch(fetchTablesFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchTables error', error);
  }
};

interface AddUpdateTablePayload {
  name: string;
  capacity: number;
}

export const addTable = (formData: AddUpdateTablePayload): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(addTableStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    const table = await new TableClassName().save({
      restaurant,
      name: formData.name,
      capacity: formData.capacity,
      available: true,
    });

    dispatch(addTableSuccess(formatTable(table)));
  } catch (error) {
    dispatch(addTableFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('addTable error', error);
  }
};

export const updateTable = (
  id: string,
  formData: AddUpdateTablePayload,
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateTableStart());

    const query = new Parse.Query(TableClassName);
    const table = await query.get(id);

    table.set(formData);
    await table.save();
    dispatch(updateTableSuccess(formatTable(table)));
  } catch (error) {
    dispatch(updateTableFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('updateTable error', error);
  }
};

export const deleteTable = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(deleteTableStart());

    const query = new Parse.Query(TableClassName);
    const table = await query.get(id);

    await table.destroy();
    dispatch(deleteTableSuccess(id));
  } catch (error) {
    dispatch(deleteTableFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('deleteTable error', error);
  }
};
