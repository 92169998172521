import React from "react";

import DeleteButtonForm from "components/DeleteButtonForm";
import styles from "./index.module.css";
import Skeleton from "components/Skeleton";

interface Props {
  id: string;
  rowCount: number;
}

const CategoryLoading: React.FC<Props> = ({rowCount, id}) => {
  return (
    <>
      {[...Array(rowCount)].map((_, i) => (
        <div key={id + i} className={styles.row}>
          <Skeleton className={styles.loading} height={64} />
          <DeleteButtonForm disabled onClick={() => {}} />
        </div>
      ))}
    </>
  );
};

export default CategoryLoading;
