import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';
import {subDays} from 'date-fns';
import moment from 'moment';

import {AppThunk} from 'state';
import {normalizeReviews, formatStats} from './statsFormatter';

type Review = {
  id: string;
  createdAt: string;
  username: string;
  profilePicture: string | null;
  rating: number;
  scores: {name: string; value: number}[];
};

type ReviewsContent = {
  byId: {[id: string]: Review};
  allIds: string[];
};

type Stats = {
  revenue: number;
  count: number;
  avg: number;
};

interface State {
  error: string | null;
  fetchLoading: boolean;
  updateLoading: boolean;
  reviewCount: number;
  reviewAvg: number;
  reviews: ReviewsContent;
  todayStats: Stats;
  rangeStats: Stats;
  startDate: string;
  endDate: string;
}

const initialState: State = {
  error: null,
  fetchLoading: false,
  updateLoading: false,
  startDate: moment().subtract(1, 'weeks').toISOString(),
  endDate: moment().toISOString(),
  reviewCount: 0,
  reviewAvg: 0,
  reviews: {
    byId: {},
    allIds: [],
  },
  todayStats: {
    revenue: 0,
    count: 0,
    avg: 0,
  },
  rangeStats: {
    revenue: 0,
    count: 0,
    avg: 0,
  },
};

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    fetchStatsStart(state) {
      state.fetchLoading = true;
      state.error = null;
    },
    fetchStatsSuccess(
      state,
      action: PayloadAction<{
        reviews: ReviewsContent;
        reviewCount: number;
        reviewAvg: number;
        todayStats: Stats;
        rangeStats: Stats;
      }>,
    ) {
      const {
        reviews,
        reviewCount,
        reviewAvg,
        todayStats,
        rangeStats,
      } = action.payload;
      state.fetchLoading = false;
      state.reviews = reviews;
      state.reviewCount = reviewCount;
      state.reviewAvg = reviewAvg;
      state.todayStats = todayStats;
      state.rangeStats = rangeStats;
    },
    fetchStatsFailed(state, action: PayloadAction<string>) {
      state.fetchLoading = false;
      state.error = action.payload;
    },
    updateDateRangeStart(
      state,
      action: PayloadAction<{startDate: string | null; endDate: string | null}>,
    ) {
      const {startDate, endDate} = action.payload;

      state.updateLoading = true;
      state.error = null;
      state.startDate = startDate ? startDate : state.startDate;
      state.endDate = endDate ? endDate : state.endDate;
    },
    updateDateRangeSuccess(state, action: PayloadAction<Stats>) {
      state.updateLoading = false;
      state.rangeStats = action.payload;
    },
    updateDateRangeFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.updateLoading = false;
    },
    fetchTodayStatsStart(state) {
      state.error = null;
    },
    fetchTodayStatsSuccess(state, action: PayloadAction<Stats>) {
      state.todayStats = action.payload;
    },
    fetchTodayStatsFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const {
  fetchStatsStart,
  fetchStatsSuccess,
  fetchStatsFailed,
  updateDateRangeStart,
  updateDateRangeSuccess,
  updateDateRangeFailed,
  fetchTodayStatsStart,
  fetchTodayStatsSuccess,
  fetchTodayStatsFailed,
} = statsSlice.actions;

export default statsSlice.reducer;

/**
 * Thunks
 */

export const fetchStats = (): AppThunk => async (dispatch, getSate) => {
  try {
    dispatch(fetchStatsStart());
    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    // Reviews
    const reviewCount = restaurant.get('reviewsCount');
    const reviewAvg = restaurant.get('rating');

    const reviewsQuery = new Parse.Query('Review');
    reviewsQuery
      .equalTo('restaurant', restaurant)
      .descending('createdAt')
      .limit(50)
      .include('user');

    const reviews = await reviewsQuery.find();
    const normalizedReviews: ReviewsContent = normalizeReviews(reviews);

    // Today Stats
    const fromDate = subDays(Date.now(), 2);
    const todayQuery = new Parse.Query('Order');
    todayQuery
      .equalTo('restaurant', restaurant)
      .greaterThan('createdAt', fromDate)
      .include('discount');

    const todayOrders = await todayQuery.find();
    const todayStats = formatStats(todayOrders);

    // Range Stats
    const {startDate, endDate} = getSate().stats;
    const rangeQuery = new Parse.Query('Order');
    rangeQuery
      .equalTo('restaurant', restaurant)
      .greaterThanOrEqualTo('createdAt', new Date(startDate))
      .lessThanOrEqualTo('createdAt', new Date(endDate))
      .include('discount');

    const rangeOrders = await rangeQuery.find();
    const rangeStats = formatStats(rangeOrders);

    dispatch(
      fetchStatsSuccess({
        reviews: normalizedReviews,
        reviewCount,
        reviewAvg,
        todayStats,
        rangeStats,
      }),
    );
  } catch (error) {
    dispatch(fetchStatsFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchStats error', error);
  }
};

export const updateDateRange = ({
  startDate,
  endDate,
}: {
  startDate: string | null;
  endDate: string | null;
}): AppThunk => async (dispatch) => {
  try {
    dispatch(updateDateRangeStart({startDate, endDate}));
    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    // Range Stats
    const rangeQuery = new Parse.Query('Order');
    rangeQuery
      .equalTo('restaurant', restaurant)
      .greaterThanOrEqualTo('createdAt', new Date(startDate!))
      .lessThanOrEqualTo('createdAt', new Date(endDate!))
      .include('discount');

    const rangeOrders = await rangeQuery.find();
    const rangeStats = formatStats(rangeOrders);

    dispatch(updateDateRangeSuccess(rangeStats));
  } catch (error) {
    dispatch(updateDateRangeFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('updateDateRange error', error);
  }
};

export const fetchTodayStats = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchTodayStatsStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    // Today Stats
    const fromDate = subDays(Date.now(), 2);
    const todayQuery = new Parse.Query('Order');
    todayQuery
      .equalTo('restaurant', restaurant)
      .greaterThan('createdAt', fromDate)
      .include('discount');

    const todayOrders = await todayQuery.find();
    const todayStats = formatStats(todayOrders);

    dispatch(fetchTodayStatsSuccess(todayStats));
  } catch (error) {
    dispatch(fetchTodayStatsFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchTodayStats error', error);
  }
};
