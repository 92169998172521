import React from 'react';

import styles from './index.module.css';
import concatStyles from '../../helpers/concatStyles';
import FormattedPrice from '../FormattedPrice';

interface Props {
  title: string;
  description: string;
  price: number;
  image: string | null;
  onClick: () => void;
  selected?: boolean;
}

const MenuRow: React.FC<Props> = ({
  title,
  description,
  price,
  image,
  onClick,
  selected,
}) => {
  return (
    <div
      onClick={onClick}
      className={concatStyles([
        styles.container,
        selected ? styles.containerSelected : '',
      ])}>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        <span className={styles.price}>
          <FormattedPrice amount={price} />
        </span>
      </div>
      <div className={styles.img} style={{backgroundImage: `url(${image})`}} />
    </div>
  );
};

export default MenuRow;
