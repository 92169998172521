import React from 'react';

import styles from './index.module.css';
import concatStyles from 'helpers/concatStyles';
import Spinner from 'components/Spinner';
import {useTranslation} from 'react-i18next';

interface Props {
  className?: string;
  loading?: boolean;
  onReset: () => void;
}

const ActionBarForm: React.FC<Props> = ({className, loading, onReset}) => {
  const {t} = useTranslation();

  const onResetHandler = () => {
    if (!loading) onReset();
  };

  const save = t('common.save');

  return (
    <div className={concatStyles([styles.container, className])}>
      <div className={styles.inner}>
        <div
          onClick={onResetHandler}
          className={concatStyles([
            styles.resetButton,
            loading ? styles.disabled : '',
          ])}>
          {t('common.cancel')}
        </div>
        {loading ? (
          <div className={styles.loading}>
            <Spinner fill="#fff" />
          </div>
        ) : (
          <input type="submit" value={save} className={styles.saveButton} />
        )}
      </div>
    </div>
  );
};

export default ActionBarForm;
