import React from "react";

import styles from "./index.module.css";
import concatStyles from "helpers/concatStyles";
import {DragIconSvg} from "components/SvgIcon";

interface Props {
  name: string;
  type?: string;
  error?: boolean;
  defaultValue?: string;
  placeholder: string;
  disabled?: boolean;
  index: number;
}

const DragTextInputForm = React.forwardRef<any, Props>(
  (
    {
      type = "text",
      index,
      placeholder,
      name,
      error = false,
      defaultValue,
      disabled,
    },
    ref,
  ) => (
    <div
      className={concatStyles([styles.container, error ? styles.error : ""])}>
      <span className={styles.position}>{index}</span>
      <input
        placeholder={placeholder}
        id={name}
        ref={ref}
        autoComplete="none"
        type={type}
        name={name}
        defaultValue={defaultValue}
        disabled={disabled}
        className={styles.input}
      />
      <div className={styles.grabIcon}>
        <DragIconSvg fill="#A6A6A6" />
      </div>
    </div>
  ),
);

export default DragTextInputForm;
