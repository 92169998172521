import {useEffect, useState} from "react";
import {differenceInSeconds, format} from "date-fns";

const formatTime = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const useCountdown = (
  startDate: string | undefined,
  isDisabled: boolean,
) => {
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    if (!startDate) return;
    let intervalId: NodeJS.Timeout;
    const date = new Date(startDate);

    if (!isDisabled) {
      setCountdown(formatTime(differenceInSeconds(Date.now(), date)));

      intervalId = setInterval(
        () => setCountdown(formatTime(differenceInSeconds(Date.now(), date))),
        1000,
      );
    } else {
      setCountdown(format(date, "d MMM hh:mm"));
    }

    return () => clearInterval(intervalId);
  }, [isDisabled, startDate]);

  return countdown;
};

export default useCountdown;
