import {combineReducers} from 'redux';
import {configureStore, getDefaultMiddleware, Action} from '@reduxjs/toolkit';
import {ThunkAction} from 'redux-thunk';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {createLogger} from 'redux-logger';

import authReducer from './auth';
import waitersReducer from 'slices/waiters';
import tableOrdersReducer from 'slices/tableOrders';
import profileReducer from 'features/Profile/profileSlice';
import menuReducer from 'slices/menu';
import ordersReducer from 'slices/orders';
import statsReducer from 'features/Stats/statsSlice';
import waiterCallReducer from 'slices/waiterCall';
import tablesReducer from 'slices/tables';
import passwordReducer from 'slices/password';
import versionReducer from 'slices/version';

// Combine to a root reducer with type
const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  tables: tablesReducer,
  menu: menuReducer,
  orders: ordersReducer,
  stats: statsReducer,
  waiters: waitersReducer,
  tableOrders: tableOrdersReducer,
  waiterCall: waiterCallReducer,
  password: passwordReducer,
  version: versionReducer,
});

// Persist redux
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['password', 'version', 'waiterCall', 'stats'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const logger = createLogger({
  predicate: () => false, // Activate this line to hide redux logger logs
});

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(process.env.NODE_ENV === 'production' ? [] : [logger]),
});

let persistor = persistStore(store);
// persistor.purge()

// Types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default {
  store,
  persistor,
};
