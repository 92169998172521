import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import * as Parse from 'parse';
import {subDays} from 'date-fns';
import {fetchTodayStats} from './statsSlice';

let isSubscriptionClosed = false;

const useLiveStats = () => {
  const dispatch = useDispatch();
  const getSubscription = async () => {
    const currentUser = Parse.User.current()!;
    const restaurant = currentUser.get('restaurant');

    const fromDate = subDays(Date.now(), 1);
    const query = new Parse.Query('Order');
    query
      .equalTo('restaurant', restaurant)
      .greaterThan('createdAt', fromDate)
      .include('discount');

    return await query.subscribe();
  };

  useEffect(() => {
    const subscription = getSubscription();

    subscription.then((sub) => {
      sub.on('update', () => {
        dispatch(fetchTodayStats());
      });

      sub.on('create', () => {
        dispatch(fetchTodayStats());
      });

      sub.on('delete', () => {
        dispatch(fetchTodayStats());
      });

      sub.on('open', () => {
        if (isSubscriptionClosed) {
          dispatch(fetchTodayStats());
          isSubscriptionClosed = false;
        }
      });

      sub.on('close', () => {
        isSubscriptionClosed = true;
      });
    });

    return () => {
      subscription.then((sub) => {
        sub.unsubscribe();
        isSubscriptionClosed = false;
      });
      // @ts-ignore
      // Parse.LiveQuery.close();
    };
  }, [dispatch]);
};

export default useLiveStats;
