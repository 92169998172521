import {TableOrderItem} from 'slices/types';
import {formatWaiter} from './waiterFormatter';
import {formatTable} from './tableFormatter';
import {formatOrder} from './orderFormatter';

export const formatTableOrder = (tableOrder: any): TableOrderItem => {
  return {
    id: tableOrder.id,
    createdAt: tableOrder.get('createdAt').toString(),
    usersId: tableOrder.get('usersId'),
    waiter: formatWaiter(tableOrder.get('waiter')),
    orders: tableOrder.get('orders').map(formatOrder),
    minGuests: tableOrder.get('minGuests'),
    table: tableOrder.get('table') && formatTable(tableOrder.get('table')),
    state: tableOrder.get('state'),
    orderCount: tableOrder.get('orderCount'),
  };
};
