import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';

import {AppThunk} from 'state';
import {formatVersion} from '../helpers/versionFormatter';

interface State {
  version: any;
  isOnline: boolean;
}

const initialState: State = {
  version: {platform: 'admin', version: process.env.REACT_APP_VERSION!},
  isOnline: true,
};

const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    fetchVersionSuccess(state, action: PayloadAction<any>) {
      state.version = action.payload;
    },
    setIsOnline(state, action: PayloadAction<any>) {
      state.isOnline = action.payload;
    },
  },
});

export const {fetchVersionSuccess, setIsOnline} = versionSlice.actions;

export default versionSlice.reducer;

export const fetchVersion = (): AppThunk => async (dispatch) => {
  try {
    const query = new Parse.Query('Version');
    query.equalTo('platform', 'admin');
    const version = await query.first();
    const normalizedData = formatVersion(version);
    dispatch(fetchVersionSuccess(normalizedData));
  } catch (error) {
    console.error('Error loading version', error);
    Sentry.captureException(error);
  }
};
