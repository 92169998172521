import React from 'react';

import styles from './index.module.css';
import concatStyles from 'helpers/concatStyles';

interface Props {
  title: string;
  value: React.ReactNode;
  loading?: boolean;
}

const KPI: React.FC<Props> = ({title, value, loading = false}) => {
  return (
    <div
      className={concatStyles([
        styles.container,
        loading ? styles.skeleton : '',
      ])}>
      <h3 className={styles.title}>{title}</h3>
      <span className={styles.value}>{!loading && value}</span>
    </div>
  );
};

export default KPI;
