import React, {useState} from 'react';
import styles from './index.module.css';
import Modal from 'components/Modal';
import MenuList from 'components/MenuList';
import Button from 'components/Button';
import TextInputForm from 'components/TextInputForm';
import {MenuCategory, MenuItem, TableOrderItem} from 'slices/types';
import {useTranslation} from 'react-i18next';

interface Props {
  requestClose: Function;
  menuCategories: {
    byId: {[id: string]: MenuCategory};
    allIds: string[];
  };
  menuItems: {[id: string]: MenuItem};
  onSubmit: (
    menuItem: MenuItem,
    quantity: number,
    tableOrder: TableOrderItem,
  ) => void;
  tableOrder: TableOrderItem;
}

const AddItemModal: React.FC<Props> = ({
  requestClose,
  menuCategories,
  menuItems,
  onSubmit,
  tableOrder,
}) => {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [quantity, setQuantity] = useState(1);
  const {t} = useTranslation();

  const handleSubmit = () => {
    if (selected && quantity > 0) {
      onSubmit(menuItems[selected], quantity, tableOrder);
    }
  };

  return (
    <Modal
      title={t('features.TableOrder.AddItemModal.title')}
      requestClose={requestClose}>
      <>
        <MenuList
          categories={menuCategories}
          items={menuItems}
          onRowClick={(itemId) => setSelected(itemId)}
          selected={selected}
          smallHeight
        />
        <TextInputForm
          name="quantity"
          label={t('features.TableOrder.AddItemModal.quantity')}
          defaultValue={1}
          type="number"
          min={1}
          onChange={(value) => setQuantity(parseInt(value) || 1)}
        />
        <Button
          className={styles.button}
          disabled={!selected}
          text={t('features.TableOrder.AddItemModal.add', {
            details: selected ? `${quantity} ${menuItems[selected].name}` : '',
          })}
          onClick={handleSubmit}
        />
      </>
    </Modal>
  );
};

export default AddItemModal;
