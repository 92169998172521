import React from "react";

import styles from "./index.module.css";

interface Props {}

const Overlay: React.FC<Props> = ({children}) => {
  return <div className={styles.container}>{children}</div>;
};

export default Overlay;
