import React from 'react';
import styles from './index.module.css';
import {TableOrderState} from 'slices/types';
import titleCase from 'helpers/titleCase';
import {useTranslation} from 'react-i18next';

interface Props {
  value: TableOrderState;
  onChange: (value: TableOrderState) => void;
  hideClose: Boolean;
  hideMain: Boolean;
}

const state: TableOrderState[] = [
  'progress',
  'confirmed',
  'completed',
  'closed',
];

const computeClass = (value: string) => {
  switch (value) {
    case 'progress':
      return styles.progressSelected;
    case 'confirmed':
      return styles.confirmedSelected;
    case 'completed':
      return styles.completedSelected;
    case 'closed':
      return styles.closedSelected;
    default:
      return '';
  }
};

const computeName = (value: string, active: boolean, t: Function) => {
  switch (value) {
    case 'progress':
      return t('features.TableOrder.ItemStateSelector.actionNeeded');
    case 'confirmed':
      return active
        ? t('features.TableOrder.ItemStateSelector.sentToKitchen')
        : t('features.TableOrder.ItemStateSelector.sendToKitchen');
    case 'completed':
      return active
        ? t('features.TableOrder.ItemStateSelector.served')
        : t('features.TableOrder.ItemStateSelector.markAsServed');
    case 'closed':
      return active
        ? t('features.TableOrder.ItemStateSelector.tableClosed')
        : t('features.TableOrder.ItemStateSelector.closeTable');
    default:
      return '';
  }
};

const ItemStateSelector: React.FC<Props> = ({
  value,
  onChange,
  hideClose,
  hideMain,
}) => {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      {state.map((state) =>
        (hideClose && state === 'closed') ||
        (hideMain && state !== 'closed') ? (
          <span key={state} />
        ) : (
          <div
            key={state}
            className={value === state ? computeClass(value) : ''}
            onClick={() => onChange(state)}>
            {titleCase(computeName(state, value === state, t))}
          </div>
        ),
      )}
    </div>
  );
};

export default ItemStateSelector;
