export const formatReview = (review: any) => {
  const profilePicture = review.get('user').get('profilePicture') || null;

  const scores = review.get('reviews').map((score: any) => {
    return {
      name: score.name,
      value: score.value,
    };
  });

  return {
    id: review.id,
    createdAt: review.get('createdAt').toString(),
    username: review.get('user').get('name'),
    profilePicture: profilePicture && profilePicture._url,
    rating: review.get('rating'),
    scores,
  };
};

export const normalizeReviews = (reviews: any[]) => {
  return reviews.reduce(
    (acc: any, review: any) => {
      const formattedReview = formatReview(review);

      acc.byId[formattedReview.id] = formattedReview;
      acc.allIds = [...acc.allIds, formattedReview.id];

      return acc;
    },
    {
      byId: {},
      allIds: [],
    },
  );
};

export const formatStats = (orders: any[]) => {
  const revenue = orders.reduce((acc, order) => {
    const tip = order.get('tip');
    const taxRate = order.get('restaurant').get('tax');
    const discount =
      (order.get('discount') && order.get('discount').get('discountValue')) ||
      0;

    const itemsTotal = order
      .get('order')
      .map(
        ({number, item: {price, deal}}: any) => (price - (deal || 0)) * number,
      );

    const subTotal = parseFloat(
      (
        itemsTotal.reduce((acc: any, itemTotal: any) => acc + itemTotal, 0) -
        discount
      ).toFixed(2),
    );

    const tips = (tip || 0) * subTotal;

    const taxes = taxRate * subTotal;

    const total = subTotal + taxes + tips;

    return acc + total;
  }, 0);

  return {
    revenue,
    count: orders.length,
    avg: revenue / orders.length || 0,
  };
};
