import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import styles from './index.module.css';
import useLiveTableOrders from './useLiveTableOrders';
import {TableOrderState, TableCardItem} from 'slices/types';
import {fetchTableOrders, reset} from 'slices/tableOrders';
import {RootState} from 'state';
import titleCase from 'helpers/titleCase';
import Layout from 'components/Layout';
import Title from 'components/Title';
import TableCard from 'components/TableCard';
import Skeleton from 'components/Skeleton';
import Overlay from 'components/Overlay';
import Spinner from 'components/Spinner';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

interface Props {}

const TableOrders: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const {t} = useTranslation();
  const {addToast} = useToasts();

  const {fetchLoading, deleteLoading, tableOrders, error} = useSelector(
    (state: RootState) => state.tableOrders,
  );

  useEffect(() => {
    dispatch(fetchTableOrders());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      addToast(i18next.t('toasts.error'), {appearance: 'error'});
    }
  }, [error]);

  useLiveTableOrders();

  const {version} = useSelector((state: RootState) => state.version);
  const correctVersion = process.env.REACT_APP_VERSION === version.version;
  if (!correctVersion) {
    return (
      <div className={styles.refresh}>{t('features.TableOrders.refresh')}</div>
    );
  }

  // The database was modeled without the notion of customers reordering at different moments, so there's no concept of suborders containing only the items ordered at a given point in time. To keep the refactoring manageable I've added an orderNumber to each individual item inside an order. Here, I'm computing the tableCards to show one per suborder. Not very elegant but otherwise I would have had to refactor the whole app...
  const tableCards = !fetchLoading
    ? tableOrders
        .filter(
          (tableOrder) => !['pending', 'closed'].includes(tableOrder.state),
        )
        .map((tableOrder) =>
          tableOrder.orders
            .map((order) =>
              order.orderItems
                .map((orderItem) => ({
                  tableOrder,
                  state: orderItem.state,
                  orderNumber: orderItem.orderNumber,
                  orderedAt: orderItem.orderedAt,
                }))
                .flat(),
            )
            .flat(),
        )
        .flat()
    : [];

  // And here I'm making sure that each orderNumber can appear only once per table
  const tableCardsToDisplay = tableCards.reduce(
    (accumulator: Array<TableCardItem>, tableCard) => {
      if (
        !accumulator.find(
          (tc: TableCardItem) =>
            tc.orderNumber === tableCard.orderNumber &&
            tc.tableOrder.table.id === tableCard.tableOrder.table.id,
        )
      ) {
        accumulator.push(tableCard);
      }
      return accumulator;
    },
    [],
  );

  const computeName = (value: string, t: Function) => {
    switch (value) {
      case 'progress':
        return t('features.TableOrders.actionNeeded');
      case 'confirmed':
        return t('features.TableOrders.sentToKitchen');
      case 'completed':
        return t('features.TableOrders.served');
      default:
        return '';
    }
  };

  const renderLane = (state: TableOrderState) => (
    <div className={styles.lane}>
      <Title>{titleCase(computeName(state, t))}</Title>
      {!fetchLoading ? (
        tableCardsToDisplay
          .filter((tableCard: {state: string}) => tableCard.state === state)
          .map((tableCard: TableCardItem) => (
            <TableCard
              key={`${tableCard.tableOrder.id}-${tableCard.orderNumber}`}
              style={{margin: '16px 0 16px 0'}}
              item={tableCard.tableOrder.table}
              onClick={() =>
                history.push(
                  `${path}/${tableCard.tableOrder.id}/${tableCard.orderNumber}`,
                )
              }
              tableOrder={tableCard.tableOrder}
              orderNumber={tableCard.orderNumber}
              orderedAt={tableCard.orderedAt}
            />
          ))
      ) : (
        <Skeleton height={91} />
      )}
    </div>
  );

  return (
    <Layout>
      {renderLane('progress')}
      {renderLane('confirmed')}
      {renderLane('completed')}
      {deleteLoading && (
        <Overlay>
          <Spinner fill="#FFF" size={96} />
        </Overlay>
      )}
    </Layout>
  );
};

export default TableOrders;
