import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';
import {AppThunk} from 'state';
import {formatWaiter} from 'helpers/waiterFormatter';
import {
  OrderClassName,
  WaiterClassName,
  WaiterItem,
  TableOrderClassName,
} from './types';

interface State {
  error: string | null;
  fetchLoading: boolean;
  addLoading: boolean;
  deleteLoading: boolean;
  waiters: WaiterItem[];
  addSuccessNotification: boolean;
  deleteSuccessNotification: boolean;
}

const initialState: State = {
  error: null,
  fetchLoading: false,
  addLoading: false,
  deleteLoading: false,
  waiters: [],
  addSuccessNotification: false,
  deleteSuccessNotification: false,
};

const waitersSlice = createSlice({
  name: 'waiters',
  initialState,
  reducers: {
    fetchWaitersStart(state) {
      state.fetchLoading = true;
      state.error = null;
    },
    fetchWaitersSuccess(state, action: PayloadAction<WaiterItem[]>) {
      state.fetchLoading = false;
      state.waiters = action.payload;
    },
    fetchWaitersFailed(state, action: PayloadAction<string>) {
      state.fetchLoading = false;
      state.error = action.payload;
    },
    addWaiterstart(state) {
      state.addLoading = true;
      state.addSuccessNotification = false;
      state.error = null;
    },
    addWaitersuccess(state, action: PayloadAction<WaiterItem>) {
      state.addLoading = false;
      state.addSuccessNotification = true;
      state.waiters.push(action.payload);
    },
    addWaiterFailed(state, action: PayloadAction<string>) {
      state.addLoading = false;
      state.error = action.payload;
    },
    deleteWaiterstart(state) {
      state.deleteLoading = true;
      state.deleteSuccessNotification = false;
      state.error = null;
    },
    deleteWaitersuccess(state, action: PayloadAction<string>) {
      state.deleteLoading = false;
      state.deleteSuccessNotification = true;

      const waiterIndex = state.waiters.findIndex(
        (waiter) => waiter.id === action.payload,
      );
      if (waiterIndex > -1) {
        state.waiters.splice(waiterIndex, 1);
      }
    },
    deleteWaiterFailed(state, action: PayloadAction<string>) {
      state.deleteLoading = false;
      state.error = action.payload;
    },
    reset(state) {
      state.fetchLoading = false;
      state.addLoading = false;
      state.error = null;
      state.addSuccessNotification = false;
      state.deleteSuccessNotification = false;
    },
    dismissNotifications(state) {
      state.addSuccessNotification = false;
      state.deleteSuccessNotification = false;
    },
    purge() {
      return initialState;
    },
  },
});

export const {
  fetchWaitersStart,
  fetchWaitersSuccess,
  fetchWaitersFailed,
  addWaiterstart,
  addWaitersuccess,
  addWaiterFailed,
  deleteWaiterstart,
  deleteWaitersuccess,
  deleteWaiterFailed,
  reset,
  dismissNotifications,
  purge,
} = waitersSlice.actions;

export default waitersSlice.reducer;

/**
 * Thunks
 */

export const fetchWaiters = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchWaitersStart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    const waitersQuery = new Parse.Query('Waiter');
    waitersQuery.equalTo('restaurant', restaurant);

    const waiters = await waitersQuery.find();

    dispatch(fetchWaitersSuccess(waiters.map(formatWaiter)));
  } catch (error) {
    dispatch(fetchWaitersFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchWaiters error', error);
  }
};

interface AddWaiterPayload {
  name: string;
}

export const addWaiter = (
  formData: AddWaiterPayload,
  profilePictureFile: any,
): AppThunk => async (dispatch) => {
  try {
    dispatch(addWaiterstart());

    const currentUser = Parse.User.current()!;
    const restaurant = await currentUser.get('restaurant').fetch();

    let profilePicture = null;

    if (profilePictureFile) {
      const file = new Parse.File('profilePicture', profilePictureFile);
      profilePicture = await file.save();
    }

    const waiter = await new WaiterClassName().save({
      name: formData.name,
      available: true,
      restaurant,
      profilePicture,
      reviewsCount: 0,
    });

    dispatch(addWaitersuccess(formatWaiter(waiter)));
  } catch (error) {
    dispatch(addWaiterFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('addWaiter error', error);
  }
};

export const deleteWaiter = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(deleteWaiterstart());

    const query = new Parse.Query(WaiterClassName);
    const waiter = await query.get(id);

    const queryTableOrder = new Parse.Query(TableOrderClassName);
    queryTableOrder.equalTo('waiter', waiter);

    const tableOrders = await queryTableOrder.find();

    const orders = await Promise.all(
      tableOrders.map((tableOrder) => {
        const queryOrder = new Parse.Query(OrderClassName);
        queryOrder.equalTo('tableOrder', tableOrder);
        return queryOrder.find();
      }),
    );

    const objectsToDestroy = [...orders.flat(), ...tableOrders, waiter];
    await Promise.all(
      objectsToDestroy.map((objectToDestroy) => objectToDestroy.destroy()),
    );

    dispatch(deleteWaitersuccess(id));
  } catch (error) {
    dispatch(deleteWaiterFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('deleteWaiter error', error);
  }
};
