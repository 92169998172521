import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {v4 as uuidv4} from 'uuid';
import {useToasts} from 'react-toast-notifications';

import styles from './index.module.css';
import Layout from 'components/Layout';
import concatStyles from 'helpers/concatStyles';
import Form from './Form';
import MenuList from 'components/MenuList';
import ActionBarForm from 'components/ActionBarForm';
import Overlay from 'components/Overlay';
import Popup from 'components/Popup';
import Spinner from 'components/Spinner';
import {
  fetchMenu,
  updateMenu,
  deleteItem,
  reset as resetAction,
  dismissNotification,
} from 'slices/menu';
import {RootState} from 'state';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {addToast} = useToasts();

  const [currentCategoryId, setCurrentCategoryId] = useState<string | null>(
    null,
  );
  const [currentItemId, setCurrentItemId] = useState<string | null>(null);
  const [actionVisible, setActionVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [isNewItem, setIsNewItem] = useState(false);
  const [image, setImage] = useState<{url: string | null; raw: any}>({
    url: null,
    raw: null,
  });

  const {
    fetchLoading,
    updateLoading,
    successNotification,
    deleteNotification,
    error,
    menuCategories,
    menuItems,
    currencySymbol,
  } = useSelector((state: RootState) => state.menu);

  const {control, errors, formState, handleSubmit, register, reset} = useForm();
  const {isDirty} = formState;

  const resetForm = useCallback(
    (itemId?: string) => {
      if (itemId) {
        const categoryId = menuItems[itemId].category;
        const currentCategory = menuCategories.byId[categoryId];
        const position = currentCategory.menuItems.findIndex(
          (id) => id === itemId,
        );

        reset({
          ...menuItems[itemId],
          position: position + 1,
          category: currentCategory.id,
        });
      } else {
        reset();
      }
    },
    [menuItems, menuCategories, reset],
  );

  // Fetch data when component is loaded
  useEffect(() => {
    dispatch(fetchMenu());

    return () => {
      // reset form when unmount
      dispatch(resetAction());
    };
  }, [dispatch]);

  // Show action bar when form is dirty
  useEffect(() => {
    if (isDirty) {
      setActionVisible(true);
    }
  }, [isDirty]);

  // show success notification
  useEffect(() => {
    if (successNotification) {
      addToast(i18next.t('toasts.saved'));
      dispatch(dismissNotification());
      resetForm(currentItemId!);
      setActionVisible(false);
      setIsNewItem(false);
    }
  }, [successNotification, currentItemId, addToast, resetForm, dispatch]);

  // show delete success notification
  useEffect(() => {
    if (deleteNotification) {
      addToast(i18next.t('toasts.deleted'));
      setCurrentCategoryId(null);
      setCurrentItemId(null);
      setActionVisible(false);
      setPopupVisible(false);
      setIsNewItem(false);
    }
  }, [deleteNotification, addToast]);

  // Show error message in a toast
  useEffect(() => {
    if (error) addToast(i18next.t('toasts.error'), {appearance: 'error'});
  }, [error, addToast]);

  const deleteItemHandler = () => {
    dispatch(deleteItem(currentItemId!));
  };

  const onSelectRowHandler = useCallback(
    (itemId: string) => {
      setCurrentCategoryId(menuItems[itemId].category);
      setImage({url: menuItems[itemId].coverPhoto, raw: null});
      setCurrentItemId(itemId);
      setActionVisible(false);
      setIsNewItem(false);
      resetForm(itemId);
    },
    [menuItems, resetForm],
  );

  const onAddItemHandler = (categoryId: string) => {
    const newId = uuidv4();

    setImage({url: null, raw: null});
    setCurrentCategoryId(categoryId);
    setCurrentItemId(newId);
    setIsNewItem(true);

    reset({
      id: newId,
      name: '',
      available: true,
      price: null,
      deal: undefined,
      description: '',
      category: menuCategories.byId[categoryId].id,
      position: 1,
      defaultService: 1,
      prepTime: null,
    });
  };

  const onDeleteItemHandler = () => {
    setImage({url: null, raw: null});
    setPopupVisible(true);
  };

  const onSubmitHandler = (formData: any) => {
    // delete or update the image
    const imageUpload = image.url ? image.raw : 'delete';

    const data = {
      ...formData,
      defaultService: parseInt(formData.defaultService),
      prepTime: parseFloat(formData.prepTime),
      price: parseFloat(formData.price),
      deal: parseFloat(formData.deal),
    };

    dispatch(updateMenu(data, imageUpload));
  };

  const onResetHandler = () => {
    setActionVisible(false);
    setImage({url: menuItems[currentItemId!].coverPhoto, raw: null});
    reset();
  };

  const onRemoveCoverHandler = () => {
    setImage({url: null, raw: null});
    setActionVisible(true);
  };

  const onUploadCoverHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    setImage({url: URL.createObjectURL(file), raw: file});
    setActionVisible(true);
  };

  // preselect the first item
  useEffect(() => {
    if (Object.keys(menuItems).length > 0) {
      const firstMenuItem = menuItems[Object.keys(menuItems)[0]];
      onSelectRowHandler(firstMenuItem.id);
    }
  }, [menuItems, onSelectRowHandler]);

  return (
    <>
      <Layout>
        <form
          className={concatStyles([
            styles.form,
            !actionVisible ? styles.clean : '',
          ])}
          onSubmit={handleSubmit(onSubmitHandler)}>
          <section className={concatStyles([styles.column, styles.leftColumn])}>
            <MenuList
              categories={menuCategories}
              items={menuItems}
              loading={fetchLoading}
              onRowClick={onSelectRowHandler}
              onAddClick={onAddItemHandler}
            />
          </section>
          <section
            className={concatStyles([styles.column, styles.rightColumn])}>
            <div className={styles.innerRightColumn}>
              {currentCategoryId && (
                <Form
                  canDelete={!isNewItem}
                  loading={fetchLoading}
                  disabled={updateLoading}
                  currentCategoryId={currentCategoryId}
                  categories={menuCategories}
                  currencySymbol={currencySymbol}
                  control={control}
                  errors={errors}
                  coverPhoto={image.url}
                  register={register}
                  onDelete={onDeleteItemHandler}
                  onRemoveCover={onRemoveCoverHandler}
                  onUploadCover={onUploadCoverHandler}
                />
              )}
            </div>
          </section>
          <ActionBarForm
            loading={updateLoading}
            onReset={onResetHandler}
            className={concatStyles([
              styles.actionBar,
              !actionVisible ? styles.hiddenActionBar : '',
            ])}
          />
        </form>
      </Layout>
      {popupVisible && (
        <Overlay>
          {updateLoading ? (
            <Spinner fill="#fff" size={96} />
          ) : (
            <Popup
              title={t('features.Menu.deleteTitle')}
              message={t('features.Menu.deleteText')}
              actions={[
                {
                  label: t('common.cancel'),
                  callback: () => setPopupVisible(false),
                },
                {
                  label: t('common.confirm'),
                  callback: () => deleteItemHandler(),
                },
              ]}
            />
          )}
        </Overlay>
      )}
    </>
  );
};

export default Menu;
