import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DateRangePicker} from 'react-dates';
import {useToasts} from 'react-toast-notifications';
import moment, {Moment} from 'moment';

import styles from './index.module.css';
import Layout from 'components/Layout';
import {RootState} from 'state';
import Title from 'components/Title';
import KPI from 'components/KPI';
import ReviewCard from './ReviewCard';
import {fetchStats, updateDateRange} from './statsSlice';
import Skeleton from 'components/Skeleton';
import useLiveStats from './useLiveStats';
import usePassword from 'hooks/usePassword';
import {useTranslation} from 'react-i18next';
import FormattedPrice from '../../components/FormattedPrice';
import i18next from 'i18next';

const Stats: React.FC = () => {
  const dispatch = useDispatch();
  useLiveStats();
  usePassword();
  const {t} = useTranslation();
  const {addToast} = useToasts();

  const {
    fetchLoading,
    error,
    updateLoading,
    startDate,
    endDate,
    reviews,
    reviewCount,
    reviewAvg,
    todayStats,
    rangeStats,
  } = useSelector((state: RootState) => state.stats);

  const [pickerFocused, setPickerFocused] = useState<
    'startDate' | 'endDate' | null
  >(null);

  // Fetch data when component is loaded
  useEffect(() => {
    dispatch(fetchStats());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      addToast(i18next.t('toasts.error'), {appearance: 'error'});
    }
  }, [error]);

  const onDatesChangeHandler = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    dispatch(
      updateDateRange({
        startDate: startDate && startDate.toISOString(),
        endDate: endDate && endDate.toISOString(),
      }),
    );
  };

  return (
    <Layout>
      <div className={styles.container}>
        <Title>{t('features.Stats.title')}</Title>
        <div className={styles.row}>
          <KPI
            loading={fetchLoading}
            title={t('features.Stats.revenue')}
            value={<FormattedPrice amount={todayStats.revenue} />}
          />
          <KPI
            loading={fetchLoading}
            title={t('features.Stats.orders')}
            value={`${todayStats.count}`}
          />
          <KPI
            loading={fetchLoading}
            title={t('features.Stats.averageOrder')}
            value={<FormattedPrice amount={todayStats.avg} />}
          />
        </div>
        <div className={styles.dateRange}>
          <DateRangePicker
            noBorder
            disabled={fetchLoading}
            startDate={moment(startDate)}
            startDateId="your_unique_start_date_id"
            endDate={moment(endDate)}
            endDateId="your_unique_end_date_id"
            onDatesChange={onDatesChangeHandler}
            focusedInput={pickerFocused}
            onFocusChange={(focusedInput) => setPickerFocused(focusedInput)}
            hideKeyboardShortcutsPanel
            anchorDirection="left"
            isOutsideRange={(day) =>
              day.isAfter(moment()) ||
              day.isBefore(moment().subtract(12, 'months'))
            }
          />
        </div>
        <div className={styles.row}>
          <KPI
            loading={fetchLoading || updateLoading}
            title={t('features.Stats.revenue')}
            value={<FormattedPrice amount={rangeStats.revenue} />}
          />
          <KPI
            loading={fetchLoading || updateLoading}
            title={t('features.Stats.orders')}
            value={`${rangeStats.count}`}
          />
          <KPI
            loading={fetchLoading || updateLoading}
            title={t('features.Stats.averageOrder')}
            value={<FormattedPrice amount={rangeStats.avg} />}
          />
        </div>
        <Title>
          {!fetchLoading && reviewCount && reviewAvg
            ? t('features.Stats.reviewsDetails', {reviewAvg, reviewCount})
            : t('features.Stats.reviews')}
        </Title>
        <div className={styles.row}>
          {fetchLoading
            ? [...Array(6)].map((_, i) => (
                <Skeleton key={`skeleton-${i}`} height={100} />
              ))
            : reviews.allIds.map((id) => (
                <ReviewCard
                  key={id}
                  id={id}
                  createdAt={reviews.byId[id].createdAt}
                  username={reviews.byId[id].username}
                  profilePicture={reviews.byId[id].profilePicture}
                  rating={reviews.byId[id].rating}
                  scores={reviews.byId[id].scores}
                />
              ))}
        </div>
      </div>
    </Layout>
  );
};

export default Stats;
