import React from 'react';
import styles from './index.module.css';
import concatStyles from 'helpers/concatStyles';

interface Props {
  name: string;
  profilePicture?: string | null;
  onClick: Function;
  selected: boolean;
}

const WaiterListItem: React.FC<Props> = ({
  name,
  profilePicture,
  selected,
  onClick,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={concatStyles([
          styles.avatar,
          selected ? styles.avatarSelected : '',
        ])}
        style={{backgroundImage: `url(${profilePicture}`}}
        onClick={onClick as any}
      />
      <b className={selected ? styles.nameSelected : ''}>{name}</b>
    </div>
  );
};

export default WaiterListItem;
