import React from 'react';
import {useForm} from 'react-hook-form';
import styles from './index.module.css';
import TextInputForm from 'components/TextInputForm';
import Button from 'components/Button';
import {useTranslation} from 'react-i18next';

interface Props {
  onSubmit: (values: Form) => void;
  submitButtonText?: string;
  defaultValues?: Form;
}

export interface Form {
  name: string;
  capacity: number;
}

interface FormRaw {
  name: string;
  capacity: string;
}

const AddUpdateForm: React.FC<Props> = ({
  onSubmit,
  defaultValues,
  submitButtonText = 'Save',
}) => {
  const {t} = useTranslation();

  const {register, errors, handleSubmit} = useForm<FormRaw>({
    defaultValues: {
      ...defaultValues,
      capacity: defaultValues?.capacity.toString(),
    },
  });

  const onSubmitHandler = (values: FormRaw) => {
    onSubmit({...values, capacity: parseInt(values.capacity)});
  };
  const required = t('common.required');
  const invalidTableName = t('features.Tables.AddUpdateForm.invalidTableName');
  const invalidTableCapacity = t(
    'features.Tables.AddUpdateForm.invalidTableCapacity',
  );

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmitHandler)}>
      <TextInputForm
        ref={register({
          required: required,
          maxLength: {value: 5, message: invalidTableName},
        })}
        name="name"
        label={t('common.tableNumber')}
        error={!!errors.name}
      />
      {errors.name && (
        <span className={styles.error}>{errors.name.message}</span>
      )}
      <TextInputForm
        ref={register({
          required: required,
          min: {value: 1, message: invalidTableCapacity},
        })}
        name="capacity"
        label={t('common.capacity')}
        type="number"
        defaultValue={1}
        min={1}
        error={!!errors.capacity}
      />
      {errors.capacity && (
        <span className={styles.error}>{errors.capacity.message}</span>
      )}
      <Button text={submitButtonText} className={styles.button} />
    </form>
  );
};

export default AddUpdateForm;
