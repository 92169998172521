import React, {FC} from "react";

interface Props {
  fill?: string;
  size?: number;
}

export const TicketIcon: FC<Props> = ({fill = "#000", size = 48}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.537 7.7L39.76 18.925c.449.421.702.993.716 1.613.014.64-.241 1.271-.7 1.73L22.282 39.761a2.261 2.261 0 01-1.664.716 2.39 2.39 0 01-1.68-.702l-2.405-2.405a1.183 1.183 0 01-.107-1.55c.998-1.32.853-3.235-.337-4.454-1.161-1.19-3.071-1.322-4.444-.308-.47.347-1.125.3-1.54-.115l-2.39-2.39a2.262 2.262 0 01-.715-1.615c-.015-.64.24-1.27.7-1.73L25.21 7.701a2.329 2.329 0 013.328 0zm-1.664 1.683l-3.263 3.263 2.132 2.16-1.683 1.66-2.121-2.148L9.383 26.873l1.742 1.742c2.204-1.088 4.917-.684 6.658 1.099 1.748 1.79 2.143 4.49 1.082 6.64l1.739 1.74L33.159 25.54l-2.108-2.082 1.662-1.684 2.119 2.093 3.262-3.262-11.22-11.221zm.835 7.349l3.076 3.075-1.673 1.673-3.075-3.076 1.672-1.672z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default TicketIcon;
