import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Parse from 'parse';
import * as Sentry from '@sentry/react';

import {AppThunk} from 'state';

export type Call = {
  id: string;
  tableNumber: string;
  type: 'waiterCall' | 'waiterPayment';
};

interface State {
  error: string | null;
  loaded: boolean;
  queue: Call[];
}

const initialState: State = {
  error: null,
  loaded: false,
  queue: [],
};

const waiterCallSlice = createSlice({
  name: 'waiterCall',
  initialState,
  reducers: {
    fetchQueueStart(state) {
      state.error = null;
    },
    fetchQueueSuccess(state, action: PayloadAction<Call[]>) {
      state.queue = action.payload;
      state.loaded = true;
    },
    appendToQueue(state, action: PayloadAction<Call>) {
      state.queue.push(action.payload);
    },
    fetchQueueFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    resetQueue(state) {
      state.loaded = false;
      state.error = null;
      state.queue = [];
    },
    resolveCallSuccess(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.queue.findIndex((item) => item.id === id);
      if (index !== -1) state.queue.splice(index, 1);
      state.error = null;
    },
    resolveCallFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    purge() {
      return initialState;
    },
  },
});

export const {
  fetchQueueStart,
  fetchQueueSuccess,
  fetchQueueFailed,
  appendToQueue,
  resetQueue,
  resolveCallSuccess,
  resolveCallFailed,
  purge,
} = waiterCallSlice.actions;

export default waiterCallSlice.reducer;

/**
 * Thunks
 */

export const fetchQueue = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchQueueStart());

    const currentUser = Parse.User.current()!;
    const restaurant = currentUser.get('restaurant');

    const query = new Parse.Query('WaiterCall');
    query.equalTo('restaurant', restaurant);

    const waiterCalls = await query.find();

    const queue = waiterCalls.map((waiterCall) => {
      return {
        id: waiterCall.id,
        tableNumber: waiterCall.get('tableNumber'),
        type: waiterCall.get('type'),
      };
    });

    dispatch(fetchQueueSuccess(queue));
  } catch (error) {
    dispatch(fetchQueueFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('fetchQueue error', error);
  }
};

export const resolveCall = (id: string): AppThunk => async (dispatch) => {
  try {
    const query = new Parse.Query('WaiterCall');

    const waiterCall = await query.get(id);

    await waiterCall.destroy();

    dispatch(resolveCallSuccess(id));
  } catch (error) {
    dispatch(resolveCallFailed(error.toString()));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('resolveCall error', error);
  }
};
